import React, { useEffect, useState } from "react";
import AddForm from "../../Elements/AddForm";
import {
	removeFieldsWithEmptyValues,
	removeFieldsWithIdKey,
} from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { modules } from "../../../utils/common/ModulesName";
import { useCloneDeal, useDealData } from "../../../hooks/services/deal";
import { useDealFieldPermissions } from "../../../hooks/modules/auth/deal";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useModuleName } from "../../../hooks/modules";

export default function CloneDeal(props) {
	const { onClose, dealId } = props;
	const [formData, setFormData] = useState([]);
	const moduleName = modules.DEAL;

	const { data: dealData, status: dealStatus } = useDealData(dealId);
	const cloneMutation = useCloneDeal();

	const { getModuleName } = useModuleName();
	const formHeaderLabel = `Clone ${getModuleName(moduleName)}`;

	const dealFieldPermissions = useDealFieldPermissions();

	useEffect(() => {
		if (dealStatus === "success") {
			if (dealData) {
				setFormData(dealData);
			}
		}
	}, [dealStatus, dealData]);

	const cloneDeal = (clonedFormData, isFormShouldClose) => {
		removeFieldsWithEmptyValues(clonedFormData);
		removeFieldsWithIdKey(clonedFormData);
		cloneMutation.mutate(clonedFormData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealCloned,
				});
				isFormShouldClose && onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<FieldPermissionContextProvider value={dealFieldPermissions}>
				<AddForm
					onSave={cloneDeal}
					moduleName={moduleName}
					formData={formData}
					onClose={onClose}
					hiddenSaveAndNewButton={true}
					formHeaderLabel={formHeaderLabel}
					isSaving={cloneMutation.isLoading}
					isEditMode={true}
				></AddForm>
			</FieldPermissionContextProvider>
		</React.Fragment>
	);
}
