import { getRootKey } from "../rootKey";

export const getAnalyticsKey = () => {
	return [...getRootKey(), "analytics"];
};

export const getAnalyticsDashboardKey = () => {
	return [...getAnalyticsKey(), "dashboard"];
};

export const getAnalyticsDashboardDataKey = (
	dashboardId,
	fetchViewProperties
) => {
	return [
		...getAnalyticsDashboardKey(),
		String(dashboardId),
		"fetchViewProperties",
		String(fetchViewProperties),
	];
};

export const getAnalyticsMetricsKey = (viewTypeId) => {
	return [...getAnalyticsDashboardKey(), "metrics", String(viewTypeId)];
};

export const getAnalyticsXAxisMetricsKey = (moduleIds) => {
	return [...getAnalyticsDashboardKey(), "x-axis", String(moduleIds)];
};

export const getAnalyticsDurationKey = (moduleId) => {
	return [...getAnalyticsDashboardKey(), "duration", String(moduleId)];
};

export const getChartViewDataKey = (dashboardId, viewId) => {
	return [
		...getAnalyticsDashboardKey(),
		"chart",
		"view-data",
		String(dashboardId),
		String(viewId),
	];
};

export const getDashboardListKey = (dashboard, fetchHits, criteria, sort) => {
	return [
		...getAnalyticsDashboardKey(),
		dashboard,
		"list",
		String(fetchHits),
		JSON.stringify(criteria),
		JSON.stringify(sort),
	];
};
