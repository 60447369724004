import { emailPattern } from "./validation";

const fieldTypes = {
	email: "EMAIL",
	phone: "PHONE",
	emails: "EMAILS",
	singleString: "SINGLE_STRING",
};

export const phoneNumberLimit = 10;
export const emailLimit = 10;

let emailValidationMessage = "Please enter a valid email id";
let fieldValidationMessage = "This field is mandatory";

export const handleFieldValidation = (field) => {
	return {
		required: handleRequiredField(field),
		validate: (value) => handleValidate(value, field),
		pattern: getFieldValidationPattern(field),
		minLength: {
			value: field.config.minLength,
			message: `Min. of ${field.config.minLength} characters are required`,
		},
		maxLength: {
			value: field.config.maxLength,
			message: `Max. of ${field.config.maxLength} characters are allowed`,
		},
		min: {
			value: field.config.min || 0,
			message: field.config.min
				? `Min. of ${
						field.config.min?.toString()?.length
					} numbers are required`
				: "Negative numbers are not permitted",
		},
		max: {
			value: field.config.max,
			message: `Max. of ${
				field.config.max?.toString()?.length
			} numbers are allowed`,
		},
	};
};

const handleRequiredField = (field) => {
	let isRequired = field.config.required;
	if (isRequired) return fieldValidationMessage;
};

const isString = (value) => {
	return !!value && typeof value === "string";
};

const validateEmail = (value) => {
	// Case 1: String validation (used for summary edit)
	if (isString(value)) {
		if (emailPattern.test(value)) {
			return true;
		}
		return emailValidationMessage;
	}
	// Case 2: Object validation (used for Add Form)
	// This case handles objects where the email value is stored in a property
	// The property name is expected to be stored in the 'id' field of the object
	else {
		// Check if the object has a non-empty value for the property specified by 'id'
		if (value?.[value?.id] && value?.[value?.id] !== "") {
			if (emailPattern.test(value?.[value?.id])) {
				return true;
			}

			return emailValidationMessage;
		}
	}
};

const validateSingleString = (value, field) => {
	const fieldValidationData = field?.config?.validation;
	if (value && fieldValidationData) {
		const regexString = fieldValidationData?.regex;
		const regexPattern = new RegExp(regexString);
		if (regexPattern.test(value)) {
			return true;
		}
		return fieldValidationData?.violationMessage;
	}
	return true;
};

const getEmailPattern = () => {
	return {
		value: emailPattern,
		message: emailValidationMessage,
	};
};

const handleValidate = (value, field) => {
	switch (field.type || field.fieldType) {
		case fieldTypes.email:
			return validateEmail(value, field);
		case fieldTypes.singleString:
			return validateSingleString(value, field);
		default:
			break;
	}
};

const getFieldValidationPattern = (field) => {
	switch (field.type) {
		case fieldTypes.emails:
			return getEmailPattern(field);
		default:
			break;
	}
};
