import { useCallback, useMemo } from "react";
import { useUserList } from "../../services/userManagement/user";

const useUsers = (searchValue = "") => {
	const {
		data: userList,
		isLoading: isLoadingUsers,
		isFetching: isFetchingUsers,
		hasNextPage: hasNextUsersPage,
		fetchNextPage: fetchNextUsersPage,
	} = useUserList(searchValue);

	const activeUserList = useMemo(() => {
		if (Array.isArray(userList)) {
			let activeUsers = userList.filter((user) => user?.config?.isActive);
			return activeUsers;
		}
		return [];
	}, [userList]);

	const getUserDataById = useCallback(
		(userId) => {
			const selectedUserData = userList?.find(
				(user) => user?.value === userId
			);

			if (selectedUserData) {
				return selectedUserData;
			}
			return {};
		},
		[userList]
	);

	const loggedInUser = useMemo(() => {
		let loggedInUserData = userList?.find(
			(user) => user?.config?.isLoggedInUser
		);
		return loggedInUserData;
	}, [userList]);

	return {
		activeUserList,
		getUserDataById,
		loggedInUser,
		isLoadingUsers,
		isFetchingUsers,
		hasNextUsersPage,
		fetchNextUsersPage,
	};
};

export { useUsers };
