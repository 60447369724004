import React from "react";
import { Box, Stack } from "@mui/material";
import LifecycleStageView from "./LifecycleStageView";
import LifecycleStageStatusView from "./LifecycleStageStatusView";

export default function LifecycleStageScaleView({ contactId }) {
	return (
		<>
			<Box
				bgcolor="rgba(231, 247, 240, 1)"
				borderRadius="8px"
				py={2}
				px={2.5}
				m={1}
			>
				<Stack direction="row" alignItems="baseline">
					<Box width="17%">
						<LifecycleStageView contactId={contactId} />
					</Box>

					<Box width="83%">
						<LifecycleStageStatusView contactId={contactId} />
					</Box>
				</Stack>
			</Box>
		</>
	);
}
