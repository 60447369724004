import React, { useEffect, useMemo, useState } from "react";
import { FormFieldName } from "../../../../../../../styles/twozo";
import Field from "../../Field";
import { Box, Button, Stack, useTheme } from "@mui/material";
import { useDashboardBuilderContext } from "../../../Context";
import { useGetMetrics } from "../../../../../../../hooks/services/analytics";
import { default as AddIcon } from "../../../../../../../assets/icons/add";
import { useWatch } from "react-hook-form";

export default function MetricsYAxis(props) {
	const theme = useTheme();
	const { control, setValue, index } = props;
	const { selectedChartProperty } = useDashboardBuilderContext();
	const [moduleOptions, setModuleOptions] = useState([]);
	const { data: metricsData } = useGetMetrics(
		selectedChartProperty.viewTypeId
	);
	const [measureId, moduleData] = useWatch({
		control,
		name: [
			`viewProperties.metrics.${index}.measureId`,
			`viewProperties.metrics.${index}.moduleData`,
		],
	});

	const measureOptions = useMemo(() => {
		if (metricsData && metricsData.metrics) {
			return metricsData.metrics.map((metric) => ({
				id: metric.measureId,
				label: metric.measureName,
			}));
		}
		return [];
	}, [metricsData]);

	useEffect(() => {
		if (measureId && metricsData) {
			const metricData = metricsData?.metrics?.find(
				(metric) => metric.measureId === measureId
			);
			setModuleOptions(
				metricData?.modules?.map((module) => ({
					id: module.moduleId,
					label: module.moduleName,
					options:
						module.fields?.map((field) => ({
							id: JSON.stringify({
								moduleId: module.moduleId,
								fieldId: field.fieldId,
							}),
							label: field.fieldName,
						})) || [],
				})) || []
			);
		}
	}, [measureId, metricsData]);

	useEffect(() => {
		if (moduleData) {
			const { moduleId, fieldId } = JSON.parse(moduleData);
			setValue(`viewProperties.metrics.${index}.moduleId`, moduleId);
			setValue(`viewProperties.metrics.${index}.fieldId`, fieldId);
		}
	}, [moduleData, setValue, index]);

	return (
		<React.Fragment>
			<Stack spacing={1.5}>
				<FormFieldName pb={1}>Metrics (Y Axis)</FormFieldName>

				<Stack spacing={0.5}>
					<FormFieldName>Measure</FormFieldName>

					<Field
						fieldType="DROPDOWN"
						name={`viewProperties.metrics.${index}.measureId`}
						control={control}
						options={measureOptions}
						rules={{
							required: {
								value: true,
								message: "Measure is required",
							},
						}}
					/>
				</Stack>

				<Stack spacing={0.5}>
					<FormFieldName>Module</FormFieldName>

					<Field
						fieldType="DROPDOWN"
						name={`viewProperties.metrics.${index}.moduleData`}
						control={control}
						options={moduleOptions}
						rules={{
							required: {
								value: true,
								message: "Module is required",
							},
						}}
						grouping
					/>
				</Stack>

				<Stack spacing={0.5}>
					<FormFieldName>Metric name</FormFieldName>

					<Field
						name={`viewProperties.metrics.${index}.label`}
						control={control}
						fieldType="SINGLE_STRING"
						placeholder="Eg: Products"
						rules={{
							required: {
								value: true,
								message: "Metric name is required",
							},
						}}
					/>
				</Stack>

				<Box>
					<Button
						startIcon={AddIcon(18, 18, theme.palette.primary.main)}
					>
						Add Filter
					</Button>
				</Box>
			</Stack>
		</React.Fragment>
	);
}
