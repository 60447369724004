import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import NavigationBar from "./NavigationBar";

//NOTE: Dummy Analytics dat. Fetch reports Data from API inside ReportsList component while API integration.
const reportsData = {
	favourites: {
		label: "Favourites",
		data: [
			{
				id: 1,
				favourite: true,
				curated: false,
				isDeletable: true,
				reportName: "Today’s Sales",
				createdBy: "Davis Dokidis",
				createdDate: "22 Jun 2023",
				modifiedBy: "Davis Dokidis",
				modifiedDate: "23 Jun 2023",
			},
			{
				id: 2,
				favourite: true,
				curated: true,
				isDeletable: false,
				reportName: "Sales Dashboard",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
		],
	},
	"all-reports": {
		label: "All Analytics",
		data: [
			{
				id: 1,
				favourite: true,
				curated: false,
				isDeletable: true,
				reportName: "Today’s Sales",
				createdBy: "Davis Dokidis",
				createdDate: "22 Jun 2023",
				modifiedBy: "Davis Dokidis",
				modifiedDate: "23 Jun 2023",
			},
			{
				id: 2,
				favourite: true,
				curated: true,
				isDeletable: false,
				reportName: "Sales Dashboard",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 3,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Team activity report",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 4,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Contact generation and trend report",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 5,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Sales Trends",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 6,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Sales Forecast",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 7,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Product Dashboard",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 8,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Marketing Performance Dashboard",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 9,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Marketing Performance Report",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
		],
	},
	"curated-reports": {
		label: "Curated reports",
		data: [
			{
				id: 2,
				favourite: true,
				curated: true,
				isDeletable: false,
				reportName: "Sales Dashboard",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 3,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Team activity report",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 4,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Contact generation and trend report",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 5,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Sales Trends",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 6,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Sales Forecast",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 7,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Product Dashboard",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 8,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Marketing Performance Dashboard",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
			{
				id: 9,
				favourite: false,
				curated: true,
				isDeletable: false,
				reportName: "Marketing Performance Report",
				createdBy: "Twozo",
				createdDate: "22 Jun 2023",
				modifiedBy: "",
				modifiedDate: "22 Jun 2023",
			},
		],
	},
	"private-reports": {
		label: "Private Analytics",
		data: [],
	},
	"shared-reports": {
		label: "Shared Analytics",
		data: [],
	},
	"owned-reports": {
		label: "Owned reports",
		data: [],
	},
	trash: {
		label: "Trash",
		data: [
			{
				id: 1,
				favourite: false,
				curated: false,
				isDeletable: true,
				isRecoverable: true,
				reportName: "Employees by Ownership",
				createdBy: "Davis Dokidis",
				createdDate: "22 Jun 2023",
				modifiedBy: "Davis Dokidis",
				modifiedDate: "22 Jun 2023",
			},
		],
	},
};

export default function Reports() {
	const drawerWidth = 300;
	const navigate = useNavigate();
	const location = useLocation();
	const ref = useRef(null);
	const [topBarHeight, setTopBarHeight] = useState(0);

	useEffect(() => {
		setTopBarHeight(ref.current.getBoundingClientRect().top);
	}, [ref]);

	useEffect(() => {
		if (location.pathname === "/analytics") {
			navigate("dashboard/list/favourites");
		}
	}, [location, navigate]);

	const menus = [
		{
			menu: [
				{
					name: "Favourites",
					path: "favourites",
				},
			],
		},
		{
			menu: [
				{
					name: "All Dashboards",
					path: "all",
				},
				{
					name: "Curated Dashboards",
					path: "curated",
				},
				{
					name: "Private Dashboards",
					path: "private",
				},
				{
					name: "Shared Dashboards",
					path: "shared",
				},
				{
					name: "Owned Dashboards",
					path: "owned",
				},
				{
					name: "Trash",
					path: "trash",
				},
			],
		},
	];

	return (
		<React.Fragment>
			<React.Fragment>
				<Box
					ref={ref}
					sx={{
						display: "flex",
						height: `calc(100vh - ${topBarHeight}px)`,
					}}
				>
					<Box sx={{ width: drawerWidth, overflowY: "auto" }}>
						<NavigationBar menus={menus} />
					</Box>

					<Box
						sx={{
							flexGrow: 1,
							width: `calc(100% - ${drawerWidth}px)`,
							overflowY: "auto",
						}}
					>
						<Box
							style={{
								height: "100%",
							}}
							p={1}
						>
							<Box
								style={{
									border: "1px solid rgba(0, 0, 0, 0.1)",
									borderRadius: "8px",
									height: "100%",
								}}
							>
								<Outlet context={[reportsData]} />
							</Box>
						</Box>
					</Box>
				</Box>
			</React.Fragment>
		</React.Fragment>
	);
}
