import { getContactsUrl } from "../apiConfig";
import { getTableData } from "../table/tableApi";
import { assertError } from "../apiHelper";
import { getFormFields } from "../fields/form/formApi";
import { apiClient } from "..";
import postFieldsApi from "../fields/postFieldsApi";

export const getContactsTableData = async () => {
	let listUrl = getContactsListUrl();
	let config = {
		url: listUrl,
		provider: "CONTACT_LIST",
		fields: parseSelectFieldsForApi(),
	};

	let response = await getTableData(config);
	return response;
};

export const getContactFormFields = async () => {
	let response = await getFormFields("Contact");
	return response;
};

export const createContact = async (data) => {
	let requestData = getCreateContactRequest(data);
	return await postContactManagementApi(requestData);
};

export const updateContact = async (data) => {
	let requestData = updateContactFieldRequest(data);
	return await postContactManagementApi(requestData);
};

export const deleteContact = async (id) => {
	let requestData = getDeleteContactRequest(id);
	return await postContactManagementApi(requestData);
};

export const deleteMultipleContacts = async (contactsToDelete) => {
	let requestData = getDeleteMultipleContactsRequest(contactsToDelete);
	return await postContactManagementApi(requestData);
};

export const getContactSummaryData = async (id, hideEmptyFields) => {
	let requestData = getContactSummaryRequest(id, hideEmptyFields);
	return await postContactManagementApi(requestData);
};

export const getContactData = async (id) => {
	let request = getContactRequestData(id);
	return await postContactManagementApi(request);
};

export const updateContactSummary = async (data) => {
	let request = updateContactSummaryRequest(data);
	return await postContactManagementApi(request);
};

export const assignOwnerToContacts = async ({ contactIds, userId }) => {
	let request = assignOwnerRequest(contactIds, userId);
	return await postContactManagementApi(request);
};

export const getContactList = async (searchValue, pageParam) => {
	let requestData = getContactListRequestData(searchValue, pageParam);
	return await postFieldsApi(requestData);
};

export const getContactListWithEmails = async () => {
	let requestData = getContactListWithEmailsRequestData();
	return await postFieldsApi(requestData);
};

export const partialUpdateContact = async (data) => {
	let requestData = partialUpdateContactRequest(data);
	return await postContactManagementApi(requestData);
};

export const getLifecycleStageProgress = async (contactId) => {
	let requestData = getLifecycleStageProgressRequest(contactId);
	return await postContactManagementApi(requestData);
};

export const getContactLifeCycleStageList = async (contactId) => {
	let requestData = getContactLifeCycleStageListRequest(contactId);
	return await postFieldsApi(requestData);
};

export const updateContactLifecycleStatus = async ({
	id,
	lifeCycleStageId,
	lifeCycleStatusId,
	contactLostReason,
}) => {
	let requestData = getUpdateContactLifecycleStatusRequest(
		id,
		lifeCycleStageId,
		lifeCycleStatusId,
		contactLostReason
	);
	return await postContactManagementApi(requestData);
};

export const contactLostReasonList = async () => {
	let requestData = getContactLostReasonListRequest();
	return await postFieldsApi(requestData);
};

const getContactsListUrl = () => {
	return getContactsUrl() + "/list";
};

const getContactManagementUrl = () => {
	return getContactsUrl() + "/mgmt";
};

const parseSelectFieldsForApi = () => {
	// let fields = [];

	// if (fieldData) {
	//     fieldData.forEach((field) => {
	//         fields.push(field.id);
	//     });
	// }

	//For now we aren't using this fieldData, But we will be using the same in future once the server side changes are done

	let fields = [
		"firstName",
		"lastName",
		"email",
		"mobile",
		"source",
		"stage",
		"company",
		"id",
	];
	return fields;
};

const postContactManagementApi = async (requestData) => {
	let contactsManagementUrl = getContactManagementUrl();
	let response = await apiClient.post(contactsManagementUrl, requestData);
	assertError(response, contactsManagementUrl);
	return response.data.data;
};

const getDeleteMultipleContactsRequest = (contactsToDelete) => {
	return {
		type: "bDelete",
		data: {
			ids: contactsToDelete,
		},
	};
};

const partialUpdateContactRequest = (data) => {
	return {
		type: "pUpdate",
		data: data,
	};
};

const getDeleteContactRequest = (id) => {
	return {
		type: "delete",
		data: {
			id: id,
		},
	};
};

const getCreateContactRequest = (data) => {
	return {
		type: "create",
		data: data,
	};
};

const updateContactFieldRequest = (data) => {
	return {
		type: "pBulkUpdate",
		data: {
			list: data,
		},
	};
};

const getContactSummaryRequest = (id, hideEmptyFields) => {
	return {
		type: "summary",
		data: {
			id: id,
			isHidden: hideEmptyFields,
		},
	};
};

const getContactRequestData = (id) => {
	return {
		type: "get",
		data: {
			id: id,
		},
	};
};

const updateContactSummaryRequest = (data) => {
	return {
		type: "update",
		data: data,
	};
};

const assignOwnerRequest = (contactIds, userId) => {
	return {
		type: "bAssignOwner",
		data: {
			ids: contactIds,
			userId: userId,
		},
	};
};

const getContactListRequestData = (searchValue = "", pageParam) => {
	return {
		type: "dropdownData",
		data: {
			name: "contactList",
			criteria: {
				type: "startsWith",
				value: searchValue,
			},
			...pageParam,
		},
	};
};

const getContactListWithEmailsRequestData = () => {
	return {
		type: "dropdownData",
		data: {
			name: "contactList",
			includeEmails: true,
		},
	};
};

const getLifecycleStageProgressRequest = (contactId) => {
	return {
		type: "getLifeCycleStageProgress",
		data: {
			contactId: contactId,
		},
	};
};

const getContactLifeCycleStageListRequest = (contactId) => {
	return {
		type: "dropdownData",
		data: {
			name: "contactLifeCycleStageList",
			contactId: contactId,
		},
	};
};

const getUpdateContactLifecycleStatusRequest = (
	id,
	lifeCycleStageId,
	lifeCycleStatusId,
	contactLostReason
) => {
	return {
		type: "updateLifecycleStatus",
		data: {
			id: id,
			lifeCycleStageId: lifeCycleStageId,
			lifeCycleStatusId: lifeCycleStatusId,
			contactLostReason: contactLostReason,
		},
	};
};

const getContactLostReasonListRequest = () => {
	return {
		type: "dropdownData",
		data: {
			name: "contactLostReasonList",
		},
	};
};
