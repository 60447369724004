import React, { useState } from "react";
import Menu from "../../../../../Elements/Menu";
import {
	Box,
	CircularProgress,
	MenuItem,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../../../../Elements/MenuSearchBar";
import { useLinkMailToSource } from "../../../../../../hooks/services/conversations";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";
import { modules } from "../../../../../../utils/common/ModulesName";
import CustomSwipeableDrawer from "../../../../../Elements/CustomSwipeableDrawer";
import { twozoStyles } from "../../../../../../styles/twozo";
import AddForm from "../../../../../Elements/AddForm";
import { useDealList } from "../../../../../../hooks/services/deal";
import { removeFieldsWithEmptyValues } from "../../../../../../utils/common";
import Can from "../../../../../Auth/Can";
import { PERMISSIONS } from "../../../../../../utils/Auth";
import { useAuth } from "../../../../../../hooks/auth";
import { useModuleName } from "../../../../../../hooks/modules";
import { useLastRowRef } from "../../../../../../hooks/common/InfiniteScroll";

export default function DealMenu(props) {
	const { dealMenuElement, isDealMenuOpened, closeDealMenu, threadId } =
		props;

	const classes = twozoStyles();

	const [displayExistingDeals, setDisplayExistingDeals] = useState(false);
	const [searchedDealValue, setSearchedDealValue] = useState("");
	const [isAddDealDrawerOpened, setIsAddDealDrawerOpened] = useState(false);

	const { isUserAllowedFor } = useAuth();
	const isDealListEnabled = isUserAllowedFor(PERMISSIONS.deal.view);
	const {
		data: dealList,
		isLoading: isLoadingDeals,
		isFetching: isFetchingDeals,
		hasNextPage: hasDealsNextPage,
		fetchNextPage: fetchDealsNextPage,
	} = useDealList(isDealListEnabled, searchedDealValue);

	const dealLastOptionRef = useLastRowRef(
		fetchDealsNextPage,
		hasDealsNextPage,
		isFetchingDeals
	);
	const linkMailToDealMutation = useLinkMailToSource(threadId);

	const { getModuleName } = useModuleName();
	const moduleName = modules.DEAL;
	const formHeaderLabel = `Add ${getModuleName(moduleName)}`;

	const handleExistingDeal = () => {
		setDisplayExistingDeals(true);
	};

	const onCloseDealMenu = () => {
		closeDealMenu();
		setDisplayExistingDeals(false);
		setSearchedDealValue("");
	};

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchedDealValue(value?.trimStart());
	};

	const handleLinkMailToSource = (linkMailToDealRequestData) => {
		linkMailToDealMutation.mutate(linkMailToDealRequestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.linkToDeal,
				});
				onCloseDealMenu();
				closeAddDealDrawer();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.somethingWentWrong,
				});
			},
		});
	};

	const handleLinkExistingDeal = (dealId) => {
		let linkMailToExsistingDealRequest = {
			threadId: threadId,
			sourceName: modules.DEAL,
			entityData: {
				deal: {
					dealId: dealId,
				},
			},
		};
		handleLinkMailToSource(linkMailToExsistingDealRequest);
	};

	const handleLinkMailtoNewDeal = (newDealData) => {
		removeFieldsWithEmptyValues(newDealData);
		let linkMailToExsistingDealRequest = {
			threadId: threadId,
			sourceName: modules.DEAL,
			entityData: {
				deal: newDealData,
			},
		};
		handleLinkMailToSource(linkMailToExsistingDealRequest);
	};

	const openAddDealDrawer = () => {
		setIsAddDealDrawerOpened(true);
		onCloseDealMenu();
	};

	const closeAddDealDrawer = () => {
		setIsAddDealDrawerOpened(false);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography
					fontSize={13}
					color="rgba(0, 0, 0, 0.6)"
					textAlign="center"
				>
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isAddDealDrawerOpened}
				onOpen={openAddDealDrawer}
				onClose={closeAddDealDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					<AddForm
						moduleName={moduleName}
						onClose={closeAddDealDrawer}
						isSaving={linkMailToDealMutation.isLoading}
						onSave={handleLinkMailtoNewDeal}
						hiddenSaveAndNewButton={true}
						formHeaderLabel={formHeaderLabel}
					></AddForm>
				</Box>
			</CustomSwipeableDrawer>

			<Menu
				width="250px"
				anchorEl={dealMenuElement}
				open={isDealMenuOpened}
				onClose={onCloseDealMenu}
				style={{
					marginTop: "8px",
				}}
				menuListProps={{
					padding: "0px 0px 4px 0px",
				}}
			>
				<Box
					p={0.5}
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
					hidden={!displayExistingDeals}
				>
					<MenuSearchBar
						value={searchedDealValue}
						onChange={handleSearch}
					/>
				</Box>

				<Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
					{displayExistingDeals ? (
						<Box>
							<Box>
								{isLoadingDeals ? (
									<Stack
										alignItems="center"
										justifyContent="center"
										height="100px"
									>
										<CircularProgress size="25px" />
									</Stack>
								) : dealList?.length > 0 ? (
									dealList.map((deal) => (
										<MenuItem
											key={deal?.id}
											style={{ height: "40px" }}
											onClick={() =>
												handleLinkExistingDeal(deal?.id)
											}
										>
											<Typography fontSize={13} noWrap>
												{deal?.title}
											</Typography>
										</MenuItem>
									))
								) : (
									renderEmptyView()
								)}
							</Box>
							<Box ref={dealLastOptionRef}>
								{!isLoadingDeals &&
									isFetchingDeals &&
									dealList?.length > 0 && (
										<MenuItem style={{ height: "40px" }}>
											<Skeleton
												width="190px"
												height="12px"
											/>
										</MenuItem>
									)}
							</Box>
						</Box>
					) : (
						<Box>
							<MenuItem
								style={{ height: "40px" }}
								onClick={handleExistingDeal}
							>
								<Typography fontSize={13}>
									to Existing Deal
								</Typography>
							</MenuItem>

							<Can permission={PERMISSIONS.contact.view}>
								<Can permission={PERMISSIONS.deal.create}>
									<MenuItem
										style={{ height: "40px" }}
										onClick={openAddDealDrawer}
									>
										<Typography fontSize={13}>
											New Deal
										</Typography>
									</MenuItem>
								</Can>
							</Can>
						</Box>
					)}
				</Box>
			</Menu>
		</React.Fragment>
	);
}
