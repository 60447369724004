import React from "react";
import { Typography } from "@mui/material";

export default function Templates() {
	return (
		<React.Fragment>
			<Typography p={1} pt={5} align="center">
				Templates not implemented
			</Typography>
		</React.Fragment>
	);
}
