import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { default as AddIcon } from "../../../../assets/icons/add";
import LifecycleStageCard from "./LifecycleStageCard";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { maxStageLimit } from "../LifecycleStageConfig";
import { useEffect, useState } from "react";
import { useUpdateLifecycleStageOrder } from "../../../../hooks/services/lifecycleStage";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function LifecycleStageList(props) {
	const { stagesList, onOpen } = props;

	// mutation call:-
	const updateStageOrderMutation = useUpdateLifecycleStageOrder();

	const [lifecycleStages, setLifecycleStages] = useState([]);

	useEffect(() => {
		if (Array.isArray(stagesList)) {
			setLifecycleStages([...stagesList]);
		}
	}, [stagesList]);

	const theme = useTheme();
	const isStageLimitExceeded = lifecycleStages?.length === maxStageLimit;

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = (result) => {
		if (
			!result.destination ||
			result.destination.index === result.source.index
		) {
			return;
		}

		let defaultLifecycleStageIndex = lifecycleStages?.length - 1;
		if (result.destination.index === defaultLifecycleStageIndex) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.lifecycleStageOrderUpdateError,
			});
			return;
		}

		const updatedLifecycleStagesList = reorder(
			lifecycleStages,
			result.source.index,
			result.destination.index
		);
		const lifecycleStageIds = updatedLifecycleStagesList.map(
			(stage) => stage?.id
		);
		setLifecycleStages([...updatedLifecycleStagesList]);

		updateStageOrderMutation.mutate(lifecycleStageIds, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.lifecycleStageUpdated,
				});
			},
		});
	};

	const updateLifecycleStagesList = (updatedStage, stageIndex) => {
		setLifecycleStages((stages) => {
			return stages.map((stage, index) => {
				if (stageIndex === index) {
					return { ...stage, statuses: [...updatedStage] };
				}
				return stage;
			});
		});
	};

	return (
		<>
			<Box pt={3}>
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId="stagesList" direction="horizontal">
						{(provided) => (
							<div
								ref={provided.innerRef}
								{...provided.droppableProps}
							>
								<Stack direction="row" height="100%">
									{lifecycleStages?.map((stage, index) => (
										<Draggable
											draggableId={stage.id.toString()}
											index={index}
											key={stage.id}
										>
											{(provided) => (
												<Box
													ref={provided.innerRef}
													{...provided.draggableProps}
													pr={2}
												>
													<Box width="320px">
														<LifecycleStageCard
															lifecycleStage={
																stage
															}
															dragHandleProps={
																provided.dragHandleProps
															}
															updateStageStatusList={(
																data
															) =>
																updateLifecycleStagesList(
																	data,
																	index
																)
															}
														/>
													</Box>
												</Box>
											)}
										</Draggable>
									))}

									{provided.placeholder}

									{!isStageLimitExceeded && (
										<Stack
											spacing={1}
											p={3}
											alignItems="center"
											justifyContent="center"
										>
											<Box onClick={onOpen}>
												<IconButton
													color="primary"
													style={{
														backgroundColor:
															theme.palette
																.secondary.main,
													}}
												>
													{AddIcon(
														24,
														24,
														theme.palette.secondary
															.contrastText
													)}
												</IconButton>
											</Box>

											<Typography
												fontSize={13}
												fontWeight={500}
												width="70px"
												color={
													theme.palette.secondary
														.contrastText
												}
											>
												Add Stage
											</Typography>
										</Stack>
									)}
								</Stack>
							</div>
						)}
					</Droppable>
				</DragDropContext>
			</Box>
		</>
	);
}
