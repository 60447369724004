import Row from "../../../Elements/AddForm/Row/index";
import { Box, Grid } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect } from "react";
import AddFormField from "../../../Elements/AddFormField";
import { modules } from "../../../../utils/common/ModulesName";
import { useFormFieldsData } from "../../../../hooks/services/common/field";

export default function AddNewCompany({ onSubmit, onClose, companyFormData }) {
	const [fields, setFields] = useState([]);
	const [subFields, setSubFields] = useState([]);
	const [source, setSource] = useState({});
	const moduleName = modules.COMPANY;
	const formMethods = useForm({
		values: companyFormData,
		reValidateMode: false,
	});

	const { status: formFieldsStatus, data: formFieldsData } =
		useFormFieldsData(moduleName);

	useEffect(() => {
		if (formFieldsStatus === "success") {
			if (formFieldsData.fields) {
				let requiredCompanyFields = [];
				for (
					let index = 0;
					index < formFieldsData.fields.length;
					index++
				) {
					for (let field of formFieldsData.fields[index]) {
						if (field?.config?.required) {
							requiredCompanyFields = [
								...requiredCompanyFields,
								[field],
							];
						}
					}
				}
				setFields(requiredCompanyFields);
			}

			if (formFieldsData.subFields) {
				setSubFields(formFieldsData.subFields);
			}

			if (formFieldsData.source) {
				setSource(formFieldsData.source);
			}
		}
	}, [formFieldsData, formFieldsStatus]);

	return (
		<React.Fragment>
			<AddFormField
				onSave={formMethods.handleSubmit(onSubmit)}
				onClose={onClose}
			>
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<FormProvider {...formMethods}>
						<Box
							p={2}
							style={{
								flex: "1 1 auto",
								minHeight: 0,
								overflowY: "auto",
							}}
						>
							<Grid
								container
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
								spacing={2}
							>
								{fields?.map((field, index) => (
									<Row
										field={field}
										subFields={subFields}
										key={index}
										source={source}
									/>
								))}
							</Grid>
						</Box>
					</FormProvider>
				</Box>
			</AddFormField>
		</React.Fragment>
	);
}
