import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	allLifecycleStagesKey,
	getLifeCycleStatusListKey,
	rulesKey,
} from "../../../utils/queryKeys/lifecycleStage";
import {
	createLifecycleStage,
	deleteLifecycleStage,
	getAllLifecycleStage,
	getLifeCycleStatusList,
	getRules,
	updateLifecycleStage,
	updateLifecycleStageStatus,
	updateRules,
	updateStageOrder,
	updateStatusOrder,
} from "../../../api/lifecycleStage/lifecycleStageApi";

// query call :-
const useAllLifecycleStages = () => {
	return useQuery(allLifecycleStagesKey, () => getAllLifecycleStage(), {
		select: (data) => data?.stages,
	});
};

const useRules = () => {
	return useQuery(rulesKey, () => getRules(), {
		select: (data) => data?.rules,
	});
};

const useLifeCycleStatusList = (lifeCycleStageId) => {
	return useQuery(
		getLifeCycleStatusListKey(lifeCycleStageId),
		() => getLifeCycleStatusList(lifeCycleStageId),
		{
			select: (data) => data?.options,
			enabled: !!lifeCycleStageId,
		}
	);
};

// Mutation call:-
const useCreateLifecycleStage = () => {
	let invalidateAllLifecycleStage = useInvalidateAllLifecycleStage();
	return useMutation(createLifecycleStage, {
		onSuccess: () => {
			invalidateAllLifecycleStage();
		},
	});
};

const useUpdateLifecycleStage = () => {
	let invalidateAllLifecycleStage = useInvalidateAllLifecycleStage();
	return useMutation(updateLifecycleStage, {
		onSuccess: () => {
			invalidateAllLifecycleStage();
		},
	});
};

const useUpdateLifecycleStageOrder = () => {
	let invalidateAllLifecycleStage = useInvalidateAllLifecycleStage();
	return useMutation(updateStageOrder, {
		onSuccess: () => {
			invalidateAllLifecycleStage();
		},
	});
};

const useDeleteLifecycleStage = () => {
	let invalidateAllLifecycleStage = useInvalidateAllLifecycleStage();
	return useMutation(deleteLifecycleStage, {
		onSuccess: () => {
			invalidateAllLifecycleStage();
		},
	});
};

const useUpdateLifecycleStatusOrder = () => {
	let invalidateAllLifecycleStage = useInvalidateAllLifecycleStage();
	return useMutation(updateStatusOrder, {
		onSuccess: () => {
			invalidateAllLifecycleStage();
		},
	});
};

const useUpdateLifecycleStageStatus = () => {
	let invalidateAllLifecycleStage = useInvalidateAllLifecycleStage();
	return useMutation(updateLifecycleStageStatus, {
		onSuccess: () => {
			invalidateAllLifecycleStage();
		},
	});
};

const useUpdateRules = () => {
	let invalidateRules = useInvalidateRules();
	return useMutation(updateRules, {
		onSuccess: () => {
			invalidateRules();
		},
	});
};

// query invalidation call:-
const useInvalidateAllLifecycleStage = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(allLifecycleStagesKey);
	};
};

const useInvalidateRules = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(rulesKey);
	};
};

export {
	useRules,
	useUpdateRules,
	useAllLifecycleStages,
	useCreateLifecycleStage,
	useDeleteLifecycleStage,
	useUpdateLifecycleStage,
	useLifeCycleStatusList,
	useUpdateLifecycleStageOrder,
	useUpdateLifecycleStatusOrder,
	useUpdateLifecycleStageStatus,
};
