import React from "react";

export default function BarChart(width = "50", height = "50") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 50 50"
		>
			<path
				d="M5 14C5 12.8954 5.89543 12 7 12H15C16.1046 12 17 12.8954 17 14V42C17 43.1046 16.1046 44 15 44H7C5.89543 44 5 43.1046 5 42V14Z"
				fill="#CB9F5D"
			/>
			<path
				d="M33 23C33 21.8954 33.8954 21 35 21H43C44.1046 21 45 21.8954 45 23V42C45 43.1046 44.1046 44 43 44H35C33.8954 44 33 43.1046 33 42V23Z"
				fill="#5D9DCB"
			/>
			<path
				d="M19 7C19 5.89543 19.8954 5 21 5H29C30.1046 5 31 5.89543 31 7V42C31 43.1046 30.1046 44 29 44H21C19.8954 44 19 43.1046 19 42V7Z"
				fill="#5DCBAA"
			/>
		</svg>
	);
}
