import React, { useCallback, useEffect, useState } from "react";
import ContactAutoComplete from "../../../../../ContactAutoComplete";

export function ContactBaseComponent(props) {
	const {
		field,
		clearErrors,
		onChange,
		value,
		inputRef,
		error,
		onChangeFieldValueLabel,
	} = props;

	const [selectedContact, setSelectedContact] = useState("");

	const handleUpdateContact = useCallback(
		(value) => {
			setSelectedContact(value);
			onChange(value?.value || "");
		},
		[onChange]
	);

	useEffect(() => {
		// This useEffect is used to handle the edit case only:
		// - It displays the label in the edit mode by setting the selected contact label.
		// - It also processes server-side data in the edit case by formatting and setting `value`.
		// If `value` is an object with a `name` property, we assume it's valid data from the server or edit case,
		// so `setSelectedContact` is called to set the label accordingly.

		const isValidServerSideData = (value) => {
			return typeof value === "object" && Boolean(value?.name);
		};

		if (isValidServerSideData(value)) {
			handleUpdateContact(value);
		}
	}, [value, handleUpdateContact]);

	const updateContactValue = (contactValue) => {
		handleUpdateContact(contactValue ?? "");
		onChangeFieldValueLabel
			? onChangeFieldValueLabel({
					[field?.id]: contactValue?.name ?? "",
				})
			: null;
	};

	const handleSelectContact = (selectedContact) => {
		updateContactValue(selectedContact);
	};

	const removeContactValue = () => {
		updateContactValue();
	};

	const onClearErrors = () => {
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<ContactAutoComplete
				value={selectedContact}
				onChange={handleSelectContact}
				onCancel={removeContactValue}
				placeHolder={field?.placeHolder}
				error={error}
				inputRef={inputRef}
				clearErrors={onClearErrors}
			/>
		</React.Fragment>
	);
}
