import { getRootKey } from "../../rootKey";

export const getFollowersKey = () => {
	return [...getRootKey(), "followers"];
};

export const getFollowersListKey = (sourceName, entityId) => {
	return [...getFollowersKey(), sourceName, String(entityId)];
};

export const getUserAndTeamKey = (sourceName, searchValue) => {
	if (searchValue) {
		return [
			...getFollowersKey(),
			sourceName,
			"UserTeamAndTerrirtoryList",
			searchValue,
		];
	}
	return [...getFollowersKey(), sourceName, "UserTeamAndTerrirtoryList"];
};
