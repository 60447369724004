import React from "react";
import { Button, Stack, TextField } from "@mui/material";
import { FormFieldName } from "../../../../styles/twozo";
import { useCreateDashboardMutation } from "../../../../hooks/services/analytics";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function AddNewDashboard(props) {
	const { closeDialog } = props;
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();
	const navigate = useNavigate();
	const createDashboardMutation = useCreateDashboardMutation();

	const createDashboard = (data) => {
		createDashboardMutation.mutate(data, {
			onSuccess: (data) => {
				console.log(data);
				navigate(`dashboard/builder/${data.dashboardId}`);
			},
		});
	};

	return (
		<React.Fragment>
			<form onSubmit={handleSubmit(createDashboard)}>
				<Stack p={2} spacing={2}>
					<Stack spacing={0.5}>
						<FormFieldName>Dashboard Name</FormFieldName>

						<TextField
							placeholder="Eg: Financial performance dashboard"
							{...register("dashboardName", {
								required: "Dashboard Name is required",
								maxLength: {
									value: 100,
									message:
										"Max. of 100 characters are allowed",
								},
							})}
							error={!!errors.dashboardName}
							helperText={
								errors.dashboardName
									? errors.dashboardName.message
									: ""
							}
						/>
					</Stack>

					<Stack spacing={0.5}>
						<FormFieldName>Description</FormFieldName>

						<TextField
							{...register("description", {
								maxLength: {
									value: 100,
									message:
										"Max. of 100 characters are allowed",
								},
							})}
							error={!!errors.description}
							helperText={
								errors.description
									? errors.description.message
									: ""
							}
							multiline
							rows={2}
						/>
					</Stack>

					<Stack
						direction="row"
						spacing={2}
						justifyContent="end"
						pt={1}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={closeDialog}
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							type="submit"
							// color={confirmButtonColor}
							// onClick={createDashboard}
							disableElevation
							// disabled={disableConfirmButton}
						>
							Create
						</Button>
					</Stack>
				</Stack>
			</form>
		</React.Fragment>
	);
}
