import React from "react";
import { Bar } from "react-chartjs-2";
import { useTheme } from "@mui/material";

export default function BarChart({ chartData }) {
	const theme = useTheme();

	const data = {
		labels: chartData["xAxis"]["data"],
		datasets: [
			{
				label: chartData["yAxis"]["label"],
				data: chartData["yAxis"]["data"],
				backgroundColor: theme.palette.primary.main,
				barThickness: 50,
			},
		],
	};

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "bottom",
				display: false,
			},
			title: {
				display: false,
			},
		},
		scales: {
			x: {
				title: {
					display: true,
					text: chartData["xAxis"]["label"],
					padding: {
						top: 12,
					},
				},
				grid: {
					display: false,
				},
			},
			y: {
				title: {
					display: true,
					text: chartData["yAxis"]["label"],
					padding: {
						bottom: 12,
					},
				},
				grid: {
					display: false,
				},
				ticks: {
					// stepSize: 20,
				},
			},
		},
	};

	return (
		<React.Fragment>
			<Bar options={options} data={data} />
		</React.Fragment>
	);
}
