import React, { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import { FormFieldName } from "../../../../../../../styles/twozo";
import Field from "../../Field";
import { useWatch } from "react-hook-form";
import { useGetXAxisMetrics } from "../../../../../../../hooks/services/analytics";
import { useDashboardBuilderContext } from "../../../Context";

export default function ViewByXAxis(props) {
	const { control, setValue, unregister } = props;
	const { selectedChartIndex } = useDashboardBuilderContext();
	const [selectedMetricData, setSelectedMetricData] = useState(null);
	const [moduleId, viewByFieldId] = useWatch({
		control,
		name: [
			"viewProperties.metrics.0.moduleId",
			"viewProperties.viewBy.fieldId",
		],
	});
	const { data: metricsData, isLoading: isMetricsDataLoading } =
		useGetXAxisMetrics([moduleId], {
			enabled: !!moduleId,
			select: (data) =>
				data.fields.map(({ fieldName, fieldId, ...others }) => ({
					id: fieldId,
					label: fieldName,
					...others,
				})),
		});

	useEffect(() => {
		if (viewByFieldId && !isMetricsDataLoading) {
			const selectedMetricData = metricsData.find(
				(metric) => metric.id === viewByFieldId
			);
			setValue("viewProperties.viewBy.label", selectedMetricData.label);
			setSelectedMetricData(selectedMetricData);
			if (
				!selectedMetricData?.bucketBy ||
				!selectedMetricData.bucketBy.isFromAndToAllowed
			) {
				unregister("viewProperties.viewBy.bucket.units");
				unregister("viewProperties.viewBy.bucket.from");
				unregister("viewProperties.viewBy.bucket.to");
			}
		} else {
			setSelectedMetricData(null);
			setValue("viewProperties.viewBy.label", "");
			unregister("viewProperties.viewBy.bucket.units");
			unregister("viewProperties.viewBy.bucket.from");
			unregister("viewProperties.viewBy.bucket.to");
		}
	}, [
		viewByFieldId,
		metricsData,
		unregister,
		setValue,
		isMetricsDataLoading,
	]);

	return (
		<React.Fragment>
			<Stack spacing={1.5}>
				<FormFieldName pb={1}>View By (X Axis)</FormFieldName>

				<Stack spacing={0.5}>
					<FormFieldName>Field</FormFieldName>

					<Field
						name="viewProperties.viewBy.fieldId"
						control={control}
						fieldType="DROPDOWN"
						options={metricsData || []}
						rules={{
							required: {
								value: true,
								message: "Field is required",
							},
						}}
					/>
				</Stack>

				{selectedMetricData &&
					selectedMetricData.bucketBy &&
					selectedMetricData.bucketBy.isFromAndToAllowed && (
						<>
							{selectedMetricData.bucketBy.units && (
								<Stack spacing={0.5}>
									<FormFieldName>Unit</FormFieldName>

									<Field
										fieldKey={`chart-${selectedChartIndex}-unit-select`}
										name="viewProperties.viewBy.bucket.units"
										control={control}
										fieldType="DROPDOWN"
										options={
											selectedMetricData.bucketBy.units.map(
												(unit) => ({
													id: unit.unitId,
													label: unit.unitName,
												})
											) || []
										}
										rules={{
											required: {
												value: true,
												message: "Unit is required",
											},
										}}
									/>
								</Stack>
							)}

							<Stack spacing={0.5}>
								<FormFieldName>From</FormFieldName>

								<Field
									name="viewProperties.viewBy.bucket.from"
									control={control}
									fieldType="SINGLE_STRING"
									type="number"
									rules={{
										required: {
											value: true,
											message: "From is required",
										},
									}}
								/>
							</Stack>

							<Stack spacing={0.5}>
								<FormFieldName>To</FormFieldName>

								<Field
									name="viewProperties.viewBy.bucket.to"
									control={control}
									fieldType="SINGLE_STRING"
									type="number"
									rules={{
										required: {
											value: true,
											message: "To is required",
										},
									}}
								/>
							</Stack>
						</>
					)}
			</Stack>
		</React.Fragment>
	);
}
