import connectorList from "./dropdownLists/connectorList";
import countryCodeList from "./dropdownLists/countryCodeList";
import currencyName from "./dropdownLists/currency";
import dealState from "./dropdownLists/dealState";
import emailType from "./dropdownLists/emailType";
import fontStyle from "./dropdownLists/fontStyle";
import languages from "./dropdownLists/languages";
import phoneType from "./dropdownLists/phoneType";
import permissionList from "./dropdownLists/permissionList";
import visibilityList from "./dropdownLists/visibilityList";
import timeList from "./dropdownLists/time";
import elementList from "./dropdownLists/elementList";
import alignments from "./dropdownLists/alignments";
import providerList from "./dropdownLists/providerList";
import emailFolderList from "./dropdownLists/emailFolderList";
import syncDateList from "./dropdownLists/syncDateList";
import activityStatus from "./dropdownLists/activityStatus";
import availability from "./dropdownLists/availability";
import priority from "./dropdownLists/priority";
import reminder from "./dropdownLists/remainder";
import userFields from "./dropdownLists/userField";
import fieldAlignments from "./dropdownLists/fieldAlignments";
import timeZoneList from "./dropdownLists/timeZoneList";
import columns from "./dropdownLists/dealStateColumns";
import intervalList from "./dropdownLists/intervalList";
import dealStateList from "./dropdownLists/dealStateList";
import workflowTriggerSourceList from "./dropdownLists/workflowTriggerSource";
import workflowTriggerEventList from "./dropdownLists/workflowTriggerEvent";
import dateFormatList from "./dropdownLists/dateFormatList";
import timeFormatList from "./dropdownLists/timeFormatList";
import accountTimezones from "./dropdownLists/accountTimeZoneList";
import workflowTriggerList from "./dropdownLists/workflowTriggerList";
import workflowStatusList from "./dropdownLists/workflowStatusList";

const dropdownData = {
	CURRENCY: currencyName,
	EMAIL_TYPE: emailType,
	PHONE_TYPE: phoneType,
	COUNTRY_CODE: countryCodeList,
	STATE: dealState,
	CONNECTOR_LIST: connectorList,
	PERMISSION_LIST: permissionList,
	VISIBILITY_LIST: visibilityList,
	LANGUAGES: languages,
	FONT_STYLE: fontStyle,
	ELEMENT: elementList,
	TIME: timeList,
	ALIGNMENTS: alignments,
	FIELD_ALIGNMENTS: fieldAlignments,
	PROVIDER_LIST: providerList,
	EMAIL_FOLDER_LIST: emailFolderList,
	SYNC_DATE_LIST: syncDateList,
	ACTIVITY_STATUS: activityStatus,
	AVAILABILITY: availability,
	PRIORITY: priority,
	REMINDER: reminder,
	USER_FIELDS: userFields,
	TIME_ZONE_LIST: timeZoneList,
	COLUMNS: columns,
	INTERVAL_LIST: intervalList,
	DEAL_STATE_LIST: dealStateList,
	WORKFLOW_TRIGGER_SOURCE_LIST: workflowTriggerSourceList,
	WORKFLOW_TRIGGER_LIST: workflowTriggerList,
	WORKFLOW_TRIGGER_EVENT_LIST: workflowTriggerEventList,
	WORKFLOW_STATUS_LIST: workflowStatusList,
	DATE_FORMAT_LIST: dateFormatList,
	TIME_FORMAT_LIST: timeFormatList,
	ACCOUNT_TIMEZONE: accountTimezones,
};

export const getDropdownDataByName = (name) => {
	return dropdownData[name];
};

export const getDropdownData = () => {
	return dropdownData;
};
