import React from "react";
import CustomHomeComponent from "../../../../Elements/CustomHomeComponent";
import { default as twozoLogo } from "../../../../../assets/images/logo/twozo-full.png";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LinkExpried() {
	const navigate = useNavigate();

	const requestNewLink = () => {
		navigate("/forgot-password");
	};

	return (
		<React.Fragment>
			<CustomHomeComponent>
				<Stack spacing={4} p={5} pb={3}>
					<Box>
						<img
							alt="twozo"
							src={twozoLogo}
							width="108px"
							height="34px"
						/>
					</Box>

					<Stack>
						<Stack spacing={0.5} pb={2}>
							<Typography fontSize={18} fontWeight={600}>
								Link not valid
							</Typography>
							<Typography fontSize={13} fontWeight={400}>
								This link is incorrect or might have expired.
								You need to request the reset password link
								again.
							</Typography>
						</Stack>

						<Box pt={1} pb={2}>
							<Button
								variant="contained"
								disableElevation
								onClick={requestNewLink}
							>
								Request New Link
							</Button>
						</Box>
					</Stack>
				</Stack>
			</CustomHomeComponent>
		</React.Fragment>
	);
}
