import React, { useState } from "react";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { default as ShareIcon } from "../../../../../assets/icons/share";
import { default as AddIcon } from "../../../../../assets/icons/add";
import NewChartMenu from "../NewChartMenu";
import { useDashboardBuilderContext } from "../Context";

export default function DashboardMenu() {
	const theme = useTheme();
	const [chartMenuElement, setChartMenuElement] = useState(null);
	const newChartMenuOpened = Boolean(chartMenuElement);
	const { dashboardName, saveDashboard, isDashboardSaving } =
		useDashboardBuilderContext();

	const OpenNewChartMenu = (event) => {
		setChartMenuElement(event.currentTarget);
	};

	const CloseNewChartMenu = () => {
		setChartMenuElement(null);
	};

	return (
		<React.Fragment>
			<NewChartMenu
				chartMenuElement={chartMenuElement}
				newChartMenuOpened={newChartMenuOpened}
				CloseNewChartMenu={CloseNewChartMenu}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "center",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			/>

			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				spacing={2}
				px={2}
				py={1.5}
			>
				<Box>
					<Typography fontWeight={500}>{dashboardName}</Typography>
				</Box>

				<Stack direction="row" spacing={2}>
					<Button
						variant="contained"
						color="secondary"
						disableElevation
						onClick={OpenNewChartMenu}
					>
						{AddIcon(20, 20, theme.palette.primary.main)}
					</Button>

					<Button
						variant="contained"
						color="secondary"
						disableElevation
						startIcon={ShareIcon(
							20,
							20,
							theme.palette.primary.main
						)}
					>
						Share
					</Button>

					<Button
						variant="contained"
						color="secondary"
						disableElevation
					>
						Discard
					</Button>

					<Button
						variant="contained"
						onClick={saveDashboard}
						disableElevation
						disabled={isDashboardSaving}
					>
						{isDashboardSaving ? "Saving..." : "Save"}
					</Button>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
