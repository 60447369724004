import React, { useCallback, useEffect, useState } from "react";
import { useUsers } from "../../../../../../../hooks/account/user";
import UsersMenu from "../../../../../UsersMenu";

export default function SalesOwnerBaseComponent({
	onChange,
	value,
	inputRef,
	clearErrors,
	field,
	errors,
}) {
	const [selectedUser, setSelectedUser] = useState(null);

	const { loggedInUser } = useUsers();

	const updateUserValue = useCallback(
		(value, name) => {
			setSelectedUser({
				value: value,
				name: name,
			});
			onChange(value);
		},
		[onChange]
	);

	useEffect(() => {
		// If `value` is null or undefined, it defaults to showing the `loggedInUser` label,
		// If `value` is an object and contains a `name` property, it represents an existing selection,
		// so we set `selectedUser` to display the corresponding label.
		const isDefaultField = field?.config?.isDefaultField;

		const isValidServerSideData = (value) => {
			return typeof value === "object" && Boolean(value?.name);
		};

		if (!value && isDefaultField && loggedInUser) {
			updateUserValue(loggedInUser.value, loggedInUser.name);
		} else if (isValidServerSideData(value)) {
			updateUserValue(value.value, value.name);
		}
	}, [value, loggedInUser, updateUserValue, field?.config?.isDefaultField]);

	const handleOwnerInputChange = (selectedUser) => {
		updateUserValue(selectedUser?.value, selectedUser?.name);
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<UsersMenu
				value={selectedUser}
				onChange={handleOwnerInputChange}
				error={errors?.[field?.id]}
				inputRef={inputRef}
			/>
		</React.Fragment>
	);
}
