import { useMemo } from "react";
import { removeFieldsWithEmptyValues } from "../../../../utils/common";
import { modules } from "../../../../utils/common/ModulesName";
import SummaryProfileEditForm from "../../../Elements/SummaryProfileEditForm";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { usePartialUpdateDeal } from "../../../../hooks/services/deal";

export default function DealSummaryProfileEdit(params) {
	const { profile, onClose, dealId, subFields } = params;

	// mutation call :-
	const updateMutation = usePartialUpdateDeal(dealId);

	const modifiedProfileData = useMemo(() => {
		const { dealValue, primaryContact, company, title } = profile;
		const { DEAL_VALUE: subDealValue = [] } = subFields;
		const { value } = dealValue;

		const transformedData = {
			isManuallyAdded: true,
			id: value?.id,
		};

		// Mapping the values to the corresponding ids
		subDealValue.forEach((field) => {
			const fieldName = field?.name;
			const fieldId = field?.id;
			const currency = "CURRENCY";
			const priceField = "VALUE";

			if (fieldName === currency) {
				transformedData[fieldId] = {
					name: value?.CURRENCY?.name,
					value: value?.CURRENCY?.value,
				};
			} else if (fieldName === priceField) {
				transformedData[fieldId] = value?.PRICE?.value;
			}
		});

		return {
			[title?.fieldId]: title?.value,
			[company?.fieldId]: company?.value?.id
				? { name: company.value?.name, value: company.value.id }
				: null,
			[dealValue?.fieldId]: transformedData,
			[primaryContact?.fieldId]: {
				name: primaryContact?.value?.fullName,
				value: primaryContact?.value?.id,
			},
			id: dealId,
		};
	}, [profile, subFields, dealId]);

	const handleUpdateSummaryProfile = (profileData) => {
		removeFieldsWithEmptyValues(profileData);
		updateMutation.mutate(profileData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<>
			<SummaryProfileEditForm
				onClose={onClose}
				moduleName={modules.DEAL}
				values={modifiedProfileData}
				onSave={handleUpdateSummaryProfile}
				isSaving={updateMutation.isLoading}
			/>
		</>
	);
}
