import React from "react";
import { FormFieldName } from "../../../../../../../styles/twozo";
import { Stack } from "@mui/material";
import Field from "../../Field";

export default function NameField(props) {
	const { control } = props;

	return (
		<React.Fragment>
			<Stack spacing={0.5}>
				<FormFieldName>Name</FormFieldName>

				<Field
					name="viewName"
					control={control}
					rules={{
						required: {
							value: true,
							message: "Name is required",
						},
					}}
					fieldType="SINGLE_STRING"
					placeholder="Eg: Products vs Sales"
				/>
			</Stack>
		</React.Fragment>
	);
}
