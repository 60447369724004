import React, { useCallback, useEffect, useState } from "react";
import AddNewCompany from "../../../../../../Deals/AddNewDeals/AddNewCompany";
import { removeFieldsWithEmptyValues } from "../../../../../../../utils/common";
import CompanyAutoComplete from "../../../../../CompanyAutoComplete";

export function CompanyOnBoardBaseComponent(props) {
	const {
		field,
		onCompanyInputChange,
		inputRef,
		value,
		error,
		clearErrors,
		onChangeFieldValueLabel,
	} = props;

	const [isAddNewCompany, setIsAddNewCompany] = useState(false);
	const [selectedCompany, setSelectedCompany] = useState("");

	const relatedFieldId = field?.relatedField?.id;

	const companyFormData = {
		[relatedFieldId]: selectedCompany?.name,
	};

	const handleUpdateCompany = useCallback(
		(value) => {
			setSelectedCompany(value);
			onCompanyInputChange(value?.value);
		},
		[onCompanyInputChange]
	);

	useEffect(() => {
		// This useEffect is used to handle the edit case only:
		// - It displays the label in the edit mode by setting the selected company label.
		// - It also processes server-side data in the edit case by formatting and setting `value`.
		// If `value` is an object with a `name` property, we assume it's valid data from the server or edit case,
		// so `setSelectedCompany` is called to set the label accordingly.

		const isValidServerSideData = (value) => {
			return typeof value === "object" && Boolean(value?.name);
		};

		if (isValidServerSideData(value)) {
			handleUpdateCompany(value);
		}
	}, [value, handleUpdateCompany]);

	const onClearErrors = () => {
		clearErrors(field.id);
	};

	const updateCompanyValue = (companyValue) => {
		handleUpdateCompany(companyValue ?? "");
		onChangeFieldValueLabel
			? onChangeFieldValueLabel({
					[field?.id]: companyValue?.name ?? "",
				})
			: null;
	};

	const handleSelectedCompany = (value) => {
		if (!value) {
			return;
		}
		let selectedCompany = { ...value };

		if (selectedCompany?.isNewOption) {
			delete selectedCompany?.isNewOption;
			setSelectedCompany(selectedCompany);
			setIsAddNewCompany(true);
		} else {
			updateCompanyValue(selectedCompany);
		}
		onClearErrors();
	};

	const handleRemoveCompanyInput = () => {
		updateCompanyValue();
	};

	const onAddNewCompany = (formSubmitData) => {
		removeFieldsWithEmptyValues(formSubmitData);
		onCompanyInputChange(formSubmitData);
		setIsAddNewCompany(false);

		let companyValue = {
			...selectedCompany,
			name: formSubmitData[relatedFieldId],
		};
		onChangeFieldValueLabel({
			[field?.id]: formSubmitData[relatedFieldId],
		});
		setSelectedCompany(companyValue);
		onClearErrors();
	};

	const onCloseAddNewCompanyForm = () => {
		setIsAddNewCompany(false);
		setSelectedCompany("");
	};

	return (
		<React.Fragment>
			{isAddNewCompany ? (
				<AddNewCompany
					onSubmit={onAddNewCompany}
					onClose={onCloseAddNewCompanyForm}
					companyFormData={companyFormData}
				/>
			) : (
				<CompanyAutoComplete
					value={selectedCompany}
					onChange={handleSelectedCompany}
					onCancel={handleRemoveCompanyInput}
					placeHolder={field?.placeHolder}
					isAllowNewOption={true}
					inputRef={inputRef}
					error={error}
					clearErrors={onClearErrors}
				/>
			)}
		</React.Fragment>
	);
}
