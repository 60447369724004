import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getContactListKey } from "../../../../../utils/queryKeys";
import { getFieldDropdownData } from "../../../../../api/fields/config/configApi";
import { useStepOptions } from "../../../../../hooks/services/workflow";
import MultiSelectBaseComponent from "../MultiSelectComponent/MultiSelectBaseComponent";

const getSelectOptions = (data) => {
	return (
		data?.options?.map((option) => ({
			value: {
				name: option.name,
				id: option.id || option.value,
			},
		})) || []
	);
};

export default function OwnersComponent(params) {
	const { field = {}, hasError, metadata, onChange, style = {} } = params;
	const [selectedValues, setSelectedValues] = useState([]);

	const { data: stepOptions } = useStepOptions(metadata, field);

	useEffect(() => {
		if (Array.isArray(field.values)) {
			setSelectedValues(
				field.values && field.values.length > 0
					? field.values.map((val) => ({
							value: {
								name: val.value?.name,
								id: val.value?.id,
							},
						}))
					: []
			);
		}
	}, [field]);

	const { data: options } = useQuery(
		getContactListKey(),
		() => getFieldDropdownData({ name: "contactList" }),
		{
			enabled: !!field?.fieldId,
			select: getSelectOptions,
		}
	);

	const handleFieldChange = (value) => {
		setSelectedValues(value);

		field["values"] = value;
		if (onChange) {
			onChange(value);
		}
	};

	return (
		<MultiSelectBaseComponent
			style={style}
			options={options || []}
			hasError={hasError}
			stepOptions={stepOptions || []}
			selectedValues={selectedValues}
			onFieldChange={handleFieldChange}
			hasStepOptions={field?.config?.hasStepOptions}
		/>
	);
}
