import React, { useEffect, useState } from "react";
import {
	Box,
	Divider,
	IconButton,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { default as DeleteIcon } from "../../../assets/icons/delete";
import ConditionList from "./ConditionList";
import { getCriteriaFieldsKey } from "../../../utils/queryKeys/workflow";
import { useQuery } from "@tanstack/react-query";
import { getCriteriaFieldsData } from "../../../api/workflow/workflowApi";
import { default as TickIcon } from "../../../assets/icons/roundCheckBoxOn";
import { default as FillCloseIcon } from "../../../assets/icons/fillClose";
import { getFormattedDateMonthYearAndTime } from "../../../utils/DateUtils";

export default function Condition(props) {
	const {
		styles,
		readOnly,
		historyData,
		triggerSourceId,
		triggerEventId,
		deleteAction,
		setAndCondition,
		conditionSetData,
		orConditionSetData,
		setOrCondition,
	} = props;

	const theme = useTheme();
	const isConditionStatusSuccess = historyData?.status === "Success";
	const color = readOnly
		? historyData?.status
			? isConditionStatusSuccess
				? "rgba(0, 0, 0, 0.12)"
				: theme.palette.error.main
			: "rgba(0, 0, 0, 0.12)"
		: "rgba(0, 0, 0, 0.12)";

	const [activityFields, setActivityFields] = useState([]);
	const [comparatorsData, setComparatorsData] = useState({});

	const criteriaFieldsKey = getCriteriaFieldsKey(triggerSourceId);
	const { data: criteriaFieldsData } = useQuery(
		criteriaFieldsKey,
		() => getCriteriaFieldsData(triggerSourceId, triggerEventId),
		{ staleTime: 600000 }
	);

	useEffect(() => {
		if (criteriaFieldsData && criteriaFieldsData.totalRecords) {
			setActivityFields(criteriaFieldsData.criteriaFields);
			setComparatorsData(criteriaFieldsData.comparator);
		}
	}, [criteriaFieldsData]);

	const addCondition = () => {
		let updatedConditionSet = [
			...conditionSetData,
			{
				comparator: {},
				field: {},
				connector: 1,
				values: [],
			},
		];
		setAndCondition(updatedConditionSet);
	};

	const deleteCondition = (index) => {
		if (conditionSetData?.length > 1) {
			let updatedConditionSet = [
				...conditionSetData.slice(0, index),
				...conditionSetData.slice(index + 1),
			];
			setAndCondition(updatedConditionSet);
		}
	};

	const addOrCondition = () => {
		let updatedOrConditionSet = [
			...orConditionSetData,
			{
				comparator: {},
				field: {},
				connector: 1,
				values: [],
			},
		];
		setOrCondition(updatedOrConditionSet);
	};

	const deleteOrCondition = (index) => {
		if (orConditionSetData?.length > 1) {
			let updatedOrConditionSet = [
				...orConditionSetData.slice(0, index),
				...orConditionSetData.slice(index + 1),
			];
			setOrCondition(updatedOrConditionSet);
		}
	};

	const onDelete = () => {
		deleteAction();
	};

	return (
		<React.Fragment>
			<Stack
				direction="row"
				position="relative"
				sx={{ pointerEvents: readOnly ? "none" : "auto" }}
			>
				<Box
					style={{
						border: `1px solid ${color}`,
						borderRadius: "8px",
						minWidth:
							conditionSetData?.length > 1 ||
							orConditionSetData.length > 0
								? "700px"
								: "500px",
					}}
					direction="row"
					sx={{ pointerEvents: readOnly ? "none" : "auto" }}
				>
					<Stack
						direction="row"
						justifyContent="space-between"
						py={1.5}
						px={2}
					>
						<Typography style={styles.title}>
							Set Condition
						</Typography>

						{readOnly ? (
							historyData?.executedTime && (
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="center"
									spacing={1}
								>
									{isConditionStatusSuccess
										? TickIcon(
												18,
												18,
												theme.palette.primary.main
											)
										: FillCloseIcon(
												16,
												16,
												theme.palette.error.main
											)}

									<Typography fontSize={14} fontWeight={400}>
										{getFormattedDateMonthYearAndTime(
											historyData.executedTime
										)}
									</Typography>
								</Stack>
							)
						) : (
							<IconButton
								size="small"
								style={{
									padding: 0,
								}}
								onClick={onDelete}
							>
								{DeleteIcon(20, 20, theme.palette.error.main)}
							</IconButton>
						)}
					</Stack>

					<Divider style={{ borderColor: color }} />

					<Box p={2} pb={1}>
						<ConditionList
							readOnly={readOnly}
							conditionSet={conditionSetData}
							addCondition={addCondition}
							deleteCondition={deleteCondition}
							activityFields={activityFields}
							comparatorsData={comparatorsData}
							hideNewConditionSetButton={
								orConditionSetData.length > 0
							}
							addNewConditionSet={addOrCondition}
							updateConditionSet={setAndCondition}
						/>
					</Box>

					{orConditionSetData.length > 0 && (
						<>
							<Divider style={{ borderColor: color }} />

							<Stack direction="row" alignItems="center">
								<Typography
									fontSize={14}
									fontWeight={500}
									color={
										readOnly
											? "#000"
											: theme.palette.secondary
													.contrastText
									}
									p={2}
								>
									OR
								</Typography>

								<Divider
									orientation="vertical"
									flexItem
									style={{ borderColor: color }}
								/>

								<Box p={2} pb={1}>
									<ConditionList
										readOnly={readOnly}
										conditionSet={orConditionSetData}
										addCondition={addOrCondition}
										deleteCondition={deleteOrCondition}
										activityFields={activityFields}
										comparatorsData={comparatorsData}
										hideNewConditionSetButton
										addNewConditionSet={addOrCondition}
										updateConditionSet={setOrCondition}
									/>
								</Box>
							</Stack>
						</>
					)}
				</Box>

				{readOnly ? (
					<Box>
						<Typography
							pl={1}
							fontSize={14}
							fontWeight={400}
							color={
								isConditionStatusSuccess
									? "rgba(0, 0, 0, 0.6)"
									: theme.palette.error.main
							}
							style={{
								position: "absolute",
								width: "200px",
								wordWrap: "break-word",
								top: "12px",
							}}
						>
							{(historyData?.state && historyData.state) || ""}
						</Typography>
					</Box>
				) : (
					""
				)}
			</Stack>
		</React.Fragment>
	);
}
