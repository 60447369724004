import { enqueueSnackbar } from "notistack";
import { useUpdateLifecycleStage } from "../../../../hooks/services/lifecycleStage";
import LifecycleStageForm from "../LifecycleStageForm";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function EditLifecycleStage(props) {
	const { value, onClose, isAddStatusEnabled } = props;

	// mutation call:-
	const updateMutation = useUpdateLifecycleStage();

	const handleUpdateLifecycleStage = (formData) => {
		updateMutation.mutate(formData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.lifecycleStageUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<>
			<LifecycleStageForm
				isEditMode
				value={value}
				isSaving={updateMutation.isLoading}
				onClose={onClose}
				isAddStatusEnabled={isAddStatusEnabled}
				onSave={handleUpdateLifecycleStage}
			/>
		</>
	);
}
