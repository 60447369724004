import React from "react";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { enqueueSnackbar } from "notistack";
import {
	useCreateProductMutation,
	useProductData,
} from "../../../../hooks/services/product";
import {
	removeFieldsWithEmptyValues,
	removeFieldsWithIdKey,
} from "../../../../utils/common";
import AddForm from "../../../Elements/AddForm";
import { modules } from "../../../../utils/common/ModulesName";
import { useProductFieldPermissions } from "../../../../hooks/modules/auth/product";
import FieldPermissionContextProvider from "../../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useModuleName } from "../../../../hooks/modules";

export default function CloneProduct(props) {
	const { productId, onClose } = props;
	const moduleName = modules.PRODUCT;

	const { data: productData } = useProductData(productId);
	const cloneMutation = useCreateProductMutation();
	let id = "id";
	let variationId = "variationId";

	const { getModuleName } = useModuleName();
	const formHeaderLabel = `Clone ${getModuleName(moduleName)}`;

	const productFieldPermissions = useProductFieldPermissions();

	const cloneProduct = (clonedFormData) => {
		removeFieldsWithEmptyValues(clonedFormData);
		removeFieldsWithIdKey(clonedFormData, id);
		removeFieldsWithIdKey(clonedFormData, variationId);
		cloneMutation.mutate(clonedFormData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.productCloned,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<FieldPermissionContextProvider value={productFieldPermissions}>
				<AddForm
					onSave={cloneProduct}
					moduleName={moduleName}
					formData={productData}
					isSaving={cloneMutation.isLoading}
					onClose={onClose}
					hiddenSaveAndNewButton={true}
					formHeaderLabel={formHeaderLabel}
					isEditMode={true}
				></AddForm>
			</FieldPermissionContextProvider>
		</React.Fragment>
	);
}
