import React, { useEffect, useMemo } from "react";
import { Stack } from "@mui/material";
import { FormFieldName } from "../../../../../../../styles/twozo";
import { useWatch } from "react-hook-form";
import { useDuration } from "../../../../../../../hooks/services/analytics";
import Field from "../../Field";

export default function Duration(props) {
	const { control, setValue } = props;
	const [measureId, durationFieldId] = useWatch({
		control,
		name: [
			"viewProperties.metrics.0.measureId",
			"viewProperties.duration.fieldId",
		],
	});
	const { data: durationData } = useDuration(measureId, {
		enabled: !!measureId,
	});

	const [durationOptions, timeUnitsOptions] = useMemo(() => {
		if (durationData) {
			const durationOptions =
				durationData.fields?.map((option) => ({
					id: option.fieldId,
					label: option.fieldName,
				})) || [];

			const timeUnitsOptions =
				durationData.timeUnits?.map((option) => ({
					id: option.timeUnitId,
					label: option.timeUnitName,
				})) || [];

			return [durationOptions, timeUnitsOptions];
		}
		return [[], []];
	}, [durationData]);

	useEffect(() => {
		if (durationFieldId) {
			const durationOption = durationOptions.find(
				(option) => option.id === durationFieldId
			);
			setValue("viewProperties.duration.label", durationOption.label);
		} else {
			setValue("viewProperties.duration.label", "");
		}
	}, [durationFieldId, durationOptions, setValue]);

	return (
		<React.Fragment>
			<Stack spacing={1.5}>
				<Stack spacing={0.5}>
					<FormFieldName>Duration</FormFieldName>

					<Field
						fieldType="DROPDOWN"
						name="viewProperties.duration.fieldId"
						control={control}
						options={durationOptions}
						rules={{
							required: {
								value: true,
								message: "Duration is required",
							},
						}}
					/>
				</Stack>

				<Stack spacing={0.5}>
					<FormFieldName>Unit</FormFieldName>

					<Field
						fieldType="DROPDOWN"
						name="viewProperties.duration.timeUnitId"
						control={control}
						options={timeUnitsOptions}
						rules={{
							required: {
								value: true,
								message: "Unit is required",
							},
						}}
					/>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
