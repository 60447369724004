import React from "react";
import { Typography } from "@mui/material";
import { getDateMonthAndYearFormat } from "../../../../../../utils/DateUtils";

export default function RenderDateRangeFieldValue(props) {
	const { fieldValue } = props;

	if (!fieldValue) return;

	return (
		<React.Fragment>
			<Typography fontSize={14}>
				{getDateMonthAndYearFormat(fieldValue?.FROM) -
					getDateMonthAndYearFormat(fieldValue?.TO)}
			</Typography>
		</React.Fragment>
	);
}
