import React from "react";
import { Typography } from "@mui/material";
import TextComponent from "./FieldComponent/TextComponent";
import DropdownComponent from "./FieldComponent/DropdownComponent";
import TextAreaComponent from "./FieldComponent/TextAreaComponent";
import DateComponent from "./FieldComponent/DateComponent";
import NumberComponent from "./FieldComponent/NumberComponent";
import TimeComponent from "./FieldComponent/TimeComponent";
import MultipleEmailComponent from "./FieldComponent/MultipleEmailComponent";
import MultiplePhonesComponent from "./FieldComponent/MultiplePhonesComponent";
import ContactComponent from "./FieldComponent/ContactComponent";
import CompanyComponent from "./FieldComponent/CompanyComponent";
import OwnerComponent from "./FieldComponent/OwnerComponent";
import TagsComponent from "./FieldComponent/TagsComponent";
import RelatedContacts from "./FieldComponent/RelatedContactsComponent";
import MultiSelectComponent from "./FieldComponent/MultiSelectComponent";
import StepPickerComponent from "./FieldComponent/StepPickerComponent";
import EmailComponent from "./FieldComponent/EmailComponent";
import PhoneComponent from "./FieldComponent/PhoneComponent";
import CheckboxComponent from "./FieldComponent/CheckboxComponent";
import OwnersComponent from "./FieldComponent/OwnersComponent";

const fieldComponents = {
	SINGLE_STRING: TextComponent,
	IDENTIFIER: TextComponent,
	SINGLE_NUMBER: NumberComponent,
	DUAL_PICKER: DropdownComponent,
	DUAL_PICKERS: DropdownComponent,
	OWNER: OwnerComponent,
	DROPDOWN: DropdownComponent,
	MULTI_DROPDOWN: DropdownComponent,
	TEXT: TextAreaComponent,
	DATE: DateComponent,
	ADDRESS: TextComponent,
	CONTACT: ContactComponent,
	COMPANY: CompanyComponent,
	PRODUCT: DropdownComponent,
	DEAL: DropdownComponent,
	TIME: TimeComponent,
	MONETARY: NumberComponent,
	EMAIL: EmailComponent,
	PHONE: PhoneComponent,
	TAGS: TagsComponent,
	TEXT_PICKERS: MultipleEmailComponent,
	EMAILS: MultipleEmailComponent,
	PHONES: MultiplePhonesComponent,
	CONTACTS_ONBOARD: RelatedContacts,
	CONTACT_ONBOARD: DropdownComponent,
	COMPANY_ONBOARD: DropdownComponent,
	TERRITORY: DropdownComponent,
	MULTI_SELECT: MultiSelectComponent,
	STEP_PICKER: StepPickerComponent,
	NUMBER_PICKERS: MultiplePhonesComponent,
	NUMBER_PICKER: NumberComponent,
	DEAL_VALUE: NumberComponent,
	CHECKBOX: CheckboxComponent,
	OWNERS: OwnersComponent,
};

export default function Field(props) {
	const { field, metadata, style, ...other } = props;
	const FieldComponent = fieldComponents[field.fieldType];

	return (
		<React.Fragment>
			{FieldComponent ? (
				<FieldComponent
					field={field}
					metadata={metadata}
					style={style}
					{...other}
				/>
			) : (
				<Typography fontSize={14}>
					{field.fieldType} is not implemented
				</Typography>
			)}
		</React.Fragment>
	);
}
