import React, { useEffect, useMemo, useState } from "react";
import {
	Autocomplete,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	IconButton,
	Paper,
	Popper,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as DropDownIcon } from "../../../../assets/icons/dropDownCentered";
import UnKnownImage from "../../../../assets/images/contact/unknownContact.png";
import team from "../../../../assets/images/contact/multipleContact.png";

import {
	useFollowersList,
	useUpdateFollowers,
	useUserAndTeamList,
} from "../../../../hooks/services/summary/followers";
import {
	TableCellText,
	TableHeaderLabel,
	Tooltip,
} from "../../../../styles/twozo";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";
import { groupTypes } from "../../../../utils/followersUtills";

const PopperComponent = (props) => {
	return <Popper {...props} sx={{ paddingTop: "10px" }} />;
};

const CustomPaper = function (props) {
	return (
		<Paper
			{...props}
			style={{
				...props.style,
				borderRadius: "8px",
				boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
				width: "300px",
			}}
		/>
	);
};

export default function ManageFollowers(props) {
	const { sourceName, entityId, onClose } = props;
	const theme = useTheme();
	const updateFollowersMutation = useUpdateFollowers(sourceName, entityId);

	const [followerList, setFollowerList] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [selectedFollowers, setSelectedFollowers] = useState([]);
	const [isAllFollowersSelected, setIsAllFollowersSelected] = useState(false);
	const [tableRowHovered, setTableRowHovered] = useState(null);
	const [selectedIndex, setSelectedIndex] = useState(null);

	const handleTableCell = (index) => {
		setSelectedIndex(index);
	};

	const onMouseOverTableRow = (id) => setTableRowHovered(id);
	const onMouseOutTableRow = () => setTableRowHovered(null);

	const addedFollowers = followerList.filter(
		(follower) => !follower?.isUserNotAdded
	);
	const followersNotYetAdded = followerList.filter(
		(follower) => follower?.isUserNotAdded
	);

	const { data: followersDetails, status: followersDetailsStatus } =
		useFollowersList(sourceName, entityId);

	useEffect(() => {
		if (followersDetailsStatus === "success") {
			setFollowerList(followersDetails);
		}
	}, [followersDetails, followersDetailsStatus]);

	const { data: userList, isLoading: isLoadingUserList } = useUserAndTeamList(
		sourceName,
		searchValue
	);

	const getCountOfFollowers = (followers) => {
		let singleDigitCount = 9;
		if (followers.length > singleDigitCount || followers.length === 0) {
			return followers.length;
		}
		return `0${followers.length}`;
	};

	const addFollowers = () => {
		const newFollower = {
			addedAt: "-",
			permissionsOnThisSource: "-",
			role: {
				name: "-",
			},
			user: {
				name: "-",
			},
			isUserNotAdded: true,
		};

		setFollowerList([...followerList, newFollower]);
	};

	const filteredUserListData = useMemo(() => {
		const usersAndTeamUsersList = [
			...(userList?.users?.map((user) => ({
				...user,
				group: groupTypes.users,
			})) ?? []),
			...(userList?.teams?.map((team) => ({
				...team,
				group: groupTypes.teams,
			})) ?? []),
		];

		let addedFollowersIds =
			addedFollowers.length > 0
				? addedFollowers.map((follower) => follower.user.id)
				: [];

		let filteredUserList =
			addedFollowersIds.length > 0
				? usersAndTeamUsersList.filter(
						(user) =>
							user.group === groupTypes.users &&
							!addedFollowersIds.includes(user.user.id)
					)
				: usersAndTeamUsersList;

		return filteredUserList;
	}, [addedFollowers, userList]);

	const isFollowerAdditionDisabled =
		followersNotYetAdded.length > 0 || filteredUserListData.length === 0;

	const handleSearchUser = (event) => {
		const { value } = event.target;
		setSearchValue(value?.trimStart());
	};

	const updateSelectedFollowers = (userList) => {
		let newSelectedUserIds = userList
			.filter((user) => !user.isUserNotAdded)
			.map((user) => user.user.id);
		setSelectedFollowers(newSelectedUserIds);
	};

	const handleSelectFollower = (selectedFollower, selectedFollowerIndex) => {
		if (selectedFollower?.group === groupTypes.teams) {
			let selectedFollowerIds = followerList.map(
				(follower) => follower.user.id
			);

			const filteredTeamsUserList = selectedFollower.users.filter(
				(user) => !selectedFollowerIds.includes(user.user.id)
			);

			const filteredEmptyUserList = followerList.filter(
				(follower) => follower.user.name !== "-"
			);

			let filteredUserList = [
				...filteredEmptyUserList,
				...filteredTeamsUserList,
			];

			setFollowerList(filteredUserList);

			if (isAllFollowersSelected) {
				updateSelectedFollowers(filteredUserList);
			}
		} else {
			let updatedFollowersList = followerList.map((follower, index) => {
				if (index === selectedFollowerIndex) {
					return {
						...selectedFollower,
						isUserNotAdded: false,
					};
				}

				return follower;
			});

			setFollowerList(updatedFollowersList);

			if (isAllFollowersSelected) {
				updateSelectedFollowers(updatedFollowersList);
			}
		}
		setSearchValue("");
		setSelectedIndex(null);
	};

	const handleSave = (addedFollowers) => {
		const followersIds = addedFollowers?.map(
			(followers) => followers.user.id
		);

		updateFollowersMutation.mutate(
			{
				followerIds: followersIds,
				source: sourceName,
				entityId: entityId,
			},
			{
				onSuccess: () => {
					onClose();
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: `${sourceName} followers added successfully.`,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	//Select Followers
	const isFollowerSelected = (id) => selectedFollowers.indexOf(id) !== -1;

	const selectFollower = (event, id) => {
		event.stopPropagation();
		const selectedIndex = selectedFollowers?.indexOf(id);
		let newSelectedIds = [];

		if (selectedIndex === -1) {
			newSelectedIds = newSelectedIds.concat(selectedFollowers, id);
		} else {
			newSelectedIds = [
				...selectedFollowers.slice(0, selectedIndex),
				...selectedFollowers.slice(selectedIndex + 1),
			];
		}
		setSelectedFollowers(newSelectedIds);
	};

	const handleSelectAllFollowers = (event) => {
		if (event.target.checked) {
			const newSelected = followerList
				.filter((followers) => !followers.isUserNotAdded)
				.map((followers) => followers.user.id);
			setSelectedFollowers(newSelected);
			setIsAllFollowersSelected(true);
		} else {
			setSelectedFollowers([]);
			setIsAllFollowersSelected(false);
		}
	};

	const removeMultipleFollowers = () => {
		let remainingUsers = followerList.filter(
			(follower) => !selectedFollowers.includes(follower.user.id)
		);
		setFollowerList(remainingUsers);
		setSelectedFollowers([]);
	};

	const removeSingleFollower = (index) => {
		setFollowerList((followerList) => {
			let updatedFollowerList = [...followerList];
			updatedFollowerList.splice(index, 1);
			return updatedFollowerList;
		});
	};

	const renderGroup = (params) => {
		return (
			<Box key={params.key}>
				<Box fontSize={13} fontWeight={500} pl={2} pt={1} py={1}>
					{params.group}
				</Box>

				{params.children.map((child, index) => (
					<React.Fragment key={`child-${params.key}-${index}`}>
						<Box>{child}</Box>
					</React.Fragment>
				))}

				<Box pt={0.5}>
					<Divider
						sx={{
							display:
								params.group === groupTypes.users
									? "block"
									: "none",
						}}
					/>
				</Box>
			</Box>
		);
	};

	const renderInput = (params) => {
		return (
			<TextField
				{...params}
				variant="standard"
				InputProps={{
					...params.InputProps,
					disableUnderline: true,
					style: {
						fontSize: "14px",
						fontWeight: 400,
					},
				}}
				placeholder="Search"
				value={searchValue}
				onBlur={() => setSearchValue("")}
				onChange={handleSearchUser}
			/>
		);
	};

	const renderOptions = (props, option, index) => {
		return (
			<Box>
				<Box
					{...props}
					sx={{
						height: "40px",
					}}
					key={index}
					onClick={() => handleSelectFollower(option, index)}
				>
					<Stack direction="row" alignItems="center" spacing={1}>
						{option?.group === groupTypes.teams ? (
							<img
								src={team}
								alt="img"
								width="26px"
								height="26px"
							/>
						) : (
							<img
								src={UnKnownImage}
								alt="img"
								width="26px"
								height="26px"
							/>
						)}
						<Typography fontSize={13} fontWeight={400}>
							{option?.group === groupTypes.teams
								? option.name
								: option.user.name}
						</Typography>
					</Stack>
				</Box>{" "}
			</Box>
		);
	};

	return (
		<React.Fragment>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontSize={16} fontWeight={600}>
							Manage Followers
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				{selectedFollowers.length > 0 ? (
					<Box px={2} pt={2}>
						<Stack alignItems="flex-end" justifyContent="center">
							<Typography
								fontSize={13}
								fontWeight={600}
								color={theme.palette.primary.main}
								borderRadius="8px"
								bgcolor={theme.palette.secondary.main}
								style={{
									padding: "4px 8px",
									cursor: "pointer",
								}}
								onClick={removeMultipleFollowers}
							>
								Remove from Followers (
								{getCountOfFollowers(selectedFollowers)})
							</Typography>
						</Stack>
					</Box>
				) : null}

				<Box
					px={2}
					py={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
					}}
				>
					<Box sx={{ height: "100%" }}>
						<Box
							sx={{
								border: "1px solid rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
								height: "100%",
								overflowY: "auto",
							}}
						>
							<TableContainer>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell
												padding="checkbox"
												width="10%"
											>
												<Stack
													alignItems="center"
													justifyContent="center"
												>
													<Checkbox
														indeterminate={
															selectedFollowers.length >
																0 &&
															selectedFollowers.length <
																addedFollowers.length
														}
														checked={
															addedFollowers.length >
																0 &&
															selectedFollowers.length ===
																addedFollowers.length
														}
														onChange={
															handleSelectAllFollowers
														}
													/>
												</Stack>
											</TableCell>

											<TableCell width="30%">
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<TableHeaderLabel>
														User
													</TableHeaderLabel>
													{DropDownIcon(
														12,
														12,
														"rgba(0, 0, 0, 0.5)"
													)}
												</Stack>
											</TableCell>

											<TableCell width="30%">
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<TableHeaderLabel>
														Permission
													</TableHeaderLabel>
													{DropDownIcon(
														12,
														12,
														"rgba(0, 0, 0, 0.5)"
													)}
												</Stack>
											</TableCell>

											<TableCell width="20%">
												<Stack
													direction="row"
													alignItems="center"
													spacing={0.5}
												>
													<TableHeaderLabel>
														Role
													</TableHeaderLabel>
													{DropDownIcon(
														12,
														12,
														"rgba(0, 0, 0, 0.5)"
													)}
												</Stack>
											</TableCell>

											<TableCell
												style={{ width: "10%" }}
											></TableCell>
										</TableRow>
									</TableHead>

									<TableBody>
										<TableRow>
											<TableCell></TableCell>
											<TableCell colSpan={4}>
												<Typography
													fontWeight={500}
													fontSize={13}
													color={
														theme.palette.secondary
															.contrastText
													}
													style={{
														pointerEvents:
															isFollowerAdditionDisabled
																? "none"
																: "auto",
														cursor: isFollowerAdditionDisabled
															? "not-allowed"
															: "pointer",
														opacity:
															isFollowerAdditionDisabled
																? 0.6
																: 1,
													}}
													onClick={addFollowers}
												>
													+ Add Followers
												</Typography>
											</TableCell>
										</TableRow>
										{followerList?.map(
											(follower, index) => (
												<TableRow
													key={index}
													selected={isFollowerSelected(
														follower.user.id
													)}
													onMouseOver={() =>
														onMouseOverTableRow(
															follower.user.id
														)
													}
													onMouseOut={
														onMouseOutTableRow
													}
												>
													<TableCell
														padding="checkbox"
														width="10%"
													>
														<Stack
															alignItems="center"
															justifyContent="center"
														>
															{follower.isUserNotAdded ? (
																<Stack
																	alignItems="center"
																	onClick={() =>
																		removeSingleFollower(
																			index
																		)
																	}
																>
																	{CloseIcon(
																		20,
																		20,
																		"rgba(0, 0, 0, 0.6)"
																	)}
																</Stack>
															) : (
																<Checkbox
																	checked={isFollowerSelected(
																		follower
																			.user
																			.id
																	)}
																	onClick={(
																		event
																	) =>
																		selectFollower(
																			event,
																			follower
																				.user
																				.id
																		)
																	}
																/>
															)}
														</Stack>
													</TableCell>

													<TableCell
														width="30%"
														onClick={() =>
															handleTableCell(
																index
															)
														}
														style={{
															borderBottom:
																selectedIndex ===
																	index &&
																follower.isUserNotAdded
																	? `2px solid ${theme.palette.primary.main}`
																	: null,
														}}
													>
														{follower.isUserNotAdded ? (
															<Box>
																<Autocomplete
																	open={
																		searchValue.trim()
																			.length >
																		0
																	}
																	loading={
																		isLoadingUserList
																	}
																	PopperComponent={
																		PopperComponent
																	}
																	PaperComponent={
																		CustomPaper
																	}
																	forcePopupIcon={
																		false
																	}
																	disableClearable
																	autoHighlight={
																		true
																	}
																	options={
																		filteredUserListData ||
																		[]
																	}
																	groupBy={(
																		option
																	) =>
																		option
																			.users
																			?.length >
																		0
																			? groupTypes.teams
																			: groupTypes.users
																	}
																	renderGroup={
																		renderGroup
																	}
																	getOptionLabel={(
																		option
																	) =>
																		option
																			.users
																			?.length >
																		0
																			? option.name
																			: option
																					.user
																					.name
																	}
																	loadingText={
																		<Stack
																			justifyContent="center"
																			alignItems="center"
																		>
																			<CircularProgress size="20px" />
																		</Stack>
																	}
																	renderOption={(
																		props,
																		option
																	) =>
																		renderOptions(
																			props,
																			option,
																			index
																		)
																	}
																	renderInput={(
																		params
																	) =>
																		renderInput(
																			params,
																			follower
																				.user
																				.name
																		)
																	}
																/>
															</Box>
														) : (
															<Stack
																direction="row"
																alignItems="center"
																spacing={1}
															>
																<img
																	src={
																		UnKnownImage
																	}
																	alt="img"
																	width={26}
																	height={26}
																/>
																<TableCellText>
																	{
																		follower
																			.user
																			.name
																	}
																</TableCellText>
															</Stack>
														)}
													</TableCell>

													<TableCell width="30%">
														<TableCellText
															style={{
																color: theme
																	.palette
																	.primary
																	.main,
															}}
															fontWeight={500}
														>
															{
																follower.permissionsOnThisSource
															}
														</TableCellText>
													</TableCell>

													<TableCell width="20%">
														<TableCellText
															fontWeight={500}
														>
															{follower.role.name}
														</TableCellText>
													</TableCell>

													<TableCell
														sx={{
															py: 0,
															pl: 0,
															pr: 1,
															width: "10%",
														}}
													>
														{!follower.isUserNotAdded &&
															!selectedFollowers.includes(
																tableRowHovered
															) &&
															tableRowHovered ===
																follower.user
																	.id && (
																<Stack alignItems="flex-end">
																	<Box
																		style={{
																			width: "fit-content",
																			border: `1px solid ${theme.palette.primary.main}`,
																			borderRadius:
																				"8px",
																			padding:
																				"2px 8px",
																		}}
																	>
																		<Tooltip
																			title="Remove from Followers"
																			placement="top"
																		>
																			<IconButton
																				size="small"
																				onClick={() =>
																					removeSingleFollower(
																						index
																					)
																				}
																			>
																				{CloseIcon(
																					20,
																					20,
																					theme
																						.palette
																						.error
																						.main
																				)}
																			</IconButton>
																		</Tooltip>
																	</Box>
																</Stack>
															)}
													</TableCell>
												</TableRow>
											)
										)}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Box>

					<Stack
						direction="row"
						justifyContent="flex-end"
						py={1}
						px={2}
						sx={{
							position: "fixed",
							bottom: "108px",
							right: "40px",
							backgroundColor: theme.palette.secondary.main,
							borderRadius: "8px",
						}}
					>
						<Typography
							style={{
								fontSize: "14px",
								fontWeight: "500",
								color: "rgba(0, 0, 0, 0.6)",
							}}
						>
							Total: {getCountOfFollowers(addedFollowers)}
						</Typography>
					</Stack>
				</Box>

				<Divider />

				<Box p={2}>
					<Button
						variant="contained"
						disableElevation
						onClick={() => handleSave(addedFollowers)}
						disabled={updateFollowersMutation.isLoading}
					>
						Save
					</Button>
				</Box>
			</Box>
		</React.Fragment>
	);
}
