import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Chip,
	CircularProgress,
	MenuItem,
	Select,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import UserImage from "../../../../../assets/images/contact/unknownContact.png";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { useUsers } from "../../../../../hooks/account/user";
import { useLastRowRef } from "../../../../../hooks/common/InfiniteScroll";

export default function Collaborator(props) {
	const { value, onChange } = props;
	const [searchedValue, setSearchValue] = useState("");
	const [collaborators, setCollaborators] = useState([]);

	const {
		activeUserList,
		isLoadingUsers,
		isFetchingUsers,
		hasNextUsersPage,
		fetchNextUsersPage,
	} = useUsers(searchedValue);

	const lastOptionRef = useLastRowRef(
		fetchNextUsersPage,
		hasNextUsersPage,
		isFetchingUsers
	);

	// This useEffect will run only initial render
	useEffect(() => {
		if (Array.isArray(value)) {
			// Check if the server value needs to be formatted
			const isNotInServerFormat = () => {
				return value.some((collaborator) => collaborator?.name);
			};

			if (isNotInServerFormat()) {
				setCollaborators(
					value.map((collaborator) => {
						return {
							name: collaborator?.name,
							value: collaborator?.id,
						};
					})
				);
				onChange(
					value.map((collaborator) => {
						return collaborator?.id;
					})
				);
			}
		}
	}, [onChange, value]);

	// Filter users that are not yet selected
	const filteredUserList = useMemo(() => {
		if (Array.isArray(activeUserList)) {
			return activeUserList?.filter(
				(user) => !value?.includes(user?.value)
			);
		}
		return [];
	}, [activeUserList, value]);

	const handleSearch = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const handleCollaboratorChange = (event, child) => {
		const { value } = event.target;
		let newSelectedUserId = value?.[value?.length - 1];

		onChange(value);
		setCollaborators((collaborators) => {
			return [
				...collaborators,
				{ name: child?.props?.name, value: newSelectedUserId },
			];
		});
	};

	const unSelectCollaborator = (removedUser) => {
		const updatedCollaborator = collaborators.filter(
			(collaborator) => collaborator?.value !== removedUser?.value
		);

		onChange(
			updatedCollaborator?.map((collaborator) => collaborator?.value)
		);
		setCollaborators(updatedCollaborator);
	};

	const isCollaboratorSelected = (value) => {
		return value?.length > 0;
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{activeUserList?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	return (
		<Select
			displayEmpty
			multiple
			value={value || []}
			onChange={handleCollaboratorChange}
			MenuProps={{
				autoFocus: false,
				PaperProps: {
					style: {
						marginTop: "4px",
						borderRadius: "8px",
						maxHeight: "255px",
					},
				},
			}}
			renderValue={
				isCollaboratorSelected(value)
					? () => (
							<Box>
								{collaborators?.map((collaborator) => (
									<Chip
										size="small"
										key={collaborator}
										label={
											<Typography
												fontSize={14}
												color="#000"
											>
												{collaborator?.name}
											</Typography>
										}
										color="secondary"
										deleteIcon={
											<Stack
												direction="row"
												alignItems="center"
												onMouseDown={(event) =>
													event.stopPropagation()
												}
											>
												{CloseIcon(16, 16, "grey")}
											</Stack>
										}
										onDelete={() =>
											unSelectCollaborator(collaborator)
										}
										sx={{
											borderRadius: "8px",
											marginRight: "4px",
										}}
									/>
								))}
							</Box>
						)
					: () => (
							<Typography
								fontSize={14}
								color="rgba(0, 0, 0, 0.6)"
							>
								Select
							</Typography>
						)
			}
			onOpen={() => setSearchValue("")}
		>
			<Box
				p={0.5}
				sx={{
					position: "sticky",
					top: 0,
					zIndex: 1,
					backgroundColor: "#fff",
				}}
			>
				<MenuSearchBar value={searchedValue} onChange={handleSearch} />
			</Box>

			{isLoadingUsers ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="150px"
				>
					<CircularProgress size={18} />
				</Stack>
			) : filteredUserList?.length > 0 ? (
				filteredUserList.map((user) => (
					<MenuItem
						key={user?.value}
						value={user?.value}
						name={user?.name}
						style={{
							minHeight: "40px",
						}}
						autoFocus={false}
					>
						<Stack direction="row" spacing={2} alignItems="center">
							<img
								src={UserImage}
								alt="contact_image"
								height="24px"
								width="24px"
							/>

							<Typography fontSize={13}>{user?.name}</Typography>
						</Stack>
					</MenuItem>
				))
			) : (
				renderEmptyView()
			)}

			<Box ref={lastOptionRef}>
				{!isLoadingUsers &&
					isFetchingUsers &&
					activeUserList?.length > 0 && (
						<MenuItem style={{ height: "40px" }}>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="center"
								spacing={1}
							>
								<Skeleton
									variant="circular"
									width="24px"
									height="24px"
								/>

								<Skeleton width="190px" height="12px" />
							</Stack>
						</MenuItem>
					)}
			</Box>
		</Select>
	);
}
