import React from "react";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { useDashboardBuilderContext } from "../../Context";

export default function Charts(props) {
	const { onClose } = props;
	const {
		chartTypeListData,
		isChartTypeListDataLoading,
		addNewChart,
		getChartIcon,
	} = useDashboardBuilderContext();

	const addChart = (chartTypeData) => {
		addNewChart({
			viewName: "",
			viewTypeId: chartTypeData.id,
			viewProperties: {},
			meta: {
				viewType: chartTypeData.viewBy,
				viewIcon: chartTypeData.icon,
			},
		});
		onClose();
	};

	return (
		<React.Fragment>
			{isChartTypeListDataLoading ? (
				<>Loading</>
			) : (
				chartTypeListData.viewList?.map((chartTypeData) => (
					<MenuItem
						key={chartTypeData.id}
						style={{ height: "48px" }}
						onClick={() => addChart(chartTypeData)}
					>
						<Stack direction="row" spacing={2} alignItems="center">
							<Box pt={0.5}>
								{getChartIcon(chartTypeData.icon.name, 24, 24)}
							</Box>
							<Typography fontSize={14}>
								{chartTypeData.icon.displayName}
							</Typography>
						</Stack>
					</MenuItem>
				))
			)}
		</React.Fragment>
	);
}
