import React, { useState, useEffect } from "react";
import { useTagDropdownList } from "../../../../../../../hooks/services/tags";
import TagsInput from "./TagsInput";

export function TagsBaseComponent(props) {
	const { inputRef, field, error, clearErrors, onChange, value } = props;

	const [selectedTags, setSelectedTags] = useState([]);

	const { data: tagsList } = useTagDropdownList();

	// This useEffect will run only initial render
	useEffect(() => {
		if (Array.isArray(value)) {
			// Check if the server value needs to be formatted
			const isNotInServerFormat = () => {
				return (
					Array.isArray(value) &&
					value.some((tagData) => tagData?.name)
				);
			};

			if (isNotInServerFormat()) {
				setSelectedTags(value.map((tagData) => tagData.name));
				onChange(
					value.map((tagData) => {
						return { value: tagData.value };
					})
				);
			}
		}
	}, [value, onChange]);

	const getTagIdByName = (tagName) => {
		let tagData = tagsList?.find((tag) => tag?.name === tagName);
		if (tagData) {
			return tagData.value;
		}
	};

	const handleSelectTags = (values) => {
		setSelectedTags(values);
		let tagsForRequest = [];
		for (let tag of values) {
			let tagId = getTagIdByName(tag);
			if (tagId) {
				tagsForRequest.push({ value: tagId });
			} else {
				tagsForRequest.push({ tag: tag });
			}
		}
		onChange(tagsForRequest);
	};

	const onClearErrors = () => {
		clearErrors(field.id);
	};

	return (
		<React.Fragment>
			<TagsInput
				inputRef={inputRef}
				tagsList={tagsList || []}
				selectedTags={selectedTags}
				handleSelectTags={handleSelectTags}
				onClearErrors={onClearErrors}
				error={error}
				placeHolder={field?.placeHolder}
			/>
		</React.Fragment>
	);
}
