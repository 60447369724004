import React from "react";

export default function PieChart(width = "50", height = "50") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 50 50"
		>
			<path
				d="M25 3C21.5282 3 18.1056 3.82168 15.0122 5.39786C11.9188 6.97403 9.24232 9.25996 7.20163 12.0687C5.16094 14.8775 3.81397 18.1294 3.27086 21.5584C2.72774 24.9875 3.0039 28.4965 4.07676 31.7984L25 25V3Z"
				fill="#CB9F5D"
				stroke="white"
			/>
			<path
				d="M5.97887 31.1803C6.95419 34.1821 8.62609 36.9104 10.8579 39.1421C13.0896 41.3739 15.8179 43.0458 18.8197 44.0211C21.8214 44.9965 25.0113 45.2475 28.1287 44.7538C31.246 44.26 34.2023 43.0355 36.7557 41.1803L25 25L5.97887 31.1803Z"
				fill="#5D9DCB"
				stroke="white"
			/>
			<path
				d="M25 5C29.2236 5 33.3388 6.3371 36.7557 8.81966C40.1727 11.3022 42.716 14.8028 44.0211 18.8197C45.3263 22.8365 45.3263 27.1635 44.0211 31.1803C42.716 35.1972 40.1727 38.6978 36.7557 41.1803L25 25V5Z"
				fill="#5DCBAA"
				stroke="white"
			/>
		</svg>
	);
}
