import TextComponent from "./FieldComponents/TextComponent";
import CompanyComponent from "./FieldComponents/CompanyComponent";
import EmailComponent from "./FieldComponents/EmailComponent";
import PhoneComponent from "./FieldComponents/PhoneComponent";
import DropdownComponent from "./FieldComponents/DropdownComponent";
import NumberInputComponent from "./FieldComponents/NumberInputComponent";
import TextAreaComponent from "./FieldComponents/TextAreaComponent";
import AddressComponent from "./FieldComponents/AddressComponent";
import MonetaryComponent from "./FieldComponents/MonetaryComponent";
import { ContactOnBoardComponent } from "./FieldComponents/ContactOnBoardComponent";
import PriceTagsComponent from "./FieldComponents/PriceTagsComponent";
import { Grid, Stack, Typography, useTheme } from "@mui/material";
import { CompanyOnBoardComponent } from "./FieldComponents/CompanyOnBoardComponent";
import { ProductComponent } from "./FieldComponents/ProductComponent";
import DateComponent from "./FieldComponents/DateComponent";
import DateRangeComponent from "./FieldComponents/DateRangeComponent";
import TimeComponent from "./FieldComponents/TimeComponent";
import { useEffect, useRef } from "react";
import MultiEmailComponent from "./FieldComponents/MultiEmailComponent";
import MultiPhoneComponent from "./FieldComponents/MultiPhoneComponent";
import { useFormContext, useWatch } from "react-hook-form";
import SalesOwnerComponent from "./FieldComponents/SalesOwnerComponent";
import MultiSelectComponent from "./FieldComponents/MultiSelectComponent";
import DateTimeRangeComponent from "./FieldComponents/DateTimeRangeComponent";
import TimeRangeComponent from "./FieldComponents/TimeRangeComponent";
import { ContactsOnBoardComponent } from "./FieldComponents/ContactsOnBoardComponent";
import ContactComponent from "./FieldComponents/ContactComponent";
import TerritoryComponent from "./FieldComponents/TerritoryComponent";
import TagsComponent from "./FieldComponents/TagsComponent";
import { FormFieldName } from "../../../../styles/twozo";
import DealTitleComponent from "./FieldComponents/DealTitleComponent";
import { useFormAuxDataContext } from "../FormAuxDataContext";

const fieldComponents = {
	IDENTIFIER: DealTitleComponent,
	SINGLE_STRING: TextComponent,
	COMPANY: CompanyComponent,
	EMAILS: MultiEmailComponent,
	PHONES: MultiPhoneComponent,
	EMAIL: EmailComponent,
	PHONE: PhoneComponent,
	DROPDOWN: DropdownComponent,
	SINGLE_NUMBER: NumberInputComponent,
	TEXT: TextAreaComponent,
	ADDRESS: AddressComponent,
	MONETARY: MonetaryComponent,
	CONTACTS_ONBOARD: ContactsOnBoardComponent,
	CONTACT_ONBOARD: ContactOnBoardComponent,
	COMPANY_ONBOARD: CompanyOnBoardComponent,
	DEAL_VALUE: ProductComponent,
	PRICE_TAGS: PriceTagsComponent,
	DATE: DateComponent,
	DATE_RANGE: DateRangeComponent,
	TIME: TimeComponent,
	OWNER: SalesOwnerComponent,
	MULTI_SELECT: MultiSelectComponent,
	DATE_TIME_RANGE: DateTimeRangeComponent,
	TIME_RANGE: TimeRangeComponent,
	CONTACT: ContactComponent,
	TERRITORY: TerritoryComponent,
	TAGS: TagsComponent,
};

export default function Field({
	field,
	subField,
	isHalfField,
	source,
	summaryFieldId,
}) {
	const formValues = useWatch();
	const inputRef = useRef(null);
	const FieldComponent = fieldComponents[field.type];
	const {
		setValue,
		formState: { errors },
	} = useFormContext();
	const theme = useTheme();

	const { onChangeFieldValueLabel, auxiliaryFormData } =
		useFormAuxDataContext();

	// Validate a single rule
	function validateRule(rule, formData) {
		const fieldValue = formData[rule.field];

		const isConditionEquals = (fieldValue) => {
			if (typeof fieldValue === "object" && fieldValue?.value) {
				return fieldValue.value === rule.value;
			} else {
				return fieldValue === rule.value;
			}
		};

		switch (rule.condition) {
			case "EQUALS":
				return isConditionEquals(fieldValue);

			default:
				return false;
		}
	}

	// Validate Nested rule
	function validateRules(rules, formData, connector) {
		let allRulesValid = true; //AND
		let anyRuleValid = false; //OR

		for (const rule of rules) {
			let ruleValid;

			if (rule.condition) {
				ruleValid = validateRule(rule, formData);
			} else if (rule.rules) {
				// Nested rule with connector
				ruleValid = validateRules(rule.rules, formData, rule.connector);
			} else {
				return false;
			}

			if (connector === "AND" && !ruleValid) {
				return false;
			} else if (connector === "OR" && ruleValid) {
				return true;
			}
		}

		return connector === "AND" ? allRulesValid : anyRuleValid;
	}

	const checkFieldVisibility = () => {
		if (field?.config?.showWhen) {
			const showWhen = field?.config?.showWhen;
			const rules = showWhen.rules;
			const connector = showWhen.connector;

			if (connector) {
				return validateRules(rules, formValues, connector);
			} else {
				if (rules[0]?.connector) {
					return validateRules(
						rules[0].rules,
						formValues,
						rules[0]?.connector
					);
				} else {
					// single rule validation
					const rule = rules[0];
					if (rule) {
						return validateRule(rule, formValues);
					}
				}
			}
		} else {
			return true;
		}
	};

	const isFieldVisible = checkFieldVisibility();

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	useEffect(() => {
		if (!isFieldVisible) {
			setValue(field.id.toString(), "");
		}
	}, [field.id, isFieldVisible, setValue]);

	return isFieldVisible && FieldComponent ? (
		<Grid item xs={12} md={isHalfField ? 6 : 12}>
			<Stack spacing={0.5}>
				<FormFieldName
					color={errors[field.id] && theme.palette.error.main}
				>
					{field.fieldName}
					{field.config.required ? (
						<span
							style={{
								color: theme.palette.error.main,
								marginLeft: "5px",
							}}
						>
							*
						</span>
					) : null}
				</FormFieldName>

				<FieldComponent
					field={field}
					subField={subField}
					source={source}
					inputRef={summaryFieldId === field.id ? inputRef : null}
					values={formValues}
					onChangeFieldValueLabel={onChangeFieldValueLabel}
					auxiliaryFormData={auxiliaryFormData}
				/>

				{errors[field.id] ? (
					<Typography
						style={{
							fontSize: 13,
							color: theme.palette.error.main,
							marginLeft: "6px",
						}}
					>
						{errors[field.id]?.message}
					</Typography>
				) : null}
			</Stack>
		</Grid>
	) : null;
}
