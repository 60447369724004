import React, { useEffect, useState } from "react";
import {
	Box,
	Divider,
	MenuItem,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import Menu from "../../Elements/Menu";
import { getDropdownDataByName } from "../../../utils/dropdownData";
import { default as TickIcon } from "../../../assets/icons/roundCheckBoxOn";
import { getFormattedDateMonthYearAndTime } from "../../../utils/DateUtils";

export default function Trigger(props) {
	const { styles, setTrigger, sourceId, eventId, readOnly, historyData } =
		props;
	const theme = useTheme();
	const [selectedSource, setSelectedSource] = useState(sourceId);
	const [selectedEvent, setSelectedEvent] = useState(eventId);
	const [sourceMenuElement, setSourceMenuElement] = useState(null);
	const isSourceMenuOpened = Boolean(sourceMenuElement);
	const [eventMenuElement, setEventMenuElement] = useState(null);
	const isActionMenuOpened = Boolean(eventMenuElement);
	const sources = getDropdownDataByName("WORKFLOW_TRIGGER_SOURCE_LIST");
	const events = getDropdownDataByName("WORKFLOW_TRIGGER_EVENT_LIST");

	useEffect(() => {
		if (sourceId) {
			const source = sources.find((source) => source.value === sourceId);
			setSelectedSource(source);
		}
	}, [sourceId, sources]);

	useEffect(() => {
		if (eventId) {
			const event = events.find((event) => event.value === eventId);
			setSelectedEvent(event);
		}
	}, [eventId, events]);

	const OpenSourceMenu = (event) => {
		setSourceMenuElement(event.currentTarget);
	};

	const CloseSourceMenu = () => {
		setSourceMenuElement(null);
	};

	const OpenEventMenu = (event) => {
		setEventMenuElement(event.currentTarget);
	};

	const CloseEventMenu = () => {
		setEventMenuElement(null);
	};

	const changeModule = (source) => {
		setSelectedSource(source);
		CloseSourceMenu();
		updateTrigger(source, selectedEvent);
	};

	const changeAction = (event) => {
		setSelectedEvent(event);
		CloseEventMenu();
		updateTrigger(selectedSource, event);
	};

	const updateTrigger = (source, event) => {
		if (source && event && source.value && event.value) {
			setTrigger(source.value, event.value);
		}
	};

	return (
		<React.Fragment>
			<Menu
				anchorEl={sourceMenuElement}
				open={isSourceMenuOpened}
				onClose={CloseSourceMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				minWidth="200px"
				style={{ marginTop: "-8px" }}
			>
				{sources.map((source) => (
					<MenuItem
						key={source.value}
						style={{ minHeight: "40px" }}
						selected={source.name === selectedSource?.name}
						onClick={() => changeModule(source)}
					>
						<Typography fontSize={13}>{source.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			<Menu
				anchorEl={eventMenuElement}
				open={isActionMenuOpened}
				onClose={CloseEventMenu}
				anchorOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "left",
				}}
				style={{ marginTop: "-8px", minWidth: "200px" }}
			>
				{events.map((event) => (
					<MenuItem
						key={event.value}
						style={{ minHeight: "40px" }}
						selected={event.name === selectedEvent?.name}
						onClick={() => changeAction(event)}
					>
						<Typography fontSize={13}>{event.name}</Typography>
					</MenuItem>
				))}
			</Menu>

			<Stack
				direction="row"
				position="relative"
				sx={{ pointerEvents: readOnly ? "none" : "auto" }}
			>
				<Box
					style={{
						border: "1px solid rgba(0, 0, 0, 0.12)",
						borderRadius: "8px",
						minWidth: "600px",
					}}
				>
					<Stack
						py={1.5}
						px={2}
						direction="row"
						justifyContent="space-between"
					>
						<Typography style={styles.title}>
							Trigger{" "}
							<span style={{ color: theme.palette.error.main }}>
								*
							</span>
						</Typography>

						{readOnly
							? historyData?.executedTime && (
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="center"
										spacing={1}
									>
										{TickIcon(
											18,
											18,
											theme.palette.primary.main
										)}

										<Typography
											fontSize={14}
											fontWeight={400}
										>
											{getFormattedDateMonthYearAndTime(
												historyData.executedTime || ""
											)}
										</Typography>
									</Stack>
								)
							: ""}
					</Stack>

					<Divider />

					<Stack
						direction="row"
						alignItems="center"
						spacing={2}
						p={2}
					>
						<Typography style={styles.text}>When</Typography>

						<Typography
							style={
								selectedSource
									? styles.textButton
									: styles.selectTextButton
							}
							onClick={OpenSourceMenu}
						>
							{selectedSource ? selectedSource.name : "Select"}
						</Typography>

						{!!selectedSource && (
							<>
								<Typography style={styles.text}>is</Typography>

								<Typography
									style={
										selectedEvent
											? styles.textButton
											: styles.selectTextButton
									}
									onClick={OpenEventMenu}
								>
									{selectedEvent
										? selectedEvent.name
										: "Select"}
								</Typography>
							</>
						)}
					</Stack>
				</Box>

				{readOnly ? (
					<Box>
						<Typography
							pl={1}
							fontSize={14}
							fontWeight={400}
							color="rgba(0, 0, 0, 0.6)"
							style={{
								position: "absolute",
								width: "400px",
								wordWrap: "break-word",
								top: "12px",
							}}
						>
							{(historyData?.state && historyData.state) || ""}
						</Typography>
					</Box>
				) : (
					""
				)}
			</Stack>
		</React.Fragment>
	);
}
