import {
	Box,
	MenuItem,
	Select,
	Stack,
	styled,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuSearchBar from "../../MenuSearchBar";
import { forwardRef, useCallback, useImperativeHandle } from "react";
import { Controller, useForm } from "react-hook-form";
import { useFieldDropdownList } from "../../../../hooks/services/common/field";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import CloseIcon from "../../../../assets/icons/close";

const CustomSelect = styled(Select)(() => ({
	"& .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&:hover .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
	"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
		border: "none",
	},
}));

const DropdownFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		// query call:-
		const { data: dropdownOptions } = useFieldDropdownList(fieldId);

		const [filteredDropdownList, setFilteredDropdownList] = useState([]);

		const {
			clearErrors,
			control,
			formState: { errors },
			handleSubmit,
			setValue,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const [selectedValue, setSelectedValue] = useState({
			name: "",
			value: "",
		});
		const [searchValue, setSearchValue] = useState("");

		const handleSearchValue = (event) => {
			const { value } = event.target;
			setSearchValue(value);
		};

		useEffect(() => {
			const getFilteredDropdownList = () => {
				if (Array.isArray(dropdownOptions)) {
					if (searchValue.trim()) {
						return dropdownOptions?.filter((option) =>
							//filter for search
							option.name
								.toLowerCase()
								.startsWith(searchValue.toLowerCase())
						);
					}
					return dropdownOptions;
				}
				return [];
			};
			setFilteredDropdownList(getFilteredDropdownList());
		}, [dropdownOptions, searchValue]);

		const renderEmptyView = () => {
			return (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="40px"
				>
					<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
						{dropdownOptions?.length === 0
							? "No Options Found"
							: "No Results Found"}
					</Typography>
				</Stack>
			);
		};

		useEffect(() => {
			if (value && value?.name) {
				setSelectedValue({
					name: value?.name,
					value: value?.id,
				});
				setValue(fieldId + "", value?.id);
			}
		}, [value, setValue, fieldId]);

		const handleChangeDropdownOption = (event, onChange, child) => {
			let { value } = event.target;
			let modifiedEvent = {
				...event,
				target: {
					...event.target,
					value: value,
				},
			};
			onChange(modifiedEvent);
			setSelectedValue({
				name: child.props.name,
				value: value,
			});
			clearErrors(fieldId + "");
			onError(null);
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		const handleRemove = (onChange) => {
			setSelectedValue({
				name: "",
				value: "",
			});
			onChange(null);
		};

		return (
			<>
				<Controller
					control={control}
					name={fieldId + ""}
					defaultValue=""
					rules={handleFieldValidation(field)}
					render={({ field: { onChange, value } }) => (
						<CustomSelect
							sx={{
								width: "300px",
								borderColor: theme.palette.primary.main,
							}}
							size="small"
							fullWidth
							autoFocus={true}
							value={value}
							onChange={(event, child) =>
								handleChangeDropdownOption(
									event,
									onChange,
									child
								)
							}
							MenuProps={{
								autoFocus: false,
								PaperProps: {
									style: {
										maxHeight: 300,
										marginTop: "4px",
										borderRadius: "8px",
									},
								},
							}}
							displayEmpty
							renderValue={() => (
								<Typography
									fontSize={14}
									color={
										selectedValue?.value
											? "#000"
											: "rgba(0, 0, 0, 0.6)"
									}
								>
									{selectedValue?.value
										? selectedValue?.name
										: "Select"}
								</Typography>
							)}
							onOpen={() => setSearchValue("")}
						>
							<Box
								p={0.5}
								marginTop="-8px"
								sx={{
									position: "sticky",
									top: 0,
									zIndex: 1,
									backgroundColor: "#fff",
								}}
							>
								<MenuSearchBar
									value={searchValue}
									onChange={handleSearchValue}
								/>
							</Box>

							{filteredDropdownList?.length > 0
								? filteredDropdownList?.map((option) => (
										<MenuItem
											value={option.value}
											key={option.value}
											name={option.name}
											style={{ height: "40px" }}
										>
											<Stack
												width="100%"
												direction="row"
												spacing={1}
												alignItems="center"
												justifyContent="space-between"
											>
												<Typography fontSize={14}>
													{option.name}
												</Typography>

												{selectedValue?.value ===
												option?.value ? (
													<Box
														display="flex"
														onClick={() =>
															handleRemove(
																onChange
															)
														}
													>
														{CloseIcon(
															20,
															20,
															"#000",
															0.6
														)}
													</Box>
												) : null}
											</Stack>
										</MenuItem>
									))
								: renderEmptyView()}
						</CustomSelect>
					)}
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

DropdownFieldEditForm.displayName = "DateFieldEditForm";

export default DropdownFieldEditForm;
