import React, { useMemo } from "react";
import { Stack } from "@mui/material";
import { FormFieldName } from "../../../../../../../styles/twozo";
import { useWatch } from "react-hook-form";
import { useDuration } from "../../../../../../../hooks/services/analytics";
import Field from "../../Field";

export default function CompareTo(props) {
	const { control } = props;
	const measureId = useWatch({
		control,
		name: "viewProperties.metrics.0.measureId",
	});
	const { data: durationData } = useDuration(measureId, {
		enabled: !!measureId,
	});

	const compareToOptions = useMemo(() => {
		if (durationData) {
			return (
				durationData.compareTo?.map((option) => ({
					id: option.compareUnitId,
					label: option.compareUnitName,
				})) || []
			);
		}
		return [];
	}, [durationData]);

	return (
		<React.Fragment>
			<Stack spacing={1.5}>
				<Stack spacing={0.5}>
					<FormFieldName>Compare To</FormFieldName>

					<Field
						fieldType="DROPDOWN"
						name="viewProperties.compareTo.compareUnitId"
						control={control}
						options={compareToOptions}
						rules={{
							required: {
								value: true,
								message: "Compare To is required",
							},
						}}
					/>
				</Stack>
			</Stack>
		</React.Fragment>
	);
}
