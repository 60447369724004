import { apiClient } from "..";
import { getFollowersUrl } from "../apiConfig";
import { assertError } from "../apiHelper";

export const getFollowers = async (source, entityId) => {
	let requestData = getFollowersRequest(source, entityId);
	return await postFollowersManagementApi(requestData);
};

export const getUserAndTeamList = async (sourceName, searchValue) => {
	let requestData = getUserAndTeamListRequest(sourceName, searchValue);
	return await postFollowersManagementApi(requestData);
};

export const updateFollowers = async ({ followerIds, source, entityId }) => {
	let requestData = getUpdateFollowersRequest(followerIds, source, entityId);
	return await postFollowersManagementApi(requestData);
};

const getFollowersRequest = (source, entityId) => {
	return {
		type: "getFollowers",
		data: {
			source: source,
			entityId: entityId,
		},
	};
};

const getUserAndTeamListRequest = (sourceName, searchValue = "") => {
	return {
		type: "getTeamsAndUsersList",
		data: {
			source: sourceName,
			criteria: {
				type: "contains",
				value: searchValue,
			},
			sort: {
				type: "asc",
				field: "role",
			},
		},
	};
};

const getUpdateFollowersRequest = (followerIds, source, entityId) => {
	return {
		type: "update",
		data: {
			followerIds: followerIds,
			source: source,
			entityId: entityId,
		},
	};
};

const postFollowersManagementApi = async (requestData) => {
	let followersUrl = getFollowersManagementUrl();
	let response = await apiClient.post(followersUrl, requestData);
	assertError(response, followersUrl);
	return response.data.data;
};

const getFollowersManagementUrl = () => {
	return getFollowersUrl() + "/mgmt";
};
