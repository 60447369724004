import React, { useEffect, useMemo, useState } from "react";
import {
	Box,
	Button,
	Divider,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { FormFieldName, hexToRgba, twozoStyles } from "../../styles/twozo";
import { default as EditIcon } from "../../assets/icons/edit";
import Trigger from "./Trigger";
import Action from "./Action";
import End from "./End";
import Connector from "./Connector";
import Condition from "./Condition";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Menu from "../Elements/Menu";
import { getTemplateListKey } from "../../utils/queryKeys/workflow";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
	createWorkflow,
	getTemplateData,
} from "../../api/workflow/workflowApi";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../utils/notification/notificationConfig";
import {
	useUpdateWorkflow,
	useWorkflowData,
	useWorkflowHistoryData,
} from "../../hooks/services/workflow";
import { notificationMessage } from "../../utils/notification/notificationMessages";
import { getFieldValue } from "./Field/workflowParser";
import { maxActionLimit } from "./WorkflowConfig/config";

const workflowType = {
	Trigger: "trigger",
	Condition: "condition",
	Action: "action",
	End: "end",
};

export default function Workflow() {
	const theme = useTheme();
	let [queryParams] = useSearchParams();

	const templateId = queryParams.get("templateId");
	const historyWorkflowId = queryParams.get("workflowId");
	const workflowHistoryId = queryParams.get("historyId");
	const preview = queryParams.get("preview");

	const { workflowId } = useParams();
	const navigate = useNavigate();
	const classes = twozoStyles();

	const defaultTriggerData = {
		type: workflowType.Trigger,
		sourceId: "",
		eventId: "",
	};

	const defaultConditionData = {
		type: workflowType.Condition,
		conditionSet: [
			{
				comparator: {},
				field: {},
				connector: 1,
				value: [],
			},
		],
		orConditionSet: [],
	};

	const defaultActionData = {
		type: workflowType.Action,
		source: {},
		event: {},
		fieldMapping: [],
	};

	const defaultEndData = {
		type: workflowType.End,
	};

	const defaultWorkflows = [
		{
			id: "1",
			...defaultTriggerData,
		},
	];
	const readOnly = preview === "true";
	const automationOrHistoryWorkflowId = historyWorkflowId || workflowId;

	// query call:-
	const { data: workflow, isLoading: isLoadingWorkflowData } =
		useWorkflowData(automationOrHistoryWorkflowId);
	const { data: historyData } = useWorkflowHistoryData(workflowHistoryId);

	// mutation call:-
	const updateWorkflowMutation = useUpdateWorkflow(
		automationOrHistoryWorkflowId
	);

	const [workflowName, setWorkflowName] = useState("Workflow 1");
	const [workflowDescription, setWorkflowDescription] = useState("");
	const [newWorkflowName, setNewWorkflowName] = useState("Workflow 1");
	const [newWorkflowDescription, setNewWorkflowDescription] = useState("");
	const [workflowMenuHovered, setWorkflowMenuHovered] = useState(false);
	const [workflowMenuElement, setWorkflowMenuElement] = useState(null);
	const isWorkflowMenuOpened = Boolean(workflowMenuElement);
	const [workflows, setWorkflows] = useState(defaultWorkflows);

	const createWorkflowMutation = useMutation(
		async (workflowData) => createWorkflow(workflowData),
		{
			onSuccess: () => {
				closeSnackbar();
				enqueueSnackbar({
					variant: notificationVariants.success,
					title: "Success!",
					preventDuplicate: true,
					message: notificationMessage.workflowCreated,
				});
			},
			onError: (error) => {
				error = JSON.parse(error.message);
				closeSnackbar();

				error?.validationErrors.forEach((validationError) => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						title: "Error!",
						// autoHideDuration: 6000,
						message: validationError,
					});
				});
			},
		}
	);

	const templateKey = getTemplateListKey(templateId);
	const { data: templateData, isLoading: isLoadingTemplateData } = useQuery(
		templateKey,
		() => getTemplateData(templateId),
		{
			staleTime: 600000,
			enabled: !!templateId && templateId !== "-1",
			select: (data) => data?.template,
		}
	);

	useEffect(() => {
		const workflowApiData =
			!isLoadingTemplateData && templateData
				? templateData
				: !isLoadingWorkflowData && workflow
					? workflow
					: null;

		if (workflowApiData) {
			let workflowData = [];
			let templateOrWorkflowData = templateData || workflow;

			setWorkflowName(templateOrWorkflowData.name);
			setWorkflowDescription(templateOrWorkflowData.description);

			// Add trigger
			workflowData.push({
				id: "trigger",
				type: workflowType.Trigger,
				sourceId: templateOrWorkflowData.trigger.source,
				eventId: templateOrWorkflowData.trigger.event,
			});

			// Add Condition
			if (
				templateOrWorkflowData.conditions &&
				templateOrWorkflowData.conditions.length > 0
			) {
				workflowData.push({
					id: "condition",
					type: workflowType.Condition,
					conditionSet:
						templateOrWorkflowData.conditions[0].criteria || [],
					orConditionSet:
						templateOrWorkflowData.conditions.length > 1
							? templateOrWorkflowData.conditions[1].criteria ||
								[]
							: [],
				});
			}

			// Add Action
			if (
				templateOrWorkflowData.actions &&
				templateOrWorkflowData.actions.length > 0
			) {
				templateOrWorkflowData.actions.forEach((action, index) => {
					workflowData.push({
						id: "action-" + index,
						type: workflowType.Action,
						source: action.source,
						event: action.event,
						fieldMapping: action.fieldMapping,
					});
				});
			}

			//Add End
			workflowData.push({
				id: "end",
				type: workflowType.End,
			});

			setWorkflows(workflowData);
		}
	}, [templateData, isLoadingTemplateData, workflow, isLoadingWorkflowData]);

	const styles = {
		text: {
			fontSize: "13px",
			fontWeight: 500,
		},
		title: {
			fontSize: "14px",
			fontWeight: 500,
		},
		textButton: {
			fontSize: "14px",
			fontWeight: 500,
			color: readOnly ? "#000" : theme.palette.secondary.contrastText,
			cursor: "pointer",
			userSelect: "none",
		},
		selectTextButton: {
			fontSize: "14px",
			fontWeight: 500,
			color: readOnly ? "#000" : theme.palette.secondary.contrastText,
			opacity: 0.6,
			cursor: "pointer",
			userSelect: "none",
		},
	};

	const OpenWorkflowMenu = (event) => {
		if (readOnly) {
			return;
		}

		setNewWorkflowName(workflowName);
		setNewWorkflowDescription(workflowDescription);
		setWorkflowMenuElement(event.currentTarget);
	};

	const CloseWorkflowMenu = () => {
		setWorkflowMenuElement(null);
	};

	const SaveWorkflowNameAndDescription = () => {
		setWorkflowName(newWorkflowName);
		setWorkflowDescription(newWorkflowDescription);
		CloseWorkflowMenu();
	};

	const AddWorkflow = (newWorkflowType, index) => {
		let newWorkflow = {
			id: workflows.length + 1 + "",
		};

		switch (newWorkflowType) {
			case workflowType.Trigger:
				newWorkflow = {
					...newWorkflow,
					...defaultTriggerData,
				};
				break;
			case workflowType.Condition:
				newWorkflow = {
					...newWorkflow,
					...defaultConditionData,
				};
				break;
			case workflowType.Action:
				newWorkflow = {
					...newWorkflow,
					...defaultActionData,
				};
				break;
			case workflowType.End:
				newWorkflow = {
					...newWorkflow,
					...defaultEndData,
				};
				break;
			default:
				break;
		}

		setWorkflows((workflows) => [
			...workflows.slice(0, index),
			newWorkflow,
			...workflows.slice(index),
		]);
	};

	const AddAction = (index) => {
		AddWorkflow(workflowType.Action, index);
	};

	const deleteAction = (index) => {
		setWorkflows((workflows) => [
			...workflows.slice(0, index),
			...workflows.slice(index + 1),
		]);
	};

	const AddCondition = (index) => {
		AddWorkflow(workflowType.Condition, index);
	};

	const setTrigger = (index, sourceId, eventId) => {
		let updatedWorkflow = [...workflows];
		updatedWorkflow[index]["sourceId"] = sourceId;
		updatedWorkflow[index]["eventId"] = eventId;

		//Remove conditions and actions if trigger source or event changed
		updatedWorkflow = [updatedWorkflow[index]];
		setWorkflows(updatedWorkflow);

		if (updatedWorkflow.length === 1) {
			AddWorkflow(workflowType.Action, index + 1);
		}
	};

	const setAction = (index, source, event) => {
		let updatedWorkflow = [...workflows];
		updatedWorkflow[index]["source"]["id"] = source.id;
		updatedWorkflow[index]["source"]["name"] = source.name;
		if (event) {
			updatedWorkflow[index]["event"]["id"] = event.id;
			updatedWorkflow[index]["event"]["name"] = event.name;
		} else {
			updatedWorkflow[index]["event"] = {};
		}
		setWorkflows(updatedWorkflow);

		if (index === workflows.length - 1)
			AddWorkflow(workflowType.End, index + 1);
	};

	const setFieldMappings = (index, fieldMappings) => {
		let updatedWorkflow = [...workflows];
		updatedWorkflow[index]["fieldMapping"] = fieldMappings;
		setWorkflows(updatedWorkflow);
	};

	const setConditionSet = (index, conditionSet) => {
		let updatedWorkflow = [...workflows];
		updatedWorkflow[index]["conditionSet"] = conditionSet;
		setWorkflows(updatedWorkflow);
	};

	const setOrConditionSet = (index, orConditionSet) => {
		let updatedWorkflow = [...workflows];
		updatedWorkflow[index]["orConditionSet"] = orConditionSet;
		setWorkflows(updatedWorkflow);
	};

	const getCriteriaFromCondition = (conditionSet) => {
		let criteria = [];
		conditionSet.forEach((condition) => {
			let conditionData = {};
			conditionData["fieldId"] = condition.field.id;
			conditionData["connector"] = condition.connector || 1; // Statically setting connector to 1 (AND) since there is no OR condition
			conditionData["comparator"] = condition.comparator.id;
			if (condition.values) {
				conditionData["values"] = getFieldValue(condition) || [];
			}
			criteria.push(conditionData);
		});
		return criteria;
	};

	const getEmailFieldsMappingData = (data) => {
		return Object.keys(data)
			.map((key) => {
				const { fieldId, values = [] } = data[key];
				if (Array.isArray(values) && values.length > 0) {
					return {
						fieldId,
						values: getFieldValue(data[key]),
					};
				}
			})
			.filter((isNotNull) => !!isNotNull);
	};

	const saveWorkflow = () => {
		let workflowRequestData = {};
		workflowRequestData["name"] = workflowName;
		workflowRequestData["description"] = workflowDescription;
		if (templateId) {
			workflowRequestData["templateId"] = templateId;
		}
		workflowRequestData["isActive"] = true;
		workflowRequestData["trigger"] = {};
		workflowRequestData["actions"] = [];

		workflows.forEach((workflow) => {
			switch (workflow.type) {
				case workflowType.Trigger:
					workflowRequestData.trigger["source"] = workflow.sourceId;
					workflowRequestData.trigger["event"] = workflow.eventId;
					break;
				case workflowType.Condition:
					workflowRequestData["conditions"] = [];
					if (
						workflow.conditionSet &&
						workflow.conditionSet.length > 0
					) {
						let criteria = getCriteriaFromCondition(
							workflow.conditionSet
						);
						workflowRequestData["conditions"].push({
							connector: 1,
							criteria,
						});
					}

					if (
						workflow.orConditionSet &&
						workflow.orConditionSet.length > 0
					) {
						let criteria = getCriteriaFromCondition(
							workflow.orConditionSet
						);
						workflowRequestData["conditions"].push({ criteria });
					}
					break;
				case workflowType.Action:
					(function () {
						let action = {};
						action["source"] = workflow.source.id;
						action["event"] = workflow.event.id;
						action["fieldMapping"] = [];

						if (
							workflow.fieldMapping &&
							Array.isArray(workflow.fieldMapping)
						) {
							workflow.fieldMapping.forEach(
								(fieldMapping, index) => {
									fieldMapping = fieldMapping[0];
									if (
										Array.isArray(fieldMapping?.values) &&
										fieldMapping?.values?.length > 0
									) {
										let fieldMappingData = {
											fieldId: fieldMapping.fieldId,
											isEnabled:
												fieldMapping.config
													?.isEnabled ||
												fieldMapping.config
													?.isRequired ||
												false,
											isRequired:
												fieldMapping.config
													?.isRequired || false,
											values:
												getFieldValue(fieldMapping) ||
												[],
										};
										action["fieldMapping"].push([
											fieldMappingData,
										]);
									} else {
										delete workflow.fieldMapping[index];
									}
								}
							);
						} else {
							const emailFieldsMapping =
								getEmailFieldsMappingData(
									workflow.fieldMapping
								);
							action["fieldMapping"] = emailFieldsMapping;
						}
						workflowRequestData.actions.push(action);
					})();
					break;
			}
		});

		const hasEmptyFieldMapping = workflowRequestData.actions.some(
			(action) => action?.fieldMapping?.length === 0
		);

		// Check if there are no actions or if any field mapping is empty, and show an error message if true
		if (workflowRequestData.actions.length === 0 || hasEmptyFieldMapping) {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.workflowOnSaveErrorMessage,
			});
			return;
		}

		if (workflowId && workflowId !== "-1") {
			workflowRequestData.id = workflow.id;
			updateWorkflowMutation.mutate(workflowRequestData, {
				onSuccess: () => {
					closeSnackbar();
					enqueueSnackbar({
						variant: notificationVariants.success,
						title: "Success!",
						message: notificationMessage.workflowUpdated,
					});
					navigate("/settings/workflow-management?type=automation");
				},
				onError: (error) => {
					error = JSON.parse(error.message);
					closeSnackbar();

					error?.validationErrors.forEach((validationError) => {
						enqueueSnackbar({
							variant: notificationVariants.error,
							title: "Error!",
							message: validationError,
						});
					});
				},
			});
		} else {
			createWorkflowMutation.mutate(workflowRequestData, {
				onSuccess: () => {
					closeSnackbar();
					enqueueSnackbar({
						variant: notificationVariants.success,
						title: "Success!",
						preventDuplicate: true,
						message: notificationMessage.workflowCreated,
					});
					navigate(-1);
				},
				onError: (error) => {
					error = JSON.parse(error.message);
					closeSnackbar();

					error?.validationErrors.forEach((validationError) => {
						enqueueSnackbar({
							variant: notificationVariants.error,
							title: "Error!",
							message: validationError,
						});
					});
				},
			});
		}
	};

	const isAddActionVisible = useMemo(() => {
		return (
			workflows.filter(
				(workflow) => workflow.type === workflowType.Action
			).length < maxActionLimit
		);
	}, [workflows]);

	const conditionFields = useMemo(() => {
		return workflows.filter(
			(workflow) => workflow.type === workflowType.Condition
		);
	}, [workflows]);

	const shouldShowAddCondition = (index) => {
		return index === 1 && conditionFields.length === 0;
	};

	const hideAddIconByCondition = (index) => {
		return index === 1 && conditionFields.length !== 0;
	};

	const getActionIndex = (index) => {
		if (historyData?.condition) {
			return index - 2;
		}
		return index - 1;
	};

	return (
		<React.Fragment>
			<Menu
				anchorEl={workflowMenuElement}
				open={isWorkflowMenuOpened}
				onClose={CloseWorkflowMenu}
				minWidth="500px"
				style={{ marginTop: "-8px" }}
			>
				<Stack spacing={2} p={2}>
					<Stack spacing={0.5}>
						<FormFieldName>Title</FormFieldName>
						<TextField
							placeholder="Enter title"
							value={newWorkflowName}
							onChange={(e) => setNewWorkflowName(e.target.value)}
							fullWidth
						/>
					</Stack>

					<Stack spacing={0.5}>
						<FormFieldName>Description</FormFieldName>
						<TextField
							placeholder="Enter description here"
							value={newWorkflowDescription}
							onChange={(e) =>
								setNewWorkflowDescription(e.target.value)
							}
							fullWidth
						/>
					</Stack>

					<Stack
						direction="row"
						justifyContent="flex-end"
						spacing={2}
						p={1}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={CloseWorkflowMenu}
							disableElevation
						>
							Cancel
						</Button>

						<Button
							variant="contained"
							color="primary"
							onClick={SaveWorkflowNameAndDescription}
							disableElevation
						>
							Save
						</Button>
					</Stack>
				</Stack>
			</Menu>

			<Box
				style={{
					height: "calc(100% - 64px)", //TODO: The top bar height is handle as static value, In future It will be handled as dynamically
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box className={classes.menuBar}>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						spacing={2}
						height="100%"
					>
						<Box
							p={1}
							onClick={OpenWorkflowMenu}
							onMouseEnter={() => setWorkflowMenuHovered(true)}
							onMouseLeave={() => setWorkflowMenuHovered(false)}
							style={{ cursor: "pointer" }}
						>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<Stack
									direction="row"
									alignItems="center"
									spacing={1}
									justifyContent="center"
								>
									<Typography fontWeight={500}>
										{workflowName}
									</Typography>

									{readOnly ? (
										<Typography
											fontSize={14}
											fontWeight={500}
											color="rgba(0, 0, 0, 0.6)"
										>
											(View Only)
										</Typography>
									) : (
										""
									)}
								</Stack>

								{(workflowMenuHovered ||
									!!workflowMenuElement) &&
									!readOnly && (
										<Box display="flex">
											{EditIcon(
												16,
												16,
												theme.palette.primary.main
											)}
										</Box>
									)}
							</Stack>

							<Typography
								fontSize={14}
								color={
									workflowDescription
										? "rgba(0, 0, 0, 0.6)"
										: hexToRgba(
												theme.palette.primary.main,
												0.6
											)
								}
								style={{ cursor: "pointer" }}
							>
								{workflowDescription
									? workflowDescription
									: "Add description"}
							</Typography>
						</Box>

						{readOnly ? (
							<Box>
								<Button
									variant="contained"
									disableElevation
									onClick={() =>
										navigate(
											`/settings/workflow/${historyWorkflowId}`
										)
									}
								>
									Edit Automation
								</Button>
							</Box>
						) : null}
					</Stack>
				</Box>

				<Divider />

				<Box
					style={{
						flexGrow: 1,
						overflowY: "auto",
						height: "0px", // Need height for the overflowY to work. Refer - https://stackoverflow.com/a/14964944/8653179
					}}
				>
					<Stack alignItems="center" p={4}>
						{workflows.map((workflow, index) => {
							switch (workflow.type) {
								case workflowType.Trigger:
									return (
										<Trigger
											key={workflow.id}
											styles={styles}
											sourceId={workflow.sourceId}
											eventId={workflow.eventId}
											setTrigger={(source, event) =>
												setTrigger(index, source, event)
											}
											readOnly={readOnly}
											historyData={historyData?.trigger}
										/>
									);
								case workflowType.Condition:
									return (
										<React.Fragment key={workflow.id}>
											<Connector
												readOnly={readOnly}
												AddAction={() =>
													AddAction(index)
												}
												AddCondition={() =>
													AddCondition(index)
												}
												isAddActionVisible={
													isAddActionVisible
												}
												isAddConditionVisible={shouldShowAddCondition(
													index
												)}
												isAddIconVisible={hideAddIconByCondition(
													index
												)}
											/>
											<Condition
												readOnly={readOnly}
												styles={styles}
												triggerSourceId={
													workflows[0].sourceId
												}
												triggerEventId={
													workflows[0].eventId
												}
												deleteAction={() =>
													deleteAction(index)
												}
												conditionSetData={
													workflow.conditionSet
												}
												orConditionSetData={
													workflow.orConditionSet
												}
												setAndCondition={(condition) =>
													setConditionSet(
														index,
														condition
													)
												}
												setOrCondition={(condition) =>
													setOrConditionSet(
														index,
														condition
													)
												}
												historyData={
													historyData?.condition
												}
											/>
										</React.Fragment>
									);
								case workflowType.Action:
									return (
										<React.Fragment key={workflow.id}>
											<Connector
												readOnly={readOnly}
												AddAction={() =>
													AddAction(index)
												}
												AddCondition={() =>
													AddCondition(index)
												}
												isAddActionVisible={
													isAddActionVisible
												}
												isAddConditionVisible={shouldShowAddCondition(
													index
												)}
												isAddIconVisible={hideAddIconByCondition(
													index
												)}
											/>
											<Action
												index={index}
												readOnly={readOnly}
												styles={styles}
												triggerSourceId={
													workflows[0].sourceId
												}
												triggerEventId={
													workflows[0].eventId
												}
												deleteAction={() =>
													deleteAction(index)
												}
												setAction={(source, event) =>
													setAction(
														index,
														source,
														event
													)
												}
												setFieldMappings={(data) =>
													setFieldMappings(
														index,
														data
													)
												}
												source={workflow.source}
												event={workflow.event}
												fieldMapping={
													workflow.fieldMapping
												}
												historyData={
													historyData?.actions?.[
														getActionIndex(index)
													]
												}
											/>
										</React.Fragment>
									);
								case workflowType.End:
									return (
										<React.Fragment key={workflow.id}>
											<Connector
												readOnly={readOnly}
												AddAction={() =>
													AddAction(index)
												}
												AddCondition={() =>
													AddCondition(index)
												}
												isAddConditionVisible={shouldShowAddCondition(
													index
												)}
												isAddActionVisible={
													isAddActionVisible
												}
												isAddIconVisible={hideAddIconByCondition(
													index
												)}
											/>
											<End styles={styles} />
										</React.Fragment>
									);
							}
							return null;
						})}
					</Stack>
				</Box>

				{!readOnly ? <Divider /> : null}

				<Box className={classes.menuBar}>
					{!readOnly ? (
						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							spacing={2}
							height="100%"
						>
							<Button
								variant="contained"
								color="secondary"
								onClick={() => navigate(-1)}
								disableElevation
							>
								Cancel
							</Button>

							<Button
								variant="contained"
								color="primary"
								onClick={saveWorkflow}
								disabled={createWorkflowMutation.isLoading}
								disableElevation
								style={{
									minWidth: "112px",
								}}
							>
								Save
							</Button>
						</Stack>
					) : null}
				</Box>
			</Box>
		</React.Fragment>
	);
}
