import { Box, Button, Stack, Grid, CircularProgress } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import React, { useState, useEffect } from "react";
import Row from "../AddForm/Row";
import { useGroupedFormFields } from "../../../hooks/services/common/field";
import { FormAuxDataProvider } from "../AddForm/FormAuxDataContext/FormAuxDataProvider";
import Menu from "../Menu";

export default function GroupFieldForm({
	moduleName,
	onSave,
	onClose,
	fieldId,
	formData,
	isSaving,
	isGroupFieldDataLoading,
	isGroupFieldMenuOpen,
	groupFieldMenuElement,
}) {
	const [fields, setFields] = useState([]);
	const [subFields, setSubFields] = useState([]);

	const formMethods = useForm({
		values: formData,
		reValidateMode: false, // validation triggered only when the form Submit
	});

	const {
		status: formFieldsAPIstatus,
		isLoading: isLoadingFormFields,
		data: formFieldsData,
	} = useGroupedFormFields(moduleName, fieldId);

	useEffect(() => {
		if (formFieldsAPIstatus === "success") {
			if (formFieldsData.fields) {
				setFields(formFieldsData.fields);
			}

			if (formFieldsData.subFields) {
				setSubFields(formFieldsData.subFields);
			}
		}
	}, [formFieldsData, formFieldsAPIstatus]);

	return (
		<React.Fragment>
			<Menu
				open={isGroupFieldMenuOpen}
				anchorEl={groupFieldMenuElement}
				onClose={onClose}
				sx={{
					"& .MuiMenu-list": {
						paddingTop: "0px",
					},
				}}
			>
				{isLoadingFormFields || isGroupFieldDataLoading ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="244px"
						width="400px"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : (
					<Box
						pt={1}
						style={{
							maxHeight: "45vh",
							width: "400px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<FormProvider {...formMethods}>
							<FormAuxDataProvider>
								<Box
									style={{
										flex: "1 1 auto",
										minHeight: 0,
										overflowY: "auto",
										padding: "16px 16px 1px 16px",
									}}
								>
									<Grid
										container
										direction="row"
										justifyContent="flex-start"
										alignItems="baseline"
										spacing={2}
									>
										{fields.map((field, index) => (
											<Row
												field={field}
												subFields={subFields}
												key={index}
											/>
										))}
									</Grid>
								</Box>
							</FormAuxDataProvider>
						</FormProvider>

						<Stack
							direction="row"
							justifyContent="flex-end"
							alignItems="center"
							spacing={2}
							pr={2}
							pb={2}
							pt={3}
						>
							<Stack direction="row" spacing={2} pr={1}>
								<Box>
									<Button
										variant="contained"
										color="secondary"
										disableElevation
										onClick={onClose}
										disabled={isSaving}
									>
										Cancel
									</Button>
								</Box>
								<Box>
									<Button
										variant="contained"
										disableElevation
										onClick={formMethods.handleSubmit(
											onSave
										)}
										disabled={isSaving}
									>
										Update
									</Button>
								</Box>
							</Stack>
						</Stack>
					</Box>
				)}
			</Menu>
		</React.Fragment>
	);
}
