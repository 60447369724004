import React, { useCallback, useEffect, useState } from "react";
import { Box, MenuItem, Select, Stack, Typography } from "@mui/material";
import MenuSearchBar from "../../../../../../MenuSearchBar";
import { default as CloseIcon } from "../../../../../../../../assets/icons/close";
import { default as DropdownIcon } from "../../../../../../../../assets/icons/dropDownCentered";

export default function Dropdown(props) {
	const { options, onChange, value, inputRef, field, clearErrors, error } =
		props;
	const [selectedOption, setSelectedOption] = useState({
		name: "",
		value: "",
	});
	const [searchValue, setSearchValue] = useState("");

	const hideSearchBar = options?.length === 0;

	const updateSelectedValue = useCallback(
		(updatedValue = "") => {
			setSelectedOption(updatedValue);
			onChange(updatedValue?.value);
		},
		[onChange]
	);

	// This useEffect will run only initial render
	useEffect(() => {
		const getDefaultValue = () => {
			const defaultValue = options?.find((option) => option.isDefault);
			if (defaultValue) {
				return defaultValue;
			}
			return "";
		};

		const checkIfTheValueMatches = options?.some(
			(option) => option.value === value
		);

		const isNotServerSideFormatted = value && typeof value === "object";

		if (options?.length > 0) {
			// Check if the server value needs to be formatted (run only once)
			if (isNotServerSideFormatted) {
				updateSelectedValue(value);
			} else if (!value || !checkIfTheValueMatches) {
				let defaultValue = getDefaultValue();
				updateSelectedValue(defaultValue);
			}
		}
	}, [options, onChange, value, updateSelectedValue]);

	const handleChangeDropdownOption = (event, child) => {
		let { value } = event.target;
		updateSelectedValue({ value: value, name: child.props.name });
		clearErrors(field.id);
	};

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const getFilteredOptionsList = () => {
		if (searchValue.trim()) {
			return options?.filter((option) =>
				//filter for search
				option.name.toLowerCase().includes(searchValue.toLowerCase())
			);
		}
		return options;
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					{options?.length === 0
						? "No Options Found"
						: "No Results Found"}
				</Typography>
			</Stack>
		);
	};

	const handleRemove = () => {
		setSelectedOption({ name: "", value: "" });
		onChange(null);
	};

	return (
		<React.Fragment>
			<Select
				size="small"
				value={selectedOption.value || ""}
				inputRef={inputRef}
				onChange={handleChangeDropdownOption}
				error={!!error}
				displayEmpty
				sx={{ width: "100%" }}
				MenuProps={{
					autoFocus: false,
					PaperProps: {
						style: {
							maxHeight: 300,
							marginTop: "4px",
							borderRadius: "8px",
						},
					},
				}}
				onOpen={() => setSearchValue("")}
				renderValue={() => (
					<Typography
						noWrap
						fontSize={14}
						color={
							selectedOption.value ? "#000" : "rgba(0, 0, 0, 0.6)"
						}
					>
						{selectedOption.name
							? selectedOption.name
							: field?.placeHolder || "Select"}
					</Typography>
				)}
				IconComponent={(props) => (
					<Box {...props}>{DropdownIcon(14, 14)}</Box>
				)}
			>
				<Box
					hidden={hideSearchBar}
					p={0.5}
					marginTop="-8px"
					sx={{
						position: "sticky",
						top: 0,
						zIndex: 1,
						backgroundColor: "#fff",
					}}
				>
					<MenuSearchBar
						value={searchValue}
						onChange={handleSearchValue}
					/>
				</Box>

				{getFilteredOptionsList()?.length > 0
					? getFilteredOptionsList()?.map((option, index) => (
							<MenuItem
								value={option.value || ""}
								key={index}
								name={option?.name || ""}
							>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
									justifyContent="space-between"
									width="100%"
								>
									<Typography fontSize={14}>
										{option.name}
									</Typography>

									{selectedOption.value === option?.value ? (
										<Box
											display="flex"
											onClick={handleRemove}
										>
											{CloseIcon(20, 20, "#000", 0.6)}
										</Box>
									) : null}
								</Stack>
							</MenuItem>
						))
					: renderEmptyView()}
			</Select>
		</React.Fragment>
	);
}
