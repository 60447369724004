import React, { useEffect } from "react";
import { Box, Button, Divider, Stack } from "@mui/material";
import NameField from "../FieldType/Name";
import MetricsYAxis from "../FieldType/MetricsYAxis";
import ViewByXAxis from "../FieldType/ViewByXAxis";
import { useForm } from "react-hook-form";
import { isEmptyObject } from "../../../../../../utils/common";

const chartPropertiesDefaultValue = {
	viewName: "",
	viewProperties: {
		metrics: [
			{
				measureId: "",
				moduleData: "",
				moduleId: "",
				fieldId: "",
				label: "",
			},
		],
		viewBy: {
			fieldId: "",
			label: "",
		},
	},
};

export default function ChartProperties(props) {
	const { chartConfiguration, onSubmit } = props;
	const { control, handleSubmit, setValue, reset, unregister } = useForm({
		defaultValues: { ...chartPropertiesDefaultValue },
	});

	useEffect(() => {
		const defaultValues = { ...chartPropertiesDefaultValue };
		if (chartConfiguration.viewName) {
			defaultValues["viewName"] = chartConfiguration.viewName;
		}
		if (!isEmptyObject(chartConfiguration.viewProperties)) {
			defaultValues["viewProperties"] = JSON.parse(
				JSON.stringify(chartConfiguration.viewProperties)
			); // Deep clone chartConfiguration.viewProperties
		}
		reset(defaultValues);
	}, [chartConfiguration, reset, setValue]);

	return (
		<React.Fragment>
			<form
				onSubmit={handleSubmit(onSubmit)}
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					flex={1}
					style={{
						overflowY: "auto",
					}}
				>
					<Box p={1}>
						<NameField control={control} />
					</Box>

					<Box pt={1}>
						<Divider />
					</Box>

					<Box p={1} pt={2}>
						<MetricsYAxis
							index={0}
							control={control}
							setValue={setValue}
						/>
					</Box>

					<Box>
						<Divider />
					</Box>

					<Box p={1} pt={2}>
						<ViewByXAxis
							control={control}
							setValue={setValue}
							unregister={unregister}
						/>
					</Box>
				</Box>

				<Box>
					<Divider />
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						p={2}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => reset()}
							disableElevation
						>
							Clear All
						</Button>

						<Button
							type="submit"
							variant="contained"
							disableElevation
						>
							Apply
						</Button>
					</Stack>
				</Box>
			</form>
		</React.Fragment>
	);
}
