import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import MultiEmailBaseComponent from "./MultiEmailBaseComponent";

export default function MultiEmailComponent({ field, subField, inputRef }) {
	const {
		control,
		formState: { errors },
		clearErrors,
		register,
		watch,
		setValue,
	} = useFormContext();
	const {
		append: addEmail,
		remove: handleRemoveEmail,
		fields: emailFields,
	} = useFieldArray({
		name: field.id + "",
		control,
	});

	return (
		<React.Fragment>
			<MultiEmailBaseComponent
				field={field}
				errors={errors}
				register={register}
				inputRef={inputRef}
				addEmail={addEmail}
				subField={subField}
				watch={watch}
				setValue={setValue}
				emailFields={emailFields}
				clearErrors={clearErrors}
				handleRemoveEmail={handleRemoveEmail}
			/>
		</React.Fragment>
	);
}
