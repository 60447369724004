import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Grid,
	IconButton,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { FormFieldName } from "../../../../../styles/twozo";
import {
	useAddUserMutation,
	useUpdateUserMutation,
} from "../../../../../hooks/services/userManagement/user";
import { removeFieldsWithEmptyValues } from "../../../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { Controller, useForm } from "react-hook-form";
import { emailPattern } from "../../../../../utils/validation";
import { usePipelineList } from "../../../../../hooks/services/deal/pipeline";
import TeamsDropdown from "./TeamsDropdown";
import { useRolesList } from "../../../../../hooks/services/userManagement/roles";
import MenuSearchBar from "../../../../Elements/MenuSearchBar";
import UsersMenu from "../../../../Elements/UsersMenu";

const userMandatoryFields = ["firstName", "email", "roleId"];

export default function AddForm(props) {
	const { isEditMode, onClose, isUserDataLoading, userData, isEditable } =
		props;
	const theme = useTheme();
	const addUserMutation = useAddUserMutation();
	const updateUserMutation = useUpdateUserMutation();

	const {
		handleSubmit,
		register,
		watch,
		setValue,
		control,
		resetField,
		formState: { errors },
	} = useForm({
		values: userData,
	});

	const { data: pipelineList } = usePipelineList();
	const { data: rolesList } = useRolesList();

	const [pipelineSearchValue, setPipelineSearchValue] = useState("");
	const [roleSearchValue, setRoleSearchValue] = useState("");

	useEffect(() => {
		if (pipelineList) {
			setValue("pipelineId", pipelineList?.[0]?.id);
		}
	}, [pipelineList, setValue]);

	const saveUser = (userData, isFormShouldClose) => {
		if (userData?.reportingManagerId) {
			userData.reportingManagerId = userData.reportingManagerId?.value;
		}
		removeFieldsWithEmptyValues(userData);
		addUserMutation.mutate(userData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.userAdded,
				});
				isFormShouldClose && onClose();
			},
			onError: (error) => {
				const errorObj = JSON.parse(error.message);
				const errorMessage = errorObj.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleSaveAndNew = (formData) => {
		let isFormShouldClose = false;
		saveUser(formData, isFormShouldClose);
		userMandatoryFields.forEach((field) => resetField(field));
	};

	const handleSave = (formData) => {
		let isFormShouldClose = true;
		saveUser(formData, isFormShouldClose);
	};

	const updateUser = (userData) => {
		if (userData?.reportingManagerId) {
			userData.reportingManagerId = userData.reportingManagerId?.value;
		}
		removeFieldsWithEmptyValues(userData);
		updateUserMutation.mutate(userData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.userUpdated,
				});
				onClose();
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.errorMessage,
				});
			},
		});
	};

	const handleChangePipelines = (event) => {
		const { value } = event.target;
		setPipelineSearchValue(value);
	};

	const handleChangeRole = (event) => {
		const { value } = event.target;
		setRoleSearchValue(value);
	};

	const filteredPipelines = useMemo(() => {
		if (Array.isArray(pipelineList)) {
			if (pipelineSearchValue.trim()) {
				return pipelineList.filter((pipeline) =>
					pipeline?.name
						?.toLowerCase()
						?.startsWith(pipelineSearchValue.toLowerCase())
				);
			}
			return pipelineList;
		}
		return [];
	}, [pipelineList, pipelineSearchValue]);

	const filteredRoleList = useMemo(() => {
		if (Array.isArray(rolesList)) {
			if (roleSearchValue.trim()) {
				return rolesList.filter((team) =>
					team?.name
						?.toLowerCase()
						?.startsWith(roleSearchValue.toLowerCase())
				);
			}
			return rolesList;
		}
		return [];
	}, [rolesList, roleSearchValue]);

	const handleSelectReportingManager = (selectedUser) => {
		setValue("reportingManagerId", selectedUser);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			{isUserDataLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box p={2}>
						<Stack direction="row" justifyContent="space-between">
							<Typography fontWeight={600}>
								{isEditMode ? "Edit User" : "Add User"}
							</Typography>

							<IconButton
								size="small"
								style={{ padding: 0 }}
								onClick={onClose}
							>
								{CloseIcon(25, 25, theme.palette.primary.main)}
							</IconButton>
						</Stack>
					</Box>

					<Divider />

					{addUserMutation.isLoading ? (
						<Stack
							alignItems="center"
							justifyContent="center"
							height="100vh"
						>
							<CircularProgress size="30px" />
						</Stack>
					) : (
						<Box
							p={2}
							style={{
								flex: "1 1 auto",
								minHeight: 0,
								overflowY: "auto",
							}}
						>
							<Grid
								container
								direction="row"
								alignItems="baseline"
								justifyContent="center"
								spacing={2}
							>
								<Grid item xs={12} md={6}>
									<Stack>
										<Stack direction="row">
											<FormFieldName
												color={
													errors.firstName &&
													theme.palette.error.main
												}
											>
												First Name
											</FormFieldName>
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
												}}
											>
												*
											</span>
										</Stack>

										<TextField
											placeholder="First Name"
											{...register("firstName", {
												required: true,
											})}
											error={Boolean(errors.firstName)}
										/>
									</Stack>
									{errors.firstName && (
										<Typography
											fontSize={13}
											fontWeight={500}
											color={theme.palette.error.main}
											pl={1}
										>
											This field is required
										</Typography>
									)}
								</Grid>

								<Grid item xs={12} md={6}>
									<Stack spacing={0.5}>
										<FormFieldName>Last Name</FormFieldName>

										<TextField
											placeholder="Last Name"
											{...register("lastName")}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={0.5}>
										<FormFieldName>Job Title</FormFieldName>

										<TextField
											placeholder="eg: sales representative"
											{...register("jobTitle")}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack>
										<Stack direction="row">
											<FormFieldName
												color={
													errors.email &&
													theme.palette.error.main
												}
												style={{
													opacity: isEditMode
														? 0.6
														: 1,
												}}
											>
												Email
											</FormFieldName>
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
													opacity: isEditMode
														? 0.6
														: 1,
												}}
											>
												*
											</span>
										</Stack>

										<TextField
											placeholder="eg: abc@gmail.com"
											sx={{
												"& .MuiOutlinedInput-notchedOutline":
													{
														opacity: isEditMode
															? 0.6
															: 1,
													},
											}}
											{...register("email", {
												required:
													"This field is required",
												pattern: {
													value: emailPattern,
													message:
														"Please enter a valid email id",
												},
											})}
											error={Boolean(errors.email)}
											disabled={isEditMode}
										/>
									</Stack>
									{errors.email && (
										<Typography
											fontSize={13}
											fontWeight={500}
											color={theme.palette.error.main}
											pl={1}
										>
											{errors.email.message}
										</Typography>
									)}
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={0.5}>
										<FormFieldName>Mobile</FormFieldName>

										<TextField
											placeholder="98765 43210"
											{...register("mobile")}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={0.5}>
										<FormFieldName
											style={{
												opacity: !isEditable ? 0.6 : 1,
											}}
										>
											Reporting Manager
										</FormFieldName>

										<UsersMenu
											{...register("reportingManagerId")}
											value={watch("reportingManagerId")}
											onChange={
												handleSelectReportingManager
											}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack>
										<Stack direction="row">
											<FormFieldName>
												Pipeline
											</FormFieldName>
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
												}}
											>
												*
											</span>
										</Stack>

										<Select
											displayEmpty
											value={watch("pipelineId") || ""}
											{...register("pipelineId", {
												onChange: (event) =>
													setValue(
														"pipelineId",
														event.target.value
													),
											})}
											onClose={() =>
												setPipelineSearchValue("")
											}
											renderValue={(selectedPipeline) => {
												const selectedOption =
													pipelineList?.find(
														(pipeline) =>
															pipeline.id ===
															selectedPipeline
													);

												return selectedOption?.name;
											}}
										>
											<Box p={0.5}>
												<MenuSearchBar
													value={pipelineSearchValue}
													onChange={
														handleChangePipelines
													}
												/>
											</Box>

											{filteredPipelines?.length > 0
												? filteredPipelines.map(
														(pipeline) => (
															<MenuItem
																key={
																	pipeline.id
																}
																value={
																	pipeline.id
																}
																style={{
																	height: "40px",
																}}
															>
																<Typography
																	fontSize={
																		14
																	}
																>
																	{
																		pipeline.name
																	}
																</Typography>
															</MenuItem>
														)
													)
												: renderEmptyView()}
										</Select>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack spacing={0.5}>
										<FormFieldName>Teams</FormFieldName>

										<Controller
											name="teamIds"
											control={control}
											render={({
												field: { value, onChange },
											}) => (
												<TeamsDropdown
													value={value}
													onChange={onChange}
												/>
											)}
										/>
									</Stack>
								</Grid>

								<Grid item xs={12}>
									<Stack>
										<Stack direction="row">
											<FormFieldName
												color={
													errors.roleId &&
													theme.palette.error.main
												}
												style={{
													opacity: !isEditable
														? 0.6
														: 1,
												}}
											>
												Role
											</FormFieldName>
											<span
												style={{
													color: theme.palette.error
														.main,
													marginLeft: "5px",
												}}
											>
												*
											</span>
										</Stack>

										<Select
											displayEmpty
											value={watch("roleId") || ""}
											{...register("roleId", {
												required:
													"This field is required",
												onChange: (event) =>
													setValue(
														"roleId",
														event.target.value
													),
											})}
											onClose={() =>
												setRoleSearchValue("")
											}
											error={Boolean(errors.roleId)}
											disabled={!isEditable}
											renderValue={(selectedRole) => {
												const selectedOption =
													rolesList?.find(
														(role) =>
															role.value ===
															selectedRole
													);

												return selectedOption?.name;
											}}
										>
											<Box p={0.5}>
												<MenuSearchBar
													value={roleSearchValue}
													onChange={handleChangeRole}
												/>
											</Box>

											{filteredRoleList?.length > 0
												? filteredRoleList?.map(
														(role) => (
															<MenuItem
																key={role.value}
																value={
																	role.value
																}
																style={{
																	height: "40px",
																}}
															>
																<Typography
																	fontSize={
																		14
																	}
																>
																	{role.name}
																</Typography>
															</MenuItem>
														)
													)
												: renderEmptyView()}
										</Select>
										{errors.roleId && (
											<Typography
												fontSize={13}
												fontWeight={500}
												color={theme.palette.error.main}
												pl={1}
											>
												{errors.roleId.message}
											</Typography>
										)}
									</Stack>
								</Grid>
							</Grid>
						</Box>
					)}

					<Divider />

					<Box p={2}>
						{isEditMode ? (
							<Button
								variant="contained"
								color="primary"
								sx={{
									"&.Mui-disabled": {
										backgroundColor:
											theme.palette.primary.main,
										color: "rgba(255,255,255, 0.6)",
									},
								}}
								disableElevation
								disabled={updateUserMutation.isLoading}
								onClick={handleSubmit(updateUser)}
							>
								Update
							</Button>
						) : (
							<Stack direction="row" spacing={2}>
								<Box>
									<Button
										variant="contained"
										color="primary"
										sx={{
											"&.Mui-disabled": {
												backgroundColor:
													theme.palette.primary.main,
												color: "rgba(255,255,255, 0.6)",
											},
										}}
										disabled={addUserMutation.isLoading}
										disableElevation
										onClick={handleSubmit(handleSave)}
									>
										Save
									</Button>
								</Box>

								<Box>
									<Button
										variant="contained"
										color="primary"
										sx={{
											"&.Mui-disabled": {
												backgroundColor:
													theme.palette.primary.main,
												color: "rgba(255,255,255, 0.6)",
											},
										}}
										disabled={addUserMutation.isLoading}
										disableElevation
										onClick={handleSubmit(handleSaveAndNew)}
									>
										Save & New
									</Button>
								</Box>
							</Stack>
						)}
					</Box>
				</Box>
			)}
		</React.Fragment>
	);
}
