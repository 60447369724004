import {
	useInfiniteQuery,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import {
	getContactDataKey,
	getCheckForDuplicatesKey,
	getContactTableKey,
	getContactSummaryKey,
	getContactSummaryViewKey,
	getContactListWithEmailsKey,
	getLifecycleStageProgressKey,
	getContactLifecycleStageListKey,
	contactLostReasonListKey,
} from "../../../utils/queryKeys";
import {
	getDuplicateContactList,
	mergeDuplicateContact,
} from "../../../api/contacts/checkForDuplicates/checkForDuplicatesApi";
import {
	createContact,
	getContactData,
	assignOwnerToContacts,
	getContactSummaryData,
	deleteContact,
	updateContactSummary,
	getContactListWithEmails,
	partialUpdateContact,
	updateContact,
	getLifecycleStageProgress,
	getContactLifeCycleStageList,
	updateContactLifecycleStatus,
	contactLostReasonList,
} from "../../../api/contacts/contactsApi";
import { getContactListKey } from "../../../utils/queryKeys";
import { getContactList } from "../../../api/contacts/contactsApi";
import { useInvalidateContactTimeline } from "./summary/timeline";
import { modules } from "../../../utils/common/ModulesName";
import { useInvalidateGroupFieldData } from "../common/field";
import {
	contactsDropdownPageSize,
	duplicatesListPageSize,
} from "../../../utils/queryConstants/contact";

//contact details
const useContactData = (id) => {
	return useQuery(getContactDataKey(id), () => getContactData(id));
};

const contactSelect = (contacts) => {
	return contacts.pages.flatMap((page) => page?.options);
};

const useContactList = (isContactListEnabled, searchValue) => {
	return useInfiniteQuery({
		queryKey: getContactListKey(searchValue),
		queryFn: ({ pageParam }) => {
			return getContactList(searchValue, {
				start: pageParam
					? (pageParam - 1) * contactsDropdownPageSize + 1
					: 1,
				limit: contactsDropdownPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
		select: contactSelect,
		enabled: Boolean(isContactListEnabled),
	});
};

const useContactListWithEmails = (isContactListEnabled) => {
	return useQuery(
		getContactListWithEmailsKey(),
		() => getContactListWithEmails(),
		{
			select: (data) => data?.options,
			enabled: Boolean(isContactListEnabled),
		}
	);
};

const useContactDuplicateList = (contactDetails) => {
	return useInfiniteQuery({
		queryKey: getCheckForDuplicatesKey(contactDetails),
		queryFn: ({ pageParam }) => {
			return getDuplicateContactList(contactDetails, {
				start: pageParam
					? (pageParam - 1) * duplicatesListPageSize + 1
					: 1,
				limit: duplicatesListPageSize,
			});
		},
		getNextPageParam: (lastPage, allPages) => {
			return lastPage && lastPage.hasMore
				? allPages.length + 1
				: undefined;
		},
	});
};

const useLifecycleStageProgress = (contactId) => {
	const lifecycleStageProgressKey = getLifecycleStageProgressKey(contactId);
	return useQuery(
		lifecycleStageProgressKey,
		() => getLifecycleStageProgress(contactId),
		{
			select: (data) => data?.status,
		}
	);
};

const useContactLifecycleStageList = (contactId) => {
	const contactLifecycleStageListKey =
		getContactLifecycleStageListKey(contactId);
	return useQuery(
		contactLifecycleStageListKey,
		() => getContactLifeCycleStageList(contactId),
		{
			select: (data) => data?.options,
		}
	);
};

const useContactLostReasonList = () => {
	return useQuery(contactLostReasonListKey, () => contactLostReasonList(), {
		select: (data) => data?.options,
	});
};

const useUpdateContactLifecycleStatus = (contactId) => {
	let invalidateLifecycleStageProgress =
		useInvalidateLifecycleStageProgress(contactId);
	let invalidateContactLifecycleStage =
		useInvalidateContactLifecycleStage(contactId);
	return useMutation(updateContactLifecycleStatus, {
		onSuccess: () => {
			invalidateContactLifecycleStage();
			invalidateLifecycleStageProgress();
		},
	});
};

const useContactSummaryData = (contactId, hideEmptyFields) => {
	let contactSummaryKey = getContactSummaryViewKey(
		contactId,
		hideEmptyFields
	);
	return useQuery(contactSummaryKey, () =>
		getContactSummaryData(contactId, hideEmptyFields)
	);
};

const useInvalidateContactData = (contactId) => {
	let queryClient = useQueryClient();
	const contactDataKey = getContactDataKey(contactId);
	return () => {
		queryClient.invalidateQueries(contactDataKey);
	};
};

const useInvalidateContactSummaryData = (contactId) => {
	let queryClient = useQueryClient();
	const contactSummaryKey = getContactSummaryKey(contactId);
	return () => {
		queryClient.invalidateQueries(contactSummaryKey);
	};
};

const useEditContact = (contactId) => {
	let invalidateSummaryData = useInvalidateContactSummaryData(contactId);
	let invalidateContactTimeline = useInvalidateContactTimeline(contactId);
	let invalidateContactData = useInvalidateContactData(contactId);
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(updateContactSummary, {
		onSuccess: () => {
			invalidateContactTableData();
			invalidateSummaryData();
			invalidateContactData();
			invalidateContactTimeline();
		},
	});
};

//Invalidate Duplicate List
const useInvalidateDuplicateList = (contactDetails) => {
	let queryClient = useQueryClient();
	const duplicateListKey = getCheckForDuplicatesKey(contactDetails);
	return () => {
		queryClient.invalidateQueries(duplicateListKey);
	};
};

//Check for duplicates mutation
const useMergeDuplicateContactMutation = (contactDetails) => {
	let invalidateContactTableData = useInvalidateContactTableData();
	let invalidateDuplicateList = useInvalidateDuplicateList(contactDetails);
	return useMutation(mergeDuplicateContact, {
		onSuccess: () => {
			invalidateContactTableData();
			invalidateDuplicateList();
		},
	});
};

//Invalidate contact Table
const useInvalidateContactTableData = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries({ queryKey: getContactTableKey() });
	};
};

//contact Create or Clone
const useCreateMutation = () => {
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(createContact, {
		onSuccess: () => {
			invalidateContactTableData();
		},
	});
};

// partial update
const usePartialUpdateContact = (contactId) => {
	let invalidateSummaryData = useInvalidateContactSummaryData(contactId);
	let invalidateContactTimeline = useInvalidateContactTimeline(contactId);
	let invalidateGroupFieldData = useInvalidateGroupFieldData(
		modules.CONTACT,
		contactId
	);
	return useMutation(partialUpdateContact, {
		onSuccess: () => {
			invalidateSummaryData();
			invalidateGroupFieldData();
			invalidateContactTimeline();
		},
	});
};

// partial bulk update
const usePartialBulkUpdateContact = () => {
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(updateContact, {
		onSuccess: () => {
			invalidateContactTableData();
		},
	});
};

//Assign owner mutation
const useAssignOwnerToContacts = () => {
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(assignOwnerToContacts, {
		onSuccess: () => {
			invalidateContactTableData();
		},
	});
};

const useDeleteContact = () => {
	let invalidateContactTableData = useInvalidateContactTableData();
	return useMutation(deleteContact, {
		onSuccess: () => {
			invalidateContactTableData();
		},
	});
};

const useInvalidateLifecycleStageProgress = (contactId) => {
	let queryClient = useQueryClient();
	const lifecycleStageProgressKey = getLifecycleStageProgressKey(contactId);

	return () => {
		queryClient.invalidateQueries(lifecycleStageProgressKey);
	};
};

const useInvalidateContactLifecycleStage = (contactId) => {
	let queryClient = useQueryClient();
	const contactLifecycleStageListKey =
		getContactLifecycleStageListKey(contactId);

	return () => {
		queryClient.invalidateQueries(contactLifecycleStageListKey);
	};
};

export {
	useContactLostReasonList,
	useContactDuplicateList,
	useMergeDuplicateContactMutation,
	useContactData,
	useCreateMutation,
	useAssignOwnerToContacts,
	useContactList,
	useDeleteContact,
	useInvalidateContactTableData,
	useContactSummaryData,
	useEditContact,
	useContactListWithEmails,
	usePartialUpdateContact,
	usePartialBulkUpdateContact,
	useLifecycleStageProgress,
	useContactLifecycleStageList,
	useUpdateContactLifecycleStatus,
};
