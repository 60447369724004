import React, { useEffect, useState } from "react";
import {
	Box,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { default as CloseIcon } from "../../../../../../../assets/icons/close";
import { default as AddIcon } from "../../../../../../../assets/icons/add";
import { TextButton, Tooltip } from "../../../../../../../styles/twozo";
import CurrencyMenu from "../../../../../../Elements/CurrencyMenu";
import { useCurrencies } from "../../../../../../../hooks/settings/currency";
import { removeFieldsWithEmptyValues } from "../../../../../../../utils/common";

export default function PriceTagsBaseComponent({
	onChange,
	value,
	subField,
	error,
}) {
	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;

		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const theme = useTheme();
	const currencyField = getSubFieldObject("CURRENCY");
	const unitPriceField = getSubFieldObject("UNIT_PRICE");
	const taxField = getSubFieldObject("TAX");
	const discountField = getSubFieldObject("DISCOUNT");
	const initialPriceData = [
		{
			[currencyField.id]: "",
			[unitPriceField.id]: "",
			[taxField.id]: "",
			[discountField.id]: "",
		},
	];
	const [priceData, setPriceData] = useState(initialPriceData);
	const [selectedCurrencies, setSelectedCurrencies] = useState([]);

	const [selectedSubFieldId, setSelectedSubFieldId] = useState(null);

	const { activeCurrencyList } = useCurrencies();

	const styles = {
		tableContainer: {
			backgroundColor: theme.palette.primary.main + "20",
			borderRadius: "8px",
			p: 1,
		},
		tableHead: {
			fontSize: 13,
			fontWeight: 500,
			color: "#000",
			opacity: "0.6",
		},
		tableCell: {
			fontSize: 14,
		},
		editableTableCell: {
			borderBottom: `2px solid ${theme.palette.primary.main}`,
		},
	};

	const isPriceAdditionEnabled =
		!!priceData?.[priceData?.length - 1]?.[currencyField?.id];
	const allCurrencySelected =
		activeCurrencyList?.length === priceData?.length &&
		isPriceAdditionEnabled;

	// This useEffect will run only initial render
	useEffect(() => {
		// Check if the server value needs to be formatted
		const isNotInServerFormat = () => {
			return (
				Array.isArray(value) &&
				value.some((priceData) => priceData?.[currencyField?.id]?.value)
			);
		};

		if (isNotInServerFormat()) {
			const modifiedPriceData = value.map((priceData) => {
				return {
					...priceData,
					[currencyField.id]: priceData[currencyField.id].value,
				};
			});

			onChange(modifiedPriceData);
			setPriceData(modifiedPriceData);
			setSelectedCurrencies(
				modifiedPriceData.map(
					(priceData) => priceData[currencyField.id]
				)
			);
		}
	}, [currencyField?.id, onChange, value]);

	const addPrice = () => {
		if (!isPriceAdditionEnabled || allCurrencySelected) {
			return;
		}
		setSelectedSubFieldId(`currency_${priceData.length}`);
		setPriceData([...priceData, getNewDefaultSinglePriceUnit()]);
	};

	const getNewDefaultSinglePriceUnit = () => {
		return {
			[currencyField.id]: "",
			[unitPriceField.id]: "",
			[taxField.id]: "",
			[discountField.id]: "",
		};
	};

	const handleTableCell = (event) => {
		setSelectedSubFieldId(event.currentTarget.id);
	};

	const handleInputChange = (event, index, subFieldId) => {
		const { value } = event.target;
		let updatedPriceData = priceData.map((price, priceIndex) => {
			if (priceIndex === index) {
				return { ...price, [subFieldId]: value };
			} else {
				return price;
			}
		});
		removeFieldsWithEmptyValues(updatedPriceData[index]);
		setPriceData(updatedPriceData);
		onChange(updatedPriceData);
	};

	const handleDeletePrice = (selectedIndex) => {
		if (priceData.length === 1) {
			setPriceData(initialPriceData);
			setSelectedCurrencies([]);
		} else {
			const updatedPriceData = priceData.filter(
				(_, index) => index !== selectedIndex
			);

			setPriceData(updatedPriceData);
			setSelectedCurrencies((selectedCurrencies) => {
				return selectedCurrencies.filter(
					(_, index) => index !== selectedIndex
				);
			});
			onChange(updatedPriceData);
		}
	};

	const getCurrencyOptionsForPriceTag = (priceData) => {
		return activeCurrencyList?.filter(
			(currency) =>
				currency.id === priceData[currencyField.id] ||
				!selectedCurrencies.includes(currency.id)
		);
	};

	const onCurrencyInputChange = (currency, index) => {
		let oldCurrencyId = priceData[index][currencyField.id];
		let newCurrencyId = currency.id;

		//remove previous selected currency
		setSelectedCurrencies((selectedCurrencies) =>
			selectedCurrencies.filter((currency) => currency !== oldCurrencyId)
		);
		//add newly selected currency
		selectedCurrencies[index] = newCurrencyId;
		setSelectedCurrencies([...selectedCurrencies]);

		priceData[index][currencyField.id] = newCurrencyId;
		setPriceData([...priceData]);
		onChange(priceData);
	};

	const showCloseIcon = () => {
		if (priceData.length === 1) {
			const initialIndex = 0;
			return !Object.values(priceData[initialIndex]).every(
				(value) => value === ""
			);
		}
		return true;
	};

	return (
		<React.Fragment>
			<Grid item xs={12}>
				<Box sx={styles.tableContainer}>
					<TableContainer>
						<Table sx={{ width: "100%" }} size="small">
							<TableHead>
								<TableRow>
									<TableCell style={{ width: "25%" }}>
										<Typography sx={styles.tableHead}>
											Currency
										</Typography>
									</TableCell>

									<TableCell
										align="right"
										style={{ width: "25%" }}
									>
										<Typography sx={styles.tableHead}>
											Unit Price
										</Typography>
									</TableCell>

									<TableCell
										align="right"
										style={{ width: "22%" }}
									>
										<Typography sx={styles.tableHead}>
											Tax %
										</Typography>
									</TableCell>

									<TableCell
										align="right"
										style={{ width: "22%" }}
									>
										<Typography sx={styles.tableHead}>
											Discount
										</Typography>
									</TableCell>

									<TableCell sx={{ width: "6%" }}></TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{priceData.map((data, index) => (
									<TableRow key={index}>
										<TableCell
											id={`currency_${index}`}
											onClick={(event) =>
												handleTableCell(event)
											}
											sx={{
												borderBottom:
													selectedSubFieldId ===
														`currency_${index}` &&
													styles.editableTableCell,
												padding: "0px",
											}}
										>
											<CurrencyMenu
												selectedCurrency={
													data[currencyField.id]
												}
												currencyOptions={getCurrencyOptionsForPriceTag(
													data
												)}
												onCurrencyInputChange={
													onCurrencyInputChange
												}
												index={index}
												error={error}
											/>
										</TableCell>

										<TableCell
											align="right"
											id={`unitPrice_${index}`}
											onClick={(event) =>
												handleTableCell(event)
											}
											sx={{
												py: "0px",
												borderBottom:
													selectedSubFieldId ===
														`unitPrice_${index}` &&
													styles.editableTableCell,
											}}
										>
											<TextField
												variant="standard"
												name="UNIT_PRICE"
												value={data[unitPriceField.id]}
												InputProps={{
													disableUnderline: true,
												}}
												onChange={(event) =>
													handleInputChange(
														event,
														index,
														unitPriceField.id
													)
												}
												placeholder="0"
												inputProps={{
													style: {
														textAlign: "right",
														fontSize: "14px",
													},
												}}
												error={!!error}
											/>
										</TableCell>

										<TableCell
											align="right"
											id={`taxPercentage_${index}`}
											onClick={(event) =>
												handleTableCell(event)
											}
											sx={{
												py: "0px",
												borderBottom:
													selectedSubFieldId ===
														`taxPercentage_${index}` &&
													styles.editableTableCell,
											}}
										>
											<TextField
												variant="standard"
												name="TAX"
												value={data[taxField.id]}
												InputProps={{
													disableUnderline: true,
												}}
												onChange={(event) =>
													handleInputChange(
														event,
														index,
														taxField.id
													)
												}
												placeholder="0%"
												inputProps={{
													style: {
														textAlign: "right",
														fontSize: "14px",
													},
												}}
											/>
										</TableCell>

										<TableCell
											align="right"
											id={`discount_${index}`}
											onClick={(event) =>
												handleTableCell(event)
											}
											sx={{
												py: "0px",
												borderBottom:
													selectedSubFieldId ===
														`discount_${index}` &&
													styles.editableTableCell,
											}}
										>
											<TextField
												variant="standard"
												name="DISCOUNT"
												value={data[discountField.id]}
												InputProps={{
													disableUnderline: true,
												}}
												onChange={(event) =>
													handleInputChange(
														event,
														index,
														discountField.id
													)
												}
												placeholder="0%"
												inputProps={{
													style: {
														textAlign: "right",
														fontSize: "14px",
													},
												}}
											/>
										</TableCell>

										<TableCell sx={styles.tableHead}>
											{showCloseIcon() && (
												<Box
													display="flex"
													onClick={() =>
														handleDeletePrice(index)
													}
												>
													{CloseIcon("20", "20")}
												</Box>
											)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>

					<Box pl={2}>
						<Tooltip
							placement="top"
							title={
								allCurrencySelected
									? "All Currencies already have a price"
									: ""
							}
						>
							<TextButton
								size="small"
								style={{
									cursor:
										isPriceAdditionEnabled &&
										!allCurrencySelected
											? "pointer"
											: "not-allowed",
									opacity:
										isPriceAdditionEnabled &&
										!allCurrencySelected
											? 1
											: 0.6,
								}}
								startIcon={AddIcon(
									13,
									13,
									theme.palette.secondary.contrastText
								)}
								onClick={addPrice}
							>
								Add Price
							</TextButton>
						</Tooltip>
					</Box>
				</Box>
			</Grid>
		</React.Fragment>
	);
}
