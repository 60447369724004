import React from "react";
import { Typography } from "@mui/material";
import {
	getDateMonthAndYearFormat,
	getHourAndMinuteFormat,
} from "../../../../../../utils/DateUtils";

export default function RenderDateTimeRangeFieldValue(props) {
	const { fieldValue } = props;

	if (!fieldValue) return;

	return (
		<React.Fragment>
			<Typography fontSize={14}>
				<span>
					{getDateMonthAndYearFormat(fieldValue?.FROM)}{" "}
					{getHourAndMinuteFormat(fieldValue?.FROM)}{" "}
				</span>
				<span>-</span>
				<span>
					{" "}
					{getDateMonthAndYearFormat(fieldValue?.TO)}{" "}
					{getHourAndMinuteFormat(fieldValue?.TO)}
				</span>
			</Typography>
		</React.Fragment>
	);
}
