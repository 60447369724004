import {
	Box,
	Button,
	Divider,
	Grid,
	IconButton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";
import { FormFieldName, TextButton } from "../../../../styles/twozo";
import { useForm, useFieldArray } from "react-hook-form";
import { default as CloseIcon } from "../../../../assets/icons/close";
import { default as AddIcon } from "../../../../assets/icons/add";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import {
	maxStatusLimit,
	validationConfig,
	DEAL_STATUS,
} from "../LifecycleStageConfig";
import StageStatusList from "./StageStatusList";

export default function LifecycleStageForm(props) {
	const { value, onClose, onSave, isAddStatusEnabled, isEditMode, isSaving } =
		props;
	const isAddStatusRef = useRef(isAddStatusEnabled || false);

	const theme = useTheme();
	const styles = {
		tableContainer: {
			backgroundColor: theme.palette.primary.main + "20",
			borderRadius: "8px",
			p: 1,
		},
		tableHead: {
			fontSize: 13,
			fontWeight: 500,
			color: "#000",
			opacity: "0.6",
		},
		Box: {
			fontSize: 14,
		},
	};

	const {
		control,
		handleSubmit,
		register,
		formState: { errors },
		clearErrors,
		setValue,
	} = useForm({
		defaultValues: {
			name: value?.name || "",
			statuses: value?.statuses || [
				{
					isDeletable: true,
					name: "",
					isMovable: true,
					isSystemStatus: false,
				},
			],
		},
		reValidateMode: false,
	});

	const {
		fields: statusFields,
		append: addStatus,
		move: moveStatus,
		remove: removeStatus,
		insert: insertStatus,
	} = useFieldArray({
		control,
		name: "statuses",
	});

	const isStatusLimitExceeded = statusFields?.length === maxStatusLimit;
	const lastStatus = statusFields?.[statusFields?.length - 1];

	const onSubmit = (data) => {
		onSave({ ...data, id: value?.id });
	};

	const onDragEnd = ({ source, destination }) => {
		if (
			destination &&
			source.index !== destination.index &&
			!statusFields[destination.index]?.label
		) {
			moveStatus(source.index, destination.index);
		}
	};

	const handleAddStatus = useCallback(() => {
		const newStatus = {
			isDeletable: true,
			name: "",
			isMovable: true,
			isSystemStatus: false,
		};
		const lastIndex = statusFields.length - 1;
		const secondLastIndex = statusFields.length - 2;

		const hasWonStatus = statusFields.some(
			(status) => status?.label === DEAL_STATUS.WON
		);
		const hasLostStatus = statusFields.some(
			(status) => status?.label === DEAL_STATUS.LOST
		);

		if (hasWonStatus && hasLostStatus) {
			// Insert before the second last status
			insertStatus(secondLastIndex, newStatus);
		} else if (hasWonStatus || hasLostStatus) {
			// Insert before the last status
			insertStatus(lastIndex, newStatus);
		} else {
			// Insert at the last position
			addStatus(newStatus);
		}
	}, [addStatus, insertStatus, statusFields]);

	const onAddStatusClick = () => {
		if (isStatusLimitExceeded) {
			return;
		}

		handleAddStatus();
	};

	const handleAddLostStatus = () => {
		if (isStatusLimitExceeded) {
			return;
		}

		const newLostStatus = {
			isDeletable: true,
			name: "",
			isMovable: false,
			label: 1,
			isSystemStatus: true,
		};

		addStatus(newLostStatus);
	};

	useEffect(() => {
		if (isAddStatusRef.current) {
			handleAddStatus();
			isAddStatusRef.current = false;
		}
	}, [handleAddStatus]);

	return (
		<>
			<Box
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box p={2}>
					<Stack
						direction="row"
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
					>
						<Typography fontWeight={600}>
							{isEditMode
								? "Update Lifecycle Stage"
								: "Add Lifecycle Stage"}
						</Typography>

						<IconButton
							onClick={onClose}
							size="small"
							style={{ padding: 0 }}
						>
							{CloseIcon(25, 25, theme.palette.primary.main)}
						</IconButton>
					</Stack>
				</Box>

				<Divider />

				<Box
					p={2}
					style={{
						flex: "1 1 auto",
						minHeight: 0,
						overflowY: "auto",
					}}
				>
					<Grid
						container
						direction="row"
						justifyContent="flex-start"
						alignItems="center"
						spacing={2}
					>
						<Grid item xs={12}>
							<Stack spacing={0.5}>
								<FormFieldName
									color={
										errors["name"]?.message &&
										theme.palette.error.main
									}
								>
									Lifecycle Stage Name
									<span
										style={{
											color: theme.palette.error.main,
											marginLeft: "5px",
										}}
									>
										*
									</span>
								</FormFieldName>

								<TextField
									placeholder="Eg: Standard"
									name="pipelineName"
									{...register("name", {
										required: "This field is required",
										...validationConfig,
									})}
									onChange={() => {
										clearErrors("name");
									}}
									FormHelperTextProps={{
										sx: {
											marginLeft: 1,
											fontSize: 13,
										},
									}}
									helperText={
										errors["name"]?.message &&
										errors["name"]?.message
									}
									error={errors["name"]?.message}
								/>
							</Stack>
						</Grid>

						<Grid item xs={12}>
							<Box sx={styles.tableContainer}>
								<Stack
									height="30px"
									direction="row"
									alignItems="center"
									borderBottom="1px solid #0000001A"
								>
									<Box sx={{ width: "10%" }} />

									<Box align="left">
										<Typography
											sx={{
												width: "80%",
												...styles.tableHead,
											}}
										>
											Status
										</Typography>
									</Box>

									<Box sx={{ width: "10%" }} />
								</Stack>

								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable
										droppableId="statusList"
										direction="vertical"
									>
										{(provided) => (
											<div
												ref={provided.innerRef}
												{...provided.droppableProps}
												style={{ width: "100%" }}
											>
												<StageStatusList
													errors={errors}
													setValue={setValue}
													register={register}
													statusFields={statusFields}
													clearErrors={clearErrors}
													removeStatus={removeStatus}
												/>
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>

								<Stack
									width="100%"
									height="40px"
									direction="row"
									alignItems="center"
								>
									<Box sx={{ width: "10%" }} />

									<Box sx={{ width: "80%" }}>
										<Stack
											direction="row"
											alignItems="center"
											spacing={5}
										>
											<Box
												display="flex"
												style={{
													opacity:
														isStatusLimitExceeded
															? 0.6
															: 1,
												}}
											>
												<TextButton
													size="small"
													startIcon={AddIcon(
														13,
														13,
														theme.palette.secondary
															.contrastText
													)}
													onClick={onAddStatusClick}
												>
													Add status
												</TextButton>
											</Box>

											{!lastStatus?.label && (
												<TextButton
													size="small"
													startIcon={AddIcon(
														13,
														13,
														theme.palette.secondary
															.contrastText
													)}
													onClick={
														handleAddLostStatus
													}
												>
													Add Closed Lost Status
												</TextButton>
											)}
										</Stack>
									</Box>

									<Box sx={{ width: "10%" }} />
								</Stack>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Divider />

				<Box p={2}>
					<Button
						onClick={handleSubmit(onSubmit)}
						variant="contained"
						disableElevation
						disabled={isSaving}
					>
						{isEditMode ? "Update" : "Save"}
					</Button>
				</Box>
			</Box>
		</>
	);
}
