import { Typography, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
	useCallback,
	useEffect,
	useState,
	useImperativeHandle,
	forwardRef,
} from "react";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import CompanyAutoComplete from "../../CompanyAutoComplete";

const textFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
		"&:hover fieldset": {
			border: "none",
		},
		"&.Mui-focused fieldset": {
			border: "none",
		},
	},
};

const CompanyFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		const {
			formState: { errors },
			control,
			handleSubmit,
			clearErrors,
			setValue,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const [selectedCompany, setSelectedCompany] = useState("");

		useEffect(() => {
			if (value?.name) {
				setSelectedCompany(value);
				setValue(fieldId?.toString(), value?.id);
			}
		}, [fieldId, setValue, value]);

		const handleSelectCompany = (selectedCompany, onChange) => {
			setSelectedCompany(selectedCompany);
			onChange(selectedCompany?.value);
			clearErrors(fieldId + "");
			onError(null);
		};

		const removeCompanyValue = (onChange) => {
			onChange("");
			setSelectedCompany("");
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange } }) => (
						<CompanyAutoComplete
							value={selectedCompany}
							onChange={(value) =>
								handleSelectCompany(value, onChange)
							}
							onCancel={() => removeCompanyValue(onChange)}
							placeHolder={field?.placeHolder}
							textFieldSx={textFieldSx}
						/>
					)}
					defaultValue=""
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

CompanyFieldEditForm.displayName = "CompanyFieldEditForm";

export default CompanyFieldEditForm;
