import React from "react";
import { Box, Button, Grid, Stack, useTheme } from "@mui/material";
import { default as AddIcon } from "../../../../../assets/icons/add";
import { useDashboardBuilderContext } from "../Context";
import ChartWrapper from "../ChartWrapper";

export default function Preview() {
	const theme = useTheme();
	const { chartConfiguration } = useDashboardBuilderContext();

	return (
		<React.Fragment>
			<Box
				style={{
					backgroundColor: "#F4F5F5",
					borderRadius: "0px 0px 8px 8px",
					height: "100%",
					overflowY: "auto",
				}}
				p={3}
			>
				{chartConfiguration.length > 0 ? (
					<Grid
						container
						direction="row"
						sx={{
							justifyContent: "flex-start",
							alignItems: "flex-start",
						}}
						spacing={3}
					>
						{chartConfiguration.map((chartConfig, index) => (
							<Grid item key={chartConfig.viewId} xs={12} lg={6}>
								<ChartWrapper
									index={index}
									config={chartConfig}
								/>
							</Grid>
						))}
					</Grid>
				) : (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="100%"
					>
						<Button
							variant="contained"
							color="secondary"
							disableElevation
							startIcon={AddIcon(
								20,
								20,
								theme.palette.primary.main
							)}
						>
							Charts
						</Button>
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}
