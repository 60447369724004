export const maxStageLimit = 10;

export const maxStatusLimit = 10;

export const validationConfig = {
	minLength: {
		value: 3,
		message: "Min. of 3 characters are required",
	},
	maxLength: {
		value: 100,
		message: "Max. of 100 characters are allowed",
	},
};

export const DEAL_STAGE_TYPES = {
	DEAL_ADDED: 1,
	DEAL_WON: 2,
};

export const DEAL_STATUS = {
	WON: 2,
	LOST: 1,
};
