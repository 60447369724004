import {
	Box,
	Button,
	CircularProgress,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { TableCellText, TableHeaderLabel } from "../../../styles/twozo";
import ContactImage from "../../../assets/images/contact/unknownContact.png";
import { useTheme } from "@emotion/react";
import { useFollowersList } from "../../../hooks/services/summary/followers";
import { default as addIcon } from "../../../assets/icons/add";
import { getDateDifferenceWithHourAndMinute } from "../../../utils/DateUtils";
import CustomSwipeableDrawer from "../../Elements/CustomSwipeableDrawer";
import React, { useState } from "react";
import ManageFollowers from "./ManageFollowers";

export default function Followers(props) {
	const { sourceName, entityId } = props;

	const { data: followerList, isLoading: followersListLoading } =
		useFollowersList(sourceName, entityId);

	const theme = useTheme();

	const tableHeaders = [
		{ label: "User" },
		{ label: "Role" },
		{ label: "Permissions on this source" },
		{ label: "Updated At" },
	];

	const [isOpenManageFollowersDrawer, setIsOpenManageFollowersDrawer] =
		useState(false);

	const openManageFollowersDrawer = () => {
		setIsOpenManageFollowersDrawer(true);
	};

	const closeManageFollowersDrawer = () => {
		setIsOpenManageFollowersDrawer(false);
	};

	return (
		<React.Fragment>
			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isOpenManageFollowersDrawer}
				onOpen={openManageFollowersDrawer}
				onClose={closeManageFollowersDrawer}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box
					sx={{
						width: "50vw",
						minHeight: "100vh",
						backgroundColor: "#fff",
						borderRadius: "10px 0px 0px 10px",
					}}
				>
					<ManageFollowers
						onClose={closeManageFollowersDrawer}
						sourceName={sourceName}
						entityId={entityId}
					/>
				</Box>
			</CustomSwipeableDrawer>

			<Box>
				{followersListLoading ? (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="50vh"
					>
						<CircularProgress size="30px" />
					</Stack>
				) : followerList?.length >= 1 ? (
					<TableContainer>
						<Table size="small">
							<TableHead>
								<TableRow>
									{tableHeaders.map((header, index) => (
										<TableCell
											key={index}
											sx={{ width: "20%" }}
										>
											<Stack
												direction="row"
												alignItems="center"
												spacing={0.5}
											>
												<TableHeaderLabel>
													{header.label}
												</TableHeaderLabel>

												<Box display="flex">
													<Box pt={0.5}>
														{DropDownIcon(
															12,
															12,
															"rgba(0, 0, 0, 0.6)"
														)}
													</Box>
												</Box>
											</Stack>
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{followerList?.map((followers, index) => (
									<TableRow key={index}>
										<TableCell>
											{followers.user ? (
												<Stack
													direction="row"
													alignItems="center"
													spacing={1}
												>
													<img
														src={ContactImage}
														alt="managerProfile"
														width={26}
														height={26}
													/>
													<TableCellText
														noWrap
														maxWidth="225px"
														sx={{
															wordBreak:
																"break-all",
														}}
													>
														{followers.user.name}
													</TableCellText>
												</Stack>
											) : (
												<TableCellText>-</TableCellText>
											)}
										</TableCell>

										<TableCell>
											<TableCellText
												sx={{ wordBreak: "break-all" }}
												maxWidth="210px"
												fontWeight={400}
												noWrap
											>
												{followers.role.name}
											</TableCellText>
										</TableCell>

										<TableCell>
											<TableCellText
												style={{
													color: theme.palette.primary
														.main,
												}}
												fontWeight={500}
											>
												{
													followers.permissionsOnThisSource
												}
											</TableCellText>
										</TableCell>

										<TableCell>
											<TableCellText>
												{followers.addedAt
													? getDateDifferenceWithHourAndMinute(
															followers.addedAt
														)
													: "-"}
											</TableCellText>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<Stack
						alignItems="center"
						justifyContent="center"
						height="50vh"
						spacing={2}
					>
						<Typography
							fontWeight={400}
							fontSize={14}
							sx={{ opacity: 0.6 }}
						>
							No {sourceName} Followers found.
						</Typography>

						<Button
							variant="contained"
							startIcon={addIcon(
								16,
								16,
								theme.palette.secondary.contrastText
							)}
							color="secondary"
							disableElevation
							onClick={openManageFollowersDrawer}
						>
							Add Followers
						</Button>
					</Stack>
				)}
			</Box>
		</React.Fragment>
	);
}
