import React, { useEffect } from "react";
import {
	Box,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { AddFormTextButton } from "../../../../../../../styles/twozo";
import { default as AddIcon } from "../../../../../../../assets/icons/add";
import { getDropdownDataByName } from "../../../../../../../utils/dropdownData";
import CloseIcon from "../../../../../../../assets/icons/close";
import {
	handleFieldValidation,
	phoneNumberLimit,
} from "../../../../../../../utils/fieldValidation";

export default function MultiPhoneBaseComponent(props) {
	const {
		field,
		errors,
		subField,
		inputRef,
		register,
		watch,
		setValue,
		addPhone,
		phoneFields,
		clearErrors,
		handleRemovePhone,
	} = props;

	const theme = useTheme();

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const phoneTypeOptions = getDropdownDataByName("PHONE_TYPE");
	const phoneTypeField = getSubFieldObject("PHONE_TYPE");
	const phoneNumberField = getSubFieldObject("PHONE_NUMBER");

	const handleAddPhone = () => {
		if (isPhoneNumberLimitExceeded()) {
			return;
		}

		addPhone({
			[phoneNumberField.id]: "",
			[phoneTypeField.id]: phoneTypeOptions[0]?.value,
		});
	};

	const isPhoneNumberLimitExceeded = () => {
		return phoneFields.length >= phoneNumberLimit;
	};

	useEffect(() => {
		const watchFieldArray = watch(field.id + "");

		// Updates the watched phone fields for initial setup, using their IDs and values.
		const updatedWatchFieldArray = watchFieldArray?.map((field) => {
			if (field[phoneTypeField.id]?.name) {
				return {
					...field,
					[phoneTypeField.id]: field[phoneTypeField.id]?.value,
				};
			}
			return { ...field };
		});

		const controlledFields = phoneFields.map((field, index) => {
			return {
				...field,
				...updatedWatchFieldArray?.[index],
			};
		});

		if (controlledFields.length === 0) {
			addPhone({
				[phoneNumberField.id]: "",
				[phoneTypeField.id]: phoneTypeOptions[0]?.value,
			});
		}

		controlledFields.forEach((phoneField, index) => {
			if (phoneField && phoneField[phoneTypeField.id]) {
				setValue(
					`${field.id}.${index}.${phoneTypeField.id}`,
					phoneField[phoneTypeField.id]
				);
			}
		});
	}, [
		watch,
		addPhone,
		phoneFields,
		phoneTypeOptions,
		phoneNumberField,
		setValue,
		field.id,
		phoneTypeField,
	]);

	return (
		<React.Fragment>
			<Stack
				direction="row"
				justifyContent="end"
				style={{
					margin: 0,
				}}
			>
				{!isPhoneNumberLimitExceeded() && (
					<AddFormTextButton
						size="small"
						startIcon={AddIcon(
							18,
							18,
							theme.palette.secondary.contrastText
						)}
						onClick={handleAddPhone}
					>
						Add
					</AddFormTextButton>
				)}
			</Stack>

			{phoneFields?.map((phoneField, index) => (
				<Stack direction="row" alignItems="center" key={phoneField.id}>
					<Box width="100%" display="flex">
						<TextField
							{...register(
								`${field.id + ""}.${index}.${
									phoneNumberField.id
								}`,
								handleFieldValidation(field)
							)}
							placeholder={
								phoneNumberField.placeHolder || "9876543210"
							}
							type="number"
							onChange={() => {
								clearErrors(
									`${field.id + ""}.${index}.${
										phoneNumberField.id
									}`
								);
							}}
							InputProps={{
								endAdornment: null,
							}}
							id={field.id + ""}
							sx={{
								width: "80%",
								"& .MuiOutlinedInput-notchedOutline": {
									borderRadius: "8px 0px 0px 8px",
								},
								"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
									{
										"-webkit-appearance": "none",
										margin: 0,
									},
							}}
							FormHelperTextProps={{
								sx: {
									fontSize: 13,
									ml: "8px",
								},
							}}
							inputRef={inputRef}
							error={
								!!errors[field.id]?.[index]?.[
									phoneNumberField.id
								]
							}
							helperText={
								errors[field.id]?.[index]?.[phoneNumberField.id]
									?.message
							}
						/>
						<Select
							sx={{
								width: "20%",
								"& .MuiOutlinedInput-notchedOutline": {
									borderRadius: "0px 8px 8px 0px",
									marginLeft: "-1px",
									borderLeftWidth: 0,
								},
							}}
							defaultValue={
								phoneField[phoneTypeField.id]?.value ||
								phoneField[phoneTypeField.id] ||
								null
							}
							error={
								!!errors[field.id]?.[index]?.[
									phoneNumberField.id
								]
							}
							{...register(
								`${field.id + ""}.${index}.${phoneTypeField.id}`
							)}
						>
							{phoneTypeOptions.map((phoneType) => (
								<MenuItem
									key={phoneType.value}
									value={phoneType.value}
								>
									<Typography fontSize={13}>
										{phoneType.name}
									</Typography>
								</MenuItem>
							))}
						</Select>

						{phoneFields.length > 1 && (
							<Box
								display="flex"
								sx={{ opacity: 0.6 }}
								style={{
									border: `1px  solid ${
										errors[field.id]?.[index]?.[
											phoneNumberField.id
										]
											? theme.palette.error.main
											: "rgba(0, 0, 0, 0.2)"
									}`,
									borderRadius: "0px 5px 5px 0px",
									marginLeft: "-1px",
									display: "flex",
									marginTop: "9px",
									cursor: "pointer",
									height: "23px",
								}}
								onClick={() => handleRemovePhone(index)}
							>
								{CloseIcon(20, 20)}
							</Box>
						)}
					</Box>
				</Stack>
			))}
		</React.Fragment>
	);
}
