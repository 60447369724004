import React, { useEffect } from "react";
import {
	Box,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { AddFormTextButton } from "../../../../../../../styles/twozo";
import { default as AddIcon } from "../../../../../../../assets/icons/add";
import { getDropdownDataByName } from "../../../../../../../utils/dropdownData";
import CloseIcon from "../../../../../../../assets/icons/close";
import {
	emailLimit,
	handleFieldValidation,
} from "../../../../../../../utils/fieldValidation";

export default function MultiEmailBaseComponent(props) {
	const {
		field,
		errors,
		inputRef,
		addEmail,
		register,
		subField,
		watch,
		setValue,
		clearErrors,
		emailFields,
		handleRemoveEmail,
	} = props;
	const theme = useTheme();

	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const emailTypeOptions = getDropdownDataByName("EMAIL_TYPE");
	const emailTypeField = getSubFieldObject("EMAIL_TYPE");
	const emailValueField = getSubFieldObject("EMAIL_VALUE");

	useEffect(() => {
		const watchFieldArray = watch(field.id + "");

		// Updates the watched email fields for initial setup, using their IDs and values.
		const updatedWatchFieldArray = watchFieldArray?.map((field) => {
			if (field[emailTypeField.id]?.name) {
				return {
					...field,
					[emailTypeField.id]: field[emailTypeField.id]?.value,
				};
			}
			return { ...field };
		});

		const controlledFields = emailFields.map((field, index) => {
			return {
				...field,
				...updatedWatchFieldArray?.[index],
			};
		});

		if (controlledFields.length === 0) {
			addEmail({
				[emailValueField.id]: "",
				[emailTypeField.id]: emailTypeOptions[0].value,
			});
		}

		// Ensures each email field's initial values are set in the form.
		controlledFields.forEach((emailField, index) => {
			if (emailField && emailField[emailTypeField.id]) {
				setValue(
					`${field.id}.${index}.${emailTypeField.id}`,
					emailField[emailTypeField.id]
				);
			}
		});
	}, [
		emailFields,
		emailTypeField,
		emailValueField,
		emailTypeOptions,
		addEmail,
		setValue,
		field.id,
		watch,
	]);

	const handleAddEmail = () => {
		if (isEmailLimitExceeded()) {
			return;
		}

		addEmail({
			[emailValueField.id]: "",
			[emailTypeField.id]: 1,
		});
	};

	const isEmailLimitExceeded = () => {
		return emailFields.length >= emailLimit;
	};

	return (
		<React.Fragment>
			<Stack
				direction="row"
				justifyContent="end"
				style={{
					margin: 0,
				}}
			>
				{!isEmailLimitExceeded() && (
					<AddFormTextButton
						size="small"
						startIcon={AddIcon(
							18,
							18,
							theme.palette.secondary.contrastText
						)}
						onClick={handleAddEmail}
					>
						Add
					</AddFormTextButton>
				)}
			</Stack>

			{emailFields?.map((emailField, index) => (
				<Stack direction="row" alignItems="center" key={emailField.id}>
					<Box width="100%" display="flex">
						<TextField
							{...register(
								`${field.id + ""}.${index}.${
									emailValueField.id
								}`,
								handleFieldValidation(field)
							)}
							key={emailField.id}
							placeholder={
								emailValueField.placeHolder || "example@abc.com"
							}
							id={field.id + ""}
							sx={{
								width: "80%",
								"& .MuiOutlinedInput-notchedOutline": {
									borderRadius: "8px 0px 0px 8px",
								},
							}}
							onChange={() => {
								clearErrors(
									`${field.id + ""}.${index}.${
										emailValueField.id
									}`
								);
							}}
							FormHelperTextProps={{
								sx: {
									fontSize: 13,
									ml: "8px",
								},
							}}
							inputRef={inputRef}
							error={
								!!errors[field.id]?.[index]?.[
									emailValueField.id
								]
							}
							helperText={
								errors[field.id]?.[index]?.[emailValueField.id]
									?.message
							}
						/>

						<Select
							defaultValue={
								emailField[emailTypeField.id]?.value ||
								emailField[emailTypeField.id]
							}
							sx={{
								width: "20%",
								"& .MuiOutlinedInput-notchedOutline": {
									borderRadius: "0px 8px 8px 0px",
									marginLeft: "-1px",
									borderLeftWidth: 0,
								},
							}}
							error={
								!!errors[field.id]?.[index]?.[
									emailValueField.id
								]
							}
							{...register(
								`${field.id + ""}.${index}.${emailTypeField.id}`
							)}
						>
							{emailTypeOptions.map((emailType) => (
								<MenuItem
									key={emailType.value}
									value={emailType.value}
								>
									<Typography fontSize={13}>
										{emailType.name}
									</Typography>
								</MenuItem>
							))}
						</Select>

						{emailFields.length > 1 && (
							<Box
								display="flex"
								sx={{ opacity: 0.6 }}
								style={{
									border: `1px  solid ${
										errors[field.id]?.[index]?.[
											emailValueField.id
										]
											? theme.palette.error.main
											: "rgba(0, 0, 0, 0.2)"
									}`,
									borderRadius: "0px 5px 5px 0px",
									marginLeft: "-1px",
									display: "flex",
									marginTop: "9px",
									cursor: "pointer",
									height: "23px",
								}}
								onClick={() => handleRemoveEmail(index)}
							>
								{CloseIcon(20, 20)}
							</Box>
						)}
					</Box>
				</Stack>
			))}
		</React.Fragment>
	);
}
