import React from "react";
import Dialog from "../../Elements/Dialog";
import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import UsersMenu from "../UsersMenu";

export default function AssignOwner(props) {
	const {
		open,
		onClose,
		value,
		handleSelectUser,
		disableCancelButton,
		disableSaveButton,
		onSave,
	} = props;
	const theme = useTheme();

	const title = "Assign Owner";

	return (
		<React.Fragment>
			<Dialog open={open} title={title}>
				<Box p={2}>
					<Stack spacing={1}>
						<Typography fontSize={13}>Assign to</Typography>

						<UsersMenu value={value} onChange={handleSelectUser} />

						<Box p={1}>
							<Stack
								direction="row"
								justifyContent="flex-end"
								spacing={2}
							>
								<Button
									onClick={onClose}
									variant="contained"
									color="secondary"
									disableElevation
									disabled={disableCancelButton}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									disableElevation
									sx={{
										"&.Mui-disabled": {
											backgroundColor: `${theme.palette.primary.main}`,
											color: "rgba(255,255,255, 0.6)",
										},
									}}
									disabled={disableSaveButton}
									onClick={onSave}
								>
									Save
								</Button>
							</Stack>
						</Box>
					</Stack>
				</Box>
			</Dialog>
		</React.Fragment>
	);
}
