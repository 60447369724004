import React, { useCallback, useEffect, useState } from "react";
import {
	Box,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import MenuSearchBar from "../../../../../MenuSearchBar";
import { useCurrencies } from "../../../../../../../hooks/settings/currency";

export default function MonetaryBaseComponent({
	onChange,
	value,
	subField,
	id,
	inputRef,
	field,
	error,
	typeSx,
	valueSx,
	clearErrors,
}) {
	const getSubFieldObject = (subFieldName) => {
		let subFieldObj;
		if (!!subField && Array.isArray(subField)) {
			subField.forEach((field) => {
				if (field.name === subFieldName) {
					subFieldObj = field;
				}
			});
		}

		return subFieldObj;
	};

	const monetaryTypeField = getSubFieldObject("CURRENCY");
	const monetaryValueField = getSubFieldObject("VALUE");
	const [monetary, setMonetary] = useState({
		[monetaryValueField.id]: "",
		[monetaryTypeField.id]: {
			name: "",
			value: "",
		},
	});
	const [searchValue, setSearchValue] = useState("");

	const { activeCurrencyList, getCurrencyDataById, baseCurrency } =
		useCurrencies(searchValue);

	const handleSearchValue = (event) => {
		const { value } = event.target;
		setSearchValue(value);
	};

	const updateMonetaryData = useCallback(
		(modifiedMonetaryData) => {
			setMonetary(modifiedMonetaryData);
			onChange({
				...modifiedMonetaryData,
				[monetaryTypeField?.id]:
					modifiedMonetaryData?.[monetaryTypeField?.id]?.value,
			});
		},
		[monetaryTypeField?.id, onChange]
	);

	// This useEffect will run only initial render
	useEffect(() => {
		// Check if the server value needs to be formatted
		const isNotServerSideFormatted =
			value && typeof value?.[monetaryTypeField?.id] === "object";

		if (isNotServerSideFormatted) {
			let modifiedMonetaryData = {
				...value,
				[monetaryTypeField.id]: {
					name: value[monetaryTypeField.id]?.name,
					value: value[monetaryTypeField.id]?.value,
				},
			};
			updateMonetaryData(modifiedMonetaryData);
		} else if (!value?.[monetaryTypeField?.id]) {
			let defaultMonetaryData = {
				[monetaryValueField?.id]: "",
				[monetaryTypeField?.id]: {
					name: getCurrencyDataById(baseCurrency?.id)?.code,
					value: baseCurrency?.id,
				},
			};
			updateMonetaryData(defaultMonetaryData);
		}
	}, [
		value,
		baseCurrency,
		monetaryTypeField?.id,
		monetaryValueField?.id,
		getCurrencyDataById,
		updateMonetaryData,
	]);

	const onMonetaryValueChange = (event) => {
		let { value } = event.target;
		let modifiedMonetaryData = {
			...monetary,
			[monetaryValueField?.id]: value,
		};
		updateMonetaryData(modifiedMonetaryData);
		clearErrors(field.id);
	};

	const onMonetaryTypeChange = (event, child) => {
		let { value } = event.target;
		let modifiedMonetaryData = {
			...monetary,
			[monetaryTypeField?.id]: {
				name: child?.props?.name,
				value: value,
			},
		};
		updateMonetaryData(modifiedMonetaryData);
		clearErrors(field.id);
	};

	const renderEmptyView = () => {
		return (
			<Stack alignItems="center" justifyContent="center" height="40px">
				<Typography fontSize={13} color="rgba(0, 0, 0, 0.6)">
					No Results Found
				</Typography>
			</Stack>
		);
	};

	return (
		<React.Fragment>
			<Box width="100%">
				<TextField
					placeholder={monetaryValueField?.placeHolder || "0.00"}
					type="number"
					sx={{
						width: "80%",
						"& .MuiOutlinedInput-notchedOutline": {
							borderRadius: "8px 0px 0px 8px",
						},
						"& input[type='number']::-webkit-inner-spin-button, & input[type='number']::-webkit-outer-spin-button":
							{
								"-webkit-appearance": "none",
							},
						...valueSx,
					}}
					id={id}
					name="VALUE"
					onChange={onMonetaryValueChange}
					value={monetary[monetaryValueField.id]}
					inputRef={inputRef}
					error={!!error}
				/>
				<Select
					value={monetary?.[monetaryTypeField?.id]?.value}
					id={id}
					sx={{
						width: "20%",
						"& .MuiOutlinedInput-notchedOutline": {
							borderRadius: "0px 8px 8px 0px",
							marginLeft: "-1px",
						},
						...typeSx,
					}}
					name="CURRENCY"
					MenuProps={{
						autoFocus: false,
						PaperProps: {
							style: {
								maxHeight: 300,
							},
						},
					}}
					renderValue={() => monetary?.[monetaryTypeField?.id]?.name}
					onChange={onMonetaryTypeChange}
					onClose={() => setSearchValue("")}
					error={!!error}
				>
					<Box
						p={0.5}
						marginTop="-8px"
						sx={{
							position: "sticky",
							top: 0,
							zIndex: 1,
							backgroundColor: "#fff",
						}}
					>
						<MenuSearchBar
							value={searchValue}
							onChange={handleSearchValue}
						/>
					</Box>
					{activeCurrencyList?.length > 0
						? activeCurrencyList?.map((currency) => (
								<MenuItem
									key={currency.id}
									value={currency.id}
									name={currency?.code || ""}
									style={{
										height: "40px",
									}}
								>
									<Stack
										minWidth="300px"
										direction="row"
										justifyContent="space-between"
									>
										<Typography fontSize={13}>
											{currency.name}
										</Typography>
										<Typography fontSize={13}>
											{currency.code}
										</Typography>
									</Stack>
								</MenuItem>
							))
						: renderEmptyView()}
				</Select>
			</Box>
		</React.Fragment>
	);
}
