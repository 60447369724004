import { enqueueSnackbar } from "notistack";
import { useCreateLifecycleStage } from "../../../../hooks/services/lifecycleStage";
import LifecycleStageForm from "../LifecycleStageForm";
import { notificationVariants } from "../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../utils/notification/notificationMessages";

export default function AddLifecycleStage(props) {
	const { onClose } = props;

	// mutation call:-
	const createMutation = useCreateLifecycleStage();

	const handleAddLifecycleStage = (formData) => {
		createMutation.mutate(formData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.lifecycleStageCreated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage ?? notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<>
			<LifecycleStageForm
				onClose={onClose}
				onSave={handleAddLifecycleStage}
				isSaving={createMutation.isLoading}
			/>
		</>
	);
}
