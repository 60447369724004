import React from "react";
import AddForm from "../../Elements/AddForm";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { modules } from "../../../utils/common/ModulesName";
import { useDealData, useEditDeal } from "../../../hooks/services/deal";
import { useDealFieldPermissions } from "../../../hooks/modules/auth/deal";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useModuleName } from "../../../hooks/modules";

export default function EditDeal(props) {
	const { onClose, dealId, summaryFieldId } = props;

	// query and mutation call:-
	const { data: dealData } = useDealData(dealId);
	const updateMutation = useEditDeal(dealId);

	const dealFieldPermissions = useDealFieldPermissions();

	const { getModuleName } = useModuleName();
	const moduleName = modules.DEAL;
	const formHeaderLabel = `Update ${getModuleName(moduleName)}`;

	const updateSummary = (updatedFormData) => {
		removeFieldsWithEmptyValues(updatedFormData);
		updateMutation.mutate(updatedFormData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.dealUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			<FieldPermissionContextProvider value={dealFieldPermissions}>
				<AddForm
					onSave={updateSummary}
					moduleName={moduleName}
					formData={dealData}
					summaryFieldId={summaryFieldId}
					onClose={onClose}
					isSaving={updateMutation.isLoading}
					hiddenSaveAndNewButton={true}
					saveButtonText="Update"
					formHeaderLabel={formHeaderLabel}
					isEditMode={true}
				></AddForm>
			</FieldPermissionContextProvider>
		</React.Fragment>
	);
}
