import { useMemo } from "react";
import { useMailData, useSingleConversationData } from "../services/mail";

export const useDraftMailDataByThreadId = (threadId) => {
	const { data: emailThreadData, isFetching: isFetchingThreadData } =
		useMailData(threadId);

	const draftMailThreadData = useMemo(() => {
		let initialComposerData = {
			toAddress: [],
			ccAddress: [],
			bccAddress: [],
			subject: "",
			body: "",
			draftConversationId: "",
			attachmentData: [],
		};
		if (emailThreadData) {
			let conversationData = { ...emailThreadData?.emails?.[0] };
			initialComposerData.toAddress =
				conversationData?.recipients?.to?.map(
					(toAddress) => toAddress.id || { email: toAddress.mailId }
				) || [];

			initialComposerData.ccAddress =
				conversationData?.recipients?.cc?.map(
					(ccAddress) => ccAddress.id || { email: ccAddress.mailId }
				) || [];

			initialComposerData.bccAddress =
				conversationData?.recipients?.bcc?.map(
					(bccAddress) =>
						bccAddress.id || { email: bccAddress.mailId }
				) || [];

			initialComposerData.draftConversationId =
				conversationData?.conversationId;

			if (conversationData?.attachments?.length > 0) {
				initialComposerData.attachmentData =
					conversationData.attachments.map(
						({ fileName, fileSize, ...rest }) => ({
							name: fileName,
							size: fileSize,
							...rest,
						})
					);
			}
			initialComposerData.subject = conversationData?.subject;
			initialComposerData.body = conversationData?.message;

			return initialComposerData;
		}
	}, [emailThreadData]);

	return { draftMailThreadData, isFetchingThreadData };
};

export const useDraftMailDataByConversationId = (conversationId) => {
	const {
		data: singleConversationData,
		isFetching: isFetchingConversationData,
	} = useSingleConversationData(conversationId);

	const draftMailConversationData = useMemo(() => {
		let initialComposerData = {
			toAddress: [],
			ccAddress: [],
			bccAddress: [],
			subject: "",
			body: "",
			draftConversationId: "",
			attachmentData: [],
		};
		if (singleConversationData) {
			let conversationData = { ...singleConversationData };

			initialComposerData.toAddress =
				conversationData?.recipients?.to?.map(
					(toAddress) =>
						toAddress.contactId || { email: toAddress.mailId }
				) || [];

			initialComposerData.ccAddress =
				conversationData?.recipients?.cc?.map(
					(ccAddress) =>
						ccAddress.contactId || { email: ccAddress.mailId }
				) || [];

			initialComposerData.bccAddress =
				conversationData?.recipients?.bcc?.map(
					(bccAddress) =>
						bccAddress.contactId || { email: bccAddress.mailId }
				) || [];

			initialComposerData.draftConversationId = conversationData.id;

			if (conversationData?.attachments?.length > 0) {
				initialComposerData.attachmentData =
					conversationData.attachments.map(
						({ fileName, fileSize, ...rest }) => ({
							name: fileName,
							size: fileSize,
							...rest,
						})
					);
			}

			initialComposerData.subject = conversationData?.subject;
			initialComposerData.body = conversationData?.message;

			return initialComposerData;
		}
	}, [singleConversationData]);

	return { draftMailConversationData, isFetchingConversationData };
};
