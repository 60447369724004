import { apiClient } from "../..";
import { getSettingsUrl } from "../../apiConfig";
import { assertError } from "../../apiHelper";

export const getLocaleSettings = async () => {
	let requestData = getLocaleSettingRequest();
	return await postLocaleSettingsPreference(requestData);
};

export const updateLocaleSettings = async ({
	id,
	dateFormat,
	language,
	timeFormat,
	timeZone,
}) => {
	let requestData = getUpdateLocaleSettingsRequest(
		id,
		dateFormat,
		language,
		timeFormat,
		timeZone
	);
	return await postLocaleSettingsPreference(requestData);
};

const getLocaleSettingRequest = () => {
	return {
		type: "get",
		data: {},
	};
};

const getUpdateLocaleSettingsRequest = (
	id,
	dateFormat,
	language,
	timeFormat,
	timeZone
) => {
	return {
		type: "pUpdate",
		data: {
			id: id,
			dateFormat: dateFormat,
			language: language,
			timeFormat: timeFormat,
			timeZone: timeZone,
		},
	};
};

const postLocaleSettingsPreference = async (requestData) => {
	let localSettingsPreferenceManagementUrl = getLocaleSettingsPreferenceURL();
	let response = await apiClient.post(
		localSettingsPreferenceManagementUrl,
		requestData
	);
	assertError(response, localSettingsPreferenceManagementUrl);

	return response.data.data;
};

const getLocaleSettingsPreferenceURL = () => {
	return getSettingsUrl() + "/preference";
};
