import {
	Box,
	IconButton,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import { validationConfig, DEAL_STATUS } from "../../LifecycleStageConfig";
import { default as CloseIcon } from "../../../../../assets/icons/close";
import { default as DragIcon } from "../../../../../assets/icons/drag";

export default function StageStatusList(props) {
	const {
		statusFields,
		errors,
		register,
		clearErrors,
		removeStatus,
		setValue,
	} = props;

	const theme = useTheme();

	const getLabel = (label) => {
		switch (label) {
			case DEAL_STATUS.WON:
				return "Closed Won";
			case DEAL_STATUS.LOST:
				return "Closed Lost";
			default:
				"";
		}
	};

	const getLabelColor = (label) => {
		switch (label) {
			case DEAL_STATUS.WON:
				return theme.palette.primary.main;
			case DEAL_STATUS.LOST:
				return theme.palette.error.main;
			default:
				"#000";
		}
	};

	const getErrorMessage = (statusIndex) => {
		if (errors["statuses"]?.[statusIndex]?.["name"]?.message) {
			return errors["statuses"]?.[statusIndex]?.["name"]?.message;
		}
		return "";
	};

	return (
		<>
			{statusFields?.map((status, statusIndex) => (
				<Draggable
					key={`status-${statusIndex}`}
					draggableId={`status-${statusIndex}`}
					index={statusIndex}
					isDragDisabled={!status?.isMovable}
				>
					{(provided) => (
						<Box
							ref={provided.innerRef}
							{...provided.draggableProps}
							sx={{ width: "100%" }}
						>
							<Stack
								direction="row"
								height="42px"
								alignItems="center"
								justifyContent="center"
								borderBottom={`1px solid ${
									getErrorMessage(statusIndex)
										? theme.palette.error.main
										: "#0000001A"
								}`}
							>
								<Box
									sx={{ width: "10%" }}
									{...provided.dragHandleProps}
								>
									<IconButton size="small">
										{DragIcon(
											20,
											20,
											"#5C6360",
											status?.isMovable ? 1 : 0.5
										)}
									</IconButton>
								</Box>

								<Box align="left" sx={{ width: "65%" }}>
									<TextField
										variant="standard"
										{...register(
											`statuses.${statusIndex}.name`,
											{
												required: "Can’t be empty",
												...validationConfig,
											}
										)}
										onChange={(event) => {
											clearErrors(
												`statuses.${statusIndex}.name`
											);
											// When dragging and dropping, I used `setValue` to ensure the value doesn't get removed.
											setValue(
												`statuses.${statusIndex}.name`,
												event.target.value
											);
										}}
										sx={{
											"& .MuiInputBase-input::placeholder":
												{
													color: theme.palette.primary
														.main,
												},
										}}
										inputProps={{
											style: {
												fontSize: "14px",
												fontWeight: 400,
												padding: 0,
												textOverflow: "ellipsis",
												whiteSpace: "nowrap",
												overflow: "hidden",
											},
										}}
										InputProps={{
											disableUnderline: true,
											placeholder: "Enter",
										}}
										error={
											!!errors?.["statuses"]?.[
												statusIndex
											]?.["name"]
										}
										defaultValue={status?.name || ""}
										autoComplete="off"
									/>
								</Box>

								<Box sx={{ width: "15%" }}>
									{status?.label && (
										<Box
											display="flex"
											p={0.5}
											bgcolor="#fff"
											borderRadius="8px"
										>
											<Typography
												fontSize={13}
												fontWeight={500}
												color={getLabelColor(
													status?.label
												)}
											>
												{getLabel(status?.label)}
											</Typography>
										</Box>
									)}
								</Box>

								<Box sx={{ width: "10%" }}>
									{statusFields?.length !== 1 &&
										status?.isDeletable && (
											<Box
												display="flex"
												justifyContent="center"
												onClick={() =>
													removeStatus(statusIndex)
												}
											>
												<IconButton size="small">
													{CloseIcon(
														20,
														20,
														"#5C6360"
													)}
												</IconButton>
											</Box>
										)}
								</Box>
							</Stack>

							{getErrorMessage(statusIndex) && (
								<Typography
									fontSize={14}
									fontWeight={500}
									pl={7}
									pt={1}
									color={theme.palette.error.main}
								>
									{getErrorMessage(statusIndex)}
								</Typography>
							)}
						</Box>
					)}
				</Draggable>
			))}
		</>
	);
}
