import { useEffect, useMemo, useState } from "react";
import { DashboardBuilderContext } from "../Context";
import {
	// useChartApplyData,
	useGetChartType,
	useGetDashboard,
	useUpdateDashboardMutation,
} from "../../../../../hooks/services/analytics";
import { default as BarChartIcon } from "../../../../../assets/icons/barChart";
import { default as PieChartIcon } from "../../../../../assets/icons/pieChart";
import { default as LineChartIcon } from "../../../../../assets/icons/lineChart";
import { default as KpiCardIcon } from "../../../../../assets/icons/numbersChart";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import { useNavigate } from "react-router-dom";

const DashboardBuilderProvider = ({ children, dashboardId }) => {
	const navigate = useNavigate();
	const [dashboardName, setDashboardName] = useState("");
	const [dashboardDescription, setDashboardDescription] = useState("");
	const [chartConfiguration, setChartConfiguration] = useState([]);
	const [isDashboardSaving, setIsDashboardSaving] = useState(false);
	const [selectedChart, setSelectedChart] = useState(null);
	const [selectedChartIndex, setSelectedChartIndex] = useState(-1);
	// const chartApplyDataMutation = useChartApplyData();
	const updateDashboardMutation = useUpdateDashboardMutation();
	const { data: chartTypeListData, isLoading: isChartTypeListDataLoading } =
		useGetChartType();
	const { data: dashboardData, isLoading: isDashboardDataLoading } =
		useGetDashboard(dashboardId, true, {
			enabled: !!(chartTypeListData && chartTypeListData.viewList),
		});

	useEffect(() => {
		if (!isDashboardDataLoading && dashboardData) {
			setDashboardName(dashboardData.dashboardName);
			setDashboardDescription(dashboardData.description);

			if (
				dashboardData.childViewInfo &&
				dashboardData.childViewInfo.length > 0
			) {
				setChartConfiguration(
					dashboardData.childViewInfo.map((childViewInfo, index) => {
						// Select first chart
						if (index === 0) {
							setSelectedChart(childViewInfo.viewId);
							setSelectedChartIndex(0);
						}

						const chartTypeData = chartTypeListData.viewList?.find(
							(chartTypeData) =>
								chartTypeData.id === childViewInfo.viewTypeId
						);
						return {
							...childViewInfo,
							meta: {
								viewType: chartTypeData?.viewBy,
								viewIcon: chartTypeData?.icon,
							},
						};
					})
				);
			} else {
				setChartConfiguration([]);
			}
		}
	}, [dashboardData, isDashboardDataLoading, chartTypeListData]);

	const addNewChart = (chartData) => {
		const chartConfigurationLength = chartConfiguration.length;
		const newChartId = "new_chart_" + (chartConfigurationLength + 1);
		setChartConfiguration((chartConfiguration) => [
			...chartConfiguration,
			{
				...chartData,
				viewId: newChartId,
				position: chartConfigurationLength + 1,
			},
		]);
		setSelectedChart(newChartId);
		setSelectedChartIndex(chartConfigurationLength);
	};

	const updateChartProperties = (chartIndex, chartProperties) => {
		setChartConfiguration((chartConfiguration) => {
			chartConfiguration[chartIndex] = {
				...chartConfiguration[chartIndex],
				...chartProperties,
			};

			// let chartConfig = chartConfiguration[chartIndex];

			// chartApplyDataMutation.mutate({
			// 	viewTypeId: chartConfig.viewTypeId,
			// 	viewName: chartConfig.viewName,
			// 	viewProperties: {
			// 		viewBy: {
			// 			...chartConfig.viewProperties.viewBy
			// 		},
			// 		metrics: chartConfig.viewProperties.metrics.map((metric, index) => {
			// 			const parsedMetric = {...metric};
			// 			delete parsedMetric["moduleData"];
			// 			parsedMetric["order"] = index + 1;
			// 			return parsedMetric;
			// 		}),
			// 		// periodicFilters: {
			// 		// 	fieldId: 1622,
			// 		// 	comparatorId: 1487,
			// 		// 	values: [
			// 		// 		{
			// 		// 			value: {
			// 		// 				timeUnitId: 27,
			// 		// 				timeUnitValue: 10
			// 		// 			}
			// 		// 		}
			// 		// 	]
			// 		// }
			// 	}
			// }, {
			// 	onSuccess: (data) => {
			// 		console.log("------- Chart Data ---------");
			// 		console.log(data);
			// 		console.log("----------------------------");
			// 	}
			// });
			// console.log(chartConfiguration);
			return [...chartConfiguration];
		});
	};

	const parseChartProperty = (chartProperty) => {
		delete chartProperty["meta"];
		delete chartProperty["viewId"];
		delete chartProperty["viewProperties"]["metrics"]?.[0]["moduleData"];
		chartProperty["order"] = chartProperty["position"];
		return chartProperty;
	};

	const saveDashboard = () => {
		const dashboardData = {
			dashboardId: dashboardId,
			dashboardName: dashboardName,
			description: dashboardDescription,
			childViewInfo: chartConfiguration.map((chartProperty) =>
				parseChartProperty({ ...chartProperty })
			),
		};

		setIsDashboardSaving(true);

		updateDashboardMutation.mutate(dashboardData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.DashboardSaved,
				});
				navigate("/analytics/dashboard/view/" + dashboardId);
				setIsDashboardSaving(false);
			},
			onError: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.genericErrorMessage,
				});
				setIsDashboardSaving(false);
			},
		});
	};

	const selectedChartProperty = useMemo(() => {
		return (
			chartConfiguration.find(
				(config) => config.viewId === selectedChart
			) || {}
		);
	}, [selectedChart, chartConfiguration]);

	const getChartIcon = (iconName, width, height) => {
		switch (iconName) {
			case "barChart":
				return BarChartIcon(width, height);
			case "pieChart":
				return PieChartIcon(width, height);
			case "lineChart":
				return LineChartIcon(width, height);
			case "kpiCard":
				return KpiCardIcon(width, height);
			default:
				return null;
		}
	};

	return (
		<DashboardBuilderContext.Provider
			value={{
				dashboardName,
				dashboardDescription,
				chartTypeListData,
				isDashboardSaving,
				isChartTypeListDataLoading,
				chartConfiguration,
				updateChartProperties,
				getChartIcon,
				addNewChart,
				selectedChart,
				setSelectedChart,
				selectedChartIndex,
				setSelectedChartIndex,
				selectedChartProperty,
				saveDashboard,
			}}
		>
			{children}
		</DashboardBuilderContext.Provider>
	);
};

export default DashboardBuilderProvider;
