import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
	getLocaleSettings,
	updateLocaleSettings,
} from "../../../../api/accountSettings/crmSettings";
import { getLocaleSettingsKey } from "../../../../utils/queryKeys/accountSettings/crmSettings";

const useLocaleSettingsDetails = () => {
	return useQuery(getLocaleSettingsKey(), () => getLocaleSettings());
};

const useUpdateLocaleSettings = () => {
	let invalidateLocaleSettingsDetails = useInvalidateLocaleSettingsDetails();
	return useMutation(updateLocaleSettings, {
		onSuccess: () => {
			invalidateLocaleSettingsDetails();
		},
	});
};

const useInvalidateLocaleSettingsDetails = () => {
	let queryClient = useQueryClient();
	return () => {
		queryClient.invalidateQueries(getLocaleSettingsKey());
	};
};

export { useLocaleSettingsDetails, useUpdateLocaleSettings };
