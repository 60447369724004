import React, { useState } from "react";
import CustomHomeComponent from "../../Elements/CustomHomeComponent";
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	Stack,
	TextField,
	Typography,
	useTheme,
} from "@mui/material";
import twozoLogo from "../../../assets/images/logo/twozo-full.png";
import { default as EyeIcon } from "../../../assets/icons/eye";
import { default as EyeOffIcon } from "../../../assets/icons/eyeOff";
import { Controller, useForm } from "react-hook-form";
import { useResetPasswordMutation } from "../../../hooks/services/forgotPassword";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tooltip } from "../../../styles/twozo";

export default function ResetPassword() {
	const theme = useTheme();
	const [queryParams] = useSearchParams();
	const token = queryParams.get("token");
	const email = queryParams.get("email");
	const navigate = useNavigate();

	const {
		handleSubmit,
		formState: { errors },
		clearErrors,
		control,
	} = useForm({
		reValidateMode: true,
	});

	const passwordConstraints = [
		"Atleast 1 Lower-case",
		"Atleast 1 Upper-case",
		"Atleast 1 Number",
		"Atleast 1 Special character",
		"8-16 Characters",
	];

	const resetPasswordMutation = useResetPasswordMutation();

	const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(false);
	const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] =
		useState(false);
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const handleClickShowNewPassword = () =>
		setIsNewPasswordHidden((show) => !show);

	const handleClickShowConfirmPassword = () =>
		setIsConfirmPasswordHidden((show) => !show);

	const handleResetPassword = (data) => {
		const requestData = {
			email: email,
			password: data.password,
			confirmPassWord: data.confirmPassword,
			token: token,
		};

		resetPasswordMutation.mutate(requestData, {
			onSuccess: () => {
				navigate("/login");
			},
		});
	};

	return (
		<React.Fragment>
			<CustomHomeComponent>
				<Stack spacing={4} p={5} pb={3}>
					<Box>
						<img
							alt="twozo"
							src={twozoLogo}
							width="108px"
							height="34px"
						/>
					</Box>

					<Stack spacing={2}>
						<Typography fontSize={18} fontWeight={600}>
							Reset your password
						</Typography>

						<Stack spacing={0.5}>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={
									!!errors.password &&
									theme.palette.error.main
								}
							>
								New Password
							</Typography>

							<Controller
								name="password"
								control={control}
								defaultValue=""
								rules={{
									required: "Please enter your new password",
									pattern: {
										value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=()-]).{8,20}$/,
										message: "Invalid password",
									},
									validate: (value) => !/\s/.test(value), // ensures no whitespace
								}}
								render={({ field: { onChange, value } }) => (
									<Tooltip
										open={isTooltipOpen}
										PopperProps={{
											modifiers: [
												{
													name: "offset",
													options: {
														offset: [30, -3],
													},
												},
											],
										}}
										placement="right"
										title={
											<List
												style={{
													fontSize: "14px",
													fontWeight: 300,
													minWidth: "223px",
													padding: "4px",
												}}
											>
												Password must contain:
												{passwordConstraints.map(
													(validation, index) => (
														<ListItem
															key={index}
															sx={{
																"::before": {
																	content:
																		// eslint-disable-next-line quotes
																		'"\\2022"' /* Unicode bullet character */,
																	marginRight: 1,
																},
																p: "0px 0px 0px 7px",
															}}
														>
															{validation}
														</ListItem>
													)
												)}
											</List>
										}
									>
										<TextField
											placeholder="Enter password"
											type={
												isNewPasswordHidden
													? "password"
													: "text"
											}
											FormHelperTextProps={{
												sx: {
													marginLeft: 0,
													fontSize: 13,
												},
											}}
											value={value}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															aria-label="toggle password visibility"
															onClick={
																handleClickShowNewPassword
															}
															edge="end"
														>
															{isNewPasswordHidden
																? EyeIcon(
																		20,
																		20,
																		"#999999"
																	)
																: EyeOffIcon(
																		20,
																		20,
																		"#999999"
																	)}
														</IconButton>
													</InputAdornment>
												),
											}}
											helperText={
												errors.password?.message
											}
											error={!!errors.password}
											onFocus={() => {
												setIsTooltipOpen(true); // Open tooltip on focus
											}}
											onBlur={() => {
												setIsTooltipOpen(false); // Close tooltip on blur
											}}
											onChange={(event) => {
												clearErrors("password");
												onChange(event.target.value);
											}}
										/>
									</Tooltip>
								)}
							/>
						</Stack>

						<Stack spacing={0.5}>
							<Typography
								fontSize={13}
								fontWeight={500}
								color={
									!!errors.confirmPassword &&
									theme.palette.error.main
								}
							>
								Confirm password
							</Typography>

							<Controller
								name="confirmPassword"
								control={control}
								rules={{
									required:
										"Please enter your confirm password",
									pattern: {
										value: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=()-]).{8,20}$/,
										message: "Invalid password",
									},
									validate: (value) => !/\s/.test(value), // ensures no whitespace
								}}
								defaultValue=""
								render={({ field: { value, onChange } }) => (
									<TextField
										placeholder="Enter password"
										type={
											isConfirmPasswordHidden
												? "password"
												: "text"
										}
										FormHelperTextProps={{
											sx: {
												marginLeft: 0,
												fontSize: 13,
											},
										}}
										value={value}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={
															handleClickShowConfirmPassword
														}
														edge="end"
													>
														{isConfirmPasswordHidden
															? EyeIcon(
																	20,
																	20,
																	"#999999"
																)
															: EyeOffIcon(
																	20,
																	20,
																	"#999999"
																)}
													</IconButton>
												</InputAdornment>
											),
										}}
										error={!!errors.confirmPassword}
										helperText={
											errors.confirmPassword?.message
										}
										onChange={(event) => {
											clearErrors("confirmPassword");
											onChange(event.target.value);
										}}
									/>
								)}
							/>
						</Stack>

						<Box py={1}>
							<Button
								variant="contained"
								style={{
									width: "96px",
								}}
								onClick={handleSubmit(handleResetPassword)}
							>
								Save
							</Button>
						</Box>
					</Stack>
				</Stack>
			</CustomHomeComponent>
		</React.Fragment>
	);
}
