import React from "react";

export default function LineChart(width = "56", height = "50") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 56 50"
		>
			<path d="M5.5 20L13.4 24.7" stroke="#CB9F5D" />
			<path d="M25 15.5L16.5 24.5" stroke="#5DCBAA" />
			<path d="M26.5 15L32.5 36.5" stroke="#5D9DCB" />
			<path d="M40.5 24.5L33.5 37" stroke="#CB9F5D" />
			<path d="M42.5 22.5L51 19.5" stroke="#5DCBAA" />
			<circle cx="4" cy="19" r="3" fill="#CB9F5D" stroke="white" />
			<circle cx="15" cy="26" r="3" fill="#5DCBAA" stroke="white" />
			<circle cx="26" cy="14" r="3" fill="#5D9DCB" stroke="white" />
			<circle cx="33" cy="38" r="3" fill="#CB9F5D" stroke="white" />
			<circle cx="41" cy="23" r="3" fill="#5DCBAA" stroke="white" />
			<circle cx="52" cy="19" r="3" fill="#5D9DCB" stroke="white" />
		</svg>
	);
}
