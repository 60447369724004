import { useLayoutEffect, useRef, useState } from "react";
import { Box, Divider } from "@mui/material";
import { Properties } from "./Properties";
import DashboardMenu from "./DashboardMenu";
import Preview from "./Preview";
import DashboardBuilderProvider from "./Provider";
import { useParams } from "react-router-dom";

export default function DashboardBuilder() {
	const drawerWidth = 300;
	let { dashboardId } = useParams();
	const ref = useRef(null);
	const [topBarHeight, setTopBarHeight] = useState(0);

	useLayoutEffect(() => {
		setTopBarHeight(ref.current.getBoundingClientRect().top);
	}, [ref]);

	return (
		<DashboardBuilderProvider dashboardId={dashboardId}>
			<Box
				ref={ref}
				sx={{
					display: "flex",
					height: `calc(100vh - ${topBarHeight}px)`,
				}}
			>
				<Box sx={{ width: drawerWidth, overflowY: "auto" }}>
					<Properties />
				</Box>

				<Box
					sx={{
						flexGrow: 1,
						width: `calc(100% - ${drawerWidth}px)`,
						overflowY: "auto",
					}}
				>
					<Box
						style={{
							height: "100%",
						}}
						p={1}
						pl={0}
					>
						<Box
							style={{
								display: "flex",
								flexDirection: "column",
								border: "1px solid rgba(0, 0, 0, 0.1)",
								borderRadius: "8px",
								height: "100%",
							}}
						>
							<DashboardMenu />

							<Divider />

							<Box
								sx={{
									flexGrow: 1,
									overflowY: "auto",
								}}
							>
								<Preview />
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</DashboardBuilderProvider>
	);
}
