import { getRootKey } from "../rootKey";

const lifecycleStageKey = "lifecycleStage";

// lifecycle stage key
const getLifecycleStageKey = () => {
	return [...getRootKey(), lifecycleStageKey];
};

// query keys:-
export const allLifecycleStagesKey = [...getLifecycleStageKey(), "allStages"];

export const rulesKey = [...getLifecycleStageKey(), "rules"];

// query key methods:-
export const getLifeCycleStatusListKey = (lifeCycleStageId) => {
	return [
		...getLifecycleStageKey(),
		String(lifeCycleStageId),
		"lifecycleStageList",
	];
};
