import React, { useState } from "react";
import { Box, Stack, ToggleButton } from "@mui/material";
import Menu from "../../../../Elements/Menu";
import ToggleButtonGroup from "../../../../Elements/ToggleButtonGroup";
import Templates from "./Templates";
import Charts from "./Charts";

export default function NewChartMenu(props) {
	const {
		chartMenuElement,
		newChartMenuOpened,
		CloseNewChartMenu,
		...other
	} = props;
	const [optionType, setOptionType] = useState("charts");

	const handleOptionsType = (_, type) => {
		if (type) {
			setOptionType(type);
		}
	};

	return (
		<React.Fragment>
			<Menu
				width="300px"
				menuListProps={{ height: "640px" }}
				anchorEl={chartMenuElement}
				open={newChartMenuOpened || false}
				onClose={CloseNewChartMenu}
				{...other}
			>
				<Box>
					<Stack direction="row" justifyContent="center" pb={0.5}>
						<ToggleButtonGroup
							value={optionType}
							onChange={handleOptionsType}
						>
							<ToggleButton value="templates">
								Templates
							</ToggleButton>

							<ToggleButton value="charts">Charts</ToggleButton>
						</ToggleButtonGroup>
					</Stack>

					{optionType === "templates" && <Templates />}

					{optionType === "charts" && (
						<Charts onClose={CloseNewChartMenu} />
					)}
				</Box>
			</Menu>
		</React.Fragment>
	);
}
