import React from "react";

export default function NumbersChart(width = "62", height = "50") {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			style={{ fill: "none" }}
			viewBox="0 0 62 50"
		>
			<path
				d="M8 32C8 31.4477 8.44772 31 9 31H40C40.5523 31 41 31.4477 41 32C41 32.5523 40.5523 33 40 33H9C8.44771 33 8 32.5523 8 32Z"
				fill="#CB9F5D"
			/>
			<path
				opacity="0.6"
				d="M49 19C49 17.3431 50.3431 16 52 16C53.6569 16 55 17.3431 55 19C55 20.6569 53.6569 22 52 22C50.3431 22 49 20.6569 49 19Z"
				fill="#CB9F5D"
			/>
			<path
				d="M25.1839 26.14C24.4933 26.14 23.8913 25.9813 23.3779 25.664C22.8739 25.3467 22.3979 24.8147 21.9499 24.068L23.1259 23.326C23.4713 23.8487 23.8166 24.2267 24.1619 24.46C24.5166 24.6933 24.8946 24.81 25.2959 24.81C25.6226 24.81 25.9259 24.7307 26.2059 24.572C26.4859 24.404 26.7099 24.1753 26.8779 23.886C27.0459 23.5967 27.1299 23.27 27.1299 22.906C27.1299 22.3553 26.9806 21.9307 26.6819 21.632C26.3926 21.3333 25.9866 21.184 25.4639 21.184C25.0253 21.184 24.4606 21.324 23.7699 21.604L23.3499 20.498L25.9819 17.6H22.4959V16.2H28.1099L28.3899 16.76L25.6459 19.798C26.1966 19.798 26.6913 19.9333 27.1299 20.204C27.5779 20.4747 27.9326 20.8387 28.1939 21.296C28.4553 21.7533 28.5859 22.262 28.5859 22.822C28.5859 23.4567 28.4319 24.026 28.1239 24.53C27.8253 25.0247 27.4193 25.4167 26.9059 25.706C26.3926 25.9953 25.8186 26.14 25.1839 26.14Z"
				fill="#5D9DCB"
			/>
			<path
				d="M15.302 26L14.84 24.852L17.836 21.478C18.0413 21.2447 18.2607 20.9787 18.494 20.68C18.7367 20.3813 18.942 20.0733 19.11 19.756C19.278 19.4387 19.362 19.1447 19.362 18.874C19.362 18.426 19.2313 18.0807 18.97 17.838C18.7087 17.586 18.3493 17.46 17.892 17.46C17.4907 17.46 17.0987 17.6373 16.716 17.992C16.3333 18.3373 16.0533 18.7993 15.876 19.378L14.714 18.65C14.9473 17.838 15.3627 17.2033 15.96 16.746C16.5667 16.2887 17.2713 16.06 18.074 16.06C18.6247 16.06 19.1147 16.1767 19.544 16.41C19.9733 16.634 20.3093 16.9513 20.552 17.362C20.7947 17.7633 20.916 18.23 20.916 18.762C20.916 19.3033 20.7573 19.8773 20.44 20.484C20.132 21.0813 19.6233 21.7673 18.914 22.542L17.08 24.544H21.224V26H15.302Z"
				fill="#5D9DCB"
			/>
			<path
				d="M8.48399 26V24.544H10.038V18.034L8.62399 18.776L8.21799 17.46L10.836 16.06H11.564V24.544H13.16V26H8.48399Z"
				fill="#5D9DCB"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 12C0 10.3431 1.34315 9 3 9H59C60.6569 9 62 10.3431 62 12V38C62 39.6569 60.6569 41 59 41H3C1.34315 41 0 39.6569 0 38V12ZM3 11C2.44772 11 2 11.4477 2 12V38C2 38.5523 2.44772 39 3 39H59C59.5523 39 60 38.5523 60 38V12C60 11.4477 59.5523 11 59 11H3Z"
				fill="#5DCBAA"
			/>
		</svg>
	);
}
