import { useMutation } from "@tanstack/react-query";
import { forgotPassword, resetPassword } from "../../../api/forgotPassword";

const useForgotPasswordMutation = () => {
	return useMutation(forgotPassword);
};

const useResetPasswordMutation = () => {
	return useMutation(resetPassword);
};

export { useForgotPasswordMutation, useResetPasswordMutation };
