import React, { useEffect, useState } from "react";
import {
	useGetChartType,
	useChartViewData,
} from "../../../../hooks/services/analytics";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	ArcElement,
	Title,
	Tooltip,
	Legend,
	Colors,
} from "chart.js";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import LineChart from "./LineChart";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { isEmptyObject } from "../../../../utils/common";
import { getFormattedDateMonthYearAndTime } from "../../../../utils/DateUtils";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	ArcElement,
	Title,
	Tooltip,
	Legend,
	Colors
);

ChartJS.defaults.font.family = "Lexend";

export default function Charts(props) {
	const { viewId, viewTypeId, dashboardId } = props;
	const { data: chartTypeListData, isLoading: isChartTypeListDataLoading } =
		useGetChartType();
	const {
		data: chartViewData,
		isLoading: isChartViewDataLoading,
		isError: chartViewDataError,
	} = useChartViewData(dashboardId, viewId);
	const [chartType, setChartType] = useState({});
	const [chartData, setChartData] = useState({
		xAxis: {
			label: "",
			data: [],
		},
		yAxis: {
			label: "",
			data: [],
		},
	});

	const parseXAxisData = (data, dataType) => {
		switch (dataType) {
			case "Date":
				data =
					data?.map((value) =>
						getFormattedDateMonthYearAndTime(parseInt(value))
					) || [];
				break;
			default:
				data = data?.map((value) => String(value)) || [];
				break;
		}
		return data;
	};

	useEffect(() => {
		if (!isChartViewDataLoading) {
			if (chartViewDataError) {
				return;
			}

			if (isEmptyObject(chartViewData.graphData)) {
				return;
			}

			setChartData((chartData) => {
				let updatedChartData = { ...chartData };

				chartViewData.graphMeta.axisDetails.forEach((axisDetail) => {
					if (axisDetail.axis === 1) {
						updatedChartData["xAxis"]["label"] = axisDetail.label;
					} else {
						updatedChartData["yAxis"]["label"] = axisDetail.label;
					}
				});

				updatedChartData["xAxis"]["data"] = parseXAxisData(
					chartViewData.graphData["1"],
					chartViewData.graphMeta.axisDetails[1]?.dataType
				);
				updatedChartData["yAxis"]["data"] =
					chartViewData.graphData["2"];
				return updatedChartData;
			});
		}
	}, [chartViewData, isChartViewDataLoading, chartViewDataError]);

	useEffect(() => {
		const chartTypes = {};
		chartTypeListData?.viewList.forEach((chartType) => {
			chartTypes[chartType.id] = chartType.viewBy;
		});
		setChartType(chartTypes);
	}, [isChartTypeListDataLoading, chartTypeListData]);

	return (
		<React.Fragment>
			{isChartViewDataLoading ? (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					height="70%"
					spacing={2}
				>
					<CircularProgress size={28} />
					<Typography>Loading</Typography>
				</Stack>
			) : chartViewDataError ? (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					height="70%"
					spacing={2}
				>
					<Typography color="rgba(0, 0, 0, 0.6)">
						Error fetching chart data
					</Typography>
				</Stack>
			) : chartData.xAxis.data.length > 0 &&
			  chartData.yAxis.data.length > 0 ? (
				(function () {
					switch (chartType[viewTypeId]) {
						case "pieChart":
							return <PieChart chartData={chartData} />;
						case "barChart":
							return <BarChart chartData={chartData} />;
						case "lineChart":
							return <LineChart chartData={chartData} />;
						case "kpiCard":
						default:
							return null;
					}
				})()
			) : (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					height="70%"
					spacing={2}
				>
					<Typography color="rgba(0, 0, 0, 0.6)">No Data</Typography>
				</Stack>
			)}
		</React.Fragment>
	);
}
