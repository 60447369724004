import { useState } from "react";
import { useAuth } from "../../../../../../hooks/auth";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { default as DropDownIcon } from "../../../../../../assets/icons/dropDown";
import { PERMISSIONS } from "../../../../../../utils/Auth";
import {
	SummaryFieldName,
	Tooltip,
	twozoStyles,
} from "../../../../../../styles/twozo";
import FieldEdit from "../../../../FieldEdit";
import CompanyFieldEditForm from "../../../../FieldEditFormComponents/CompanyFieldEditForm";
import { useSummaryContext } from "../../../../../Summary/SummaryContext";

export default function CompanyComponent(params) {
	const { summaryField } = params;
	// query call:-
	const { isUserAllowedFor } = useAuth();

	// summary context:-
	const { updateSummaryField, entityId } = useSummaryContext();

	const theme = useTheme();
	const classes = twozoStyles();

	const [openToolTip, setOpenToolTip] = useState(false);
	const [isCompanyHovered, setIsCompanyHovered] = useState(false);

	const handleOpenTooltip = (event) => {
		setOpenToolTip(event.target.scrollWidth > event.target.clientWidth);
	};

	const getCompanyRequestData = (companyFieldValue) => {
		let companyValue = companyFieldValue[summaryField?.fieldId];
		return {
			[summaryField?.fieldId]: companyValue ? companyValue : null,
			id: entityId,
		};
	};

	const hasValueOrIsChanged = (companyFieldValue) => {
		return (
			(summaryField?.value &&
				summaryField?.value?.id !==
					companyFieldValue[summaryField?.fieldId]) ||
			(!summaryField?.value && companyFieldValue[summaryField?.fieldId])
		);
	};

	const handleSave = (companyFieldValue, onClose) => {
		if (hasValueOrIsChanged(companyFieldValue)) {
			let requestData = getCompanyRequestData(companyFieldValue);
			updateSummaryField(requestData, onClose);
		} else {
			onClose();
		}
	};

	const getCompanyFieldLabel = (onFieldClicked) => {
		return isUserAllowedFor(PERMISSIONS.company.view) ? (
			<Box onClick={onFieldClicked}>
				{summaryField?.value ? (
					<Tooltip
						title={summaryField.value?.name}
						open={openToolTip}
						placement="top"
					>
						<SummaryFieldName
							className={classes.summaryData}
							onMouseOver={handleOpenTooltip}
							onMouseLeave={() => setOpenToolTip(false)}
						>
							{summaryField.value?.name}
						</SummaryFieldName>
					</Tooltip>
				) : summaryField.config.readOnly ? (
					<SummaryFieldName style={{ opacity: 0.6 }}>
						-
					</SummaryFieldName>
				) : (
					<Stack
						onMouseOver={() => setIsCompanyHovered(true)}
						onMouseLeave={() => setIsCompanyHovered(false)}
						padding={0}
						sx={{
							borderBottom: isCompanyHovered
								? "1px solid rgba(0, 0, 0, 0.2)"
								: "none",
						}}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							spacing={0.5}
							width="150px"
						>
							<Typography
								fontSize="14px"
								fontWeight={500}
								style={{
									color: theme.palette.secondary.contrastText,
									opacity: "0.4",
								}}
							>
								Select
							</Typography>
							{isCompanyHovered ? (
								<Box display="flex">
									{DropDownIcon(20, 20, "rgba(0, 0, 0, 0.2)")}
								</Box>
							) : null}
						</Stack>
					</Stack>
				)}
			</Box>
		) : (
			<Typography
				fontSize={14}
				fontWeight={500}
				style={{
					opacity: 0.4,
					cursor: "not-allowed",
				}}
			>
				Access Denied
			</Typography>
		);
	};

	return (
		<>
			<FieldEdit field={summaryField} fieldLabel={getCompanyFieldLabel}>
				<CompanyFieldEditForm
					onSave={handleSave}
					value={summaryField?.value}
					field={summaryField}
				/>
			</FieldEdit>
		</>
	);
}
