import {
	Box,
	Divider,
	ListItem,
	Stack,
	Switch,
	Typography,
	useTheme,
} from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { default as DragIcon } from "../../../../../assets/icons/drag";
import { default as AddIcon } from "../../../../../assets/icons/add";
import { default as DeleteIcon } from "../../../../../assets/icons/delete";
import { default as EditIcon } from "../../../../../assets/icons/edit";
import LifecycleStageStatusDetail from "./LifecycleStageStatusDetail";
import CustomSwipeableDrawer from "../../../../Elements/CustomSwipeableDrawer";
import EditLifecycleStage from "../../EditLifecycleStage";
import { useState } from "react";
import { twozoStyles } from "../../../../../styles/twozo";
import {
	useDeleteLifecycleStage,
	useUpdateLifecycleStageStatus,
	useUpdateLifecycleStatusOrder,
} from "../../../../../hooks/services/lifecycleStage";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../utils/notification/notificationConfig";
import DeleteDialog from "../../../../Elements/DeleteDialog";
import { notificationMessage } from "../../../../../utils/notification/notificationMessages";
import ConfirmDialog from "../../../../Elements/ConfirmDialog";

export default function LifecycleStageCard(props) {
	const {
		lifecycleStage: {
			isActive = true,
			isDeactivatable,
			id: lifecycleStageId,
			isDeletable,
			isMovable,
			name: lifecycleStageName,
			statuses,
			isSystemStage,
		},
		updateStageStatusList,
	} = props;

	const theme = useTheme();
	const classes = twozoStyles();

	// mutation call:-
	const updateStageStatusMutation = useUpdateLifecycleStageStatus();
	const updateStatusOrderMutation = useUpdateLifecycleStatusOrder();
	const deleteStageMutation = useDeleteLifecycleStage();

	const [isAddStatusEnabled, setIsAddStatusEnabled] = useState(false);
	const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
	const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
	const [isStageCardHovered, setIsStageCardHovered] = useState(false);
	const [
		isEditNewLifecycleStageDialogOpen,
		setIsEditNewLifecycleStageDialogOpen,
	] = useState(false);

	const openEditNewLifecycleStageDialog = () => {
		setIsEditNewLifecycleStageDialogOpen(true);
	};

	const closeEditNewLifecycleStageDialog = () => {
		setIsEditNewLifecycleStageDialogOpen(false);
	};

	const onClickAddStatus = (isAddStatusEnabled) => {
		setIsAddStatusEnabled(isAddStatusEnabled);
		openEditNewLifecycleStageDialog();
	};

	const handleDisableLifecycleStage = () => {
		let requestData = {
			isActive: !isActive,
			id: lifecycleStageId,
		};

		updateStageStatusMutation.mutate(requestData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.lifecycleStageUpdated,
				});
				closeDisableDialog();
			},
		});
	};

	const openDeleteDialog = () => {
		setIsDeleteDialogOpened(true);
	};

	const closeDeleteDialog = () => {
		setIsDeleteDialogOpened(false);
	};

	const openDisableDialog = () => {
		setIsDisableDialogOpen(true);
	};

	const closeDisableDialog = () => {
		setIsDisableDialogOpen(false);
	};

	const handleToggleLifecycleStage = () => {
		if (isActive) {
			openDisableDialog();
		} else {
			handleDisableLifecycleStage();
		}
	};

	const handleDeleteLifecycleStage = () => {
		deleteStageMutation.mutate(lifecycleStageId, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: notificationMessage.lifecycleStageDeleted,
				});
				closeDeleteDialog();
			},
		});
	};

	const stageDeletionWarningMessages = [
		`Deleting “${
			lifecycleStageName || ""
		}” stage will move the contact data to your first lifecycle stage(“Lead”).`,
		"It might also impact the rules for changing stages.",
		"This is an irreversible change.",
	];

	const stageDisableWarningMessages = [
		`Disabling “${
			lifecycleStageName || ""
		}” stage will move the contact data to your first lifecycle stage(“Lead”).`,
		"It might also impact the rules for changing stages.",
		"This is an irreversible change.",
	];

	const getSubTitle = (messages) => {
		return messages.map((message, index) => (
			<ListItem
				key={index}
				style={{
					display: "flex",
					alignItems: "flex-start",
				}}
				sx={{
					"::before": {
						content:
							// eslint-disable-next-line quotes
							'"\\2022"' /* Unicode bullet character */,
						marginRight: 1,
					},
					p: "0px 0px 0px 7px",
				}}
			>
				{message}
			</ListItem>
		));
	};

	const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);
		return result;
	};

	const onDragEnd = (result) => {
		const { source, destination } = result;

		if (!destination) {
			return;
		}

		if (destination.index === source.index) {
			return;
		}

		if (statuses?.[destination.index]?.label) {
			return;
		}

		const stageStatusList = reorder(
			statuses,
			source.index,
			destination.index
		);
		updateStageStatusList([...stageStatusList]);
		const stageStatusIds = stageStatusList?.map((status) => status?.id);

		updateStatusOrderMutation.mutate(stageStatusIds, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.lifecycleStatusUpdated,
				});
			},
		});
	};

	return (
		<>
			<DeleteDialog
				title={`Are you sure you want to delete “${lifecycleStageName}”  stage?`}
				subtitle={getSubTitle(stageDeletionWarningMessages)}
				open={isDeleteDialogOpened}
				onCancel={closeDeleteDialog}
				onDelete={handleDeleteLifecycleStage}
				disableDeleteButton={deleteStageMutation.isLoading}
			></DeleteDialog>

			<ConfirmDialog
				title={`Are you sure you want to disable “${lifecycleStageName}”  stage?`}
				subtitle={getSubTitle(stageDisableWarningMessages)}
				open={isDisableDialogOpen}
				confirmButtonText="Disable"
				onCancel={closeDisableDialog}
				onConfirm={handleDisableLifecycleStage}
				disableDeleteButton={updateStageStatusMutation.isLoading}
			></ConfirmDialog>

			<CustomSwipeableDrawer
				anchor="right"
				PaperProps={{
					elevation: 0,
					style: { backgroundColor: "transparent" },
				}}
				open={isEditNewLifecycleStageDialogOpen}
				onOpen={openEditNewLifecycleStageDialog}
				onClose={closeEditNewLifecycleStageDialog}
				disableBackdropClick={true}
				disableSwipeToOpen
			>
				<Box className={classes.addDialogContainer}>
					{isEditNewLifecycleStageDialogOpen && (
						<EditLifecycleStage
							isAddStatusEnabled={isAddStatusEnabled}
							value={{
								isActive,
								id: lifecycleStageId,
								isDeletable,
								isMovable,
								name: lifecycleStageName,
								statuses,
								isSystemStage,
							}}
							onClose={closeEditNewLifecycleStageDialog}
						/>
					)}
				</Box>
			</CustomSwipeableDrawer>

			<Box
				style={{
					height: "500px",
					width: "inherit",
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
					backgroundColor: "#fff",
				}}
			>
				<Box
					style={{
						height: "100%",
						display: "flex",
						flexDirection: "column",
					}}
					onMouseOver={() => setIsStageCardHovered(true)}
					onMouseLeave={() => setIsStageCardHovered(false)}
				>
					<Stack
						width="100%"
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						p={2}
					>
						<Stack direction="row" alignItems="center">
							<Stack
								direction="row"
								alignItems="center"
								spacing={2}
							>
								{isActive && (
									<Box
										display="flex"
										{...props.dragHandleProps}
										style={{
											opacity: isMovable ? 1 : 0.6,
											pointerEvents: isMovable
												? "auto"
												: "none",
										}}
									>
										{DragIcon(24, 24, "grey")}
									</Box>
								)}

								<Typography
									width="150px"
									fontSize={16}
									fontWeight={600}
									noWrap
								>
									{lifecycleStageName}
								</Typography>
							</Stack>
						</Stack>

						<Stack direction="row" spacing={1} alignItems="center">
							{isStageCardHovered && isActive && (
								<Box
									style={{ cursor: "pointer" }}
									display="flex"
									onClick={() => onClickAddStatus(false)}
								>
									{EditIcon(
										18,
										18,
										theme.palette.primary.main
									)}
								</Box>
							)}

							{isDeletable && isStageCardHovered && isActive && (
								<Box
									style={{ cursor: "pointer" }}
									display="flex"
									onClick={openDeleteDialog}
								>
									{DeleteIcon(
										18,
										18,
										theme.palette.error.main
									)}
								</Box>
							)}

							{isDeactivatable && (
								<Box style={{ cursor: "pointer" }}>
									<Switch
										checked={isActive}
										onClick={handleToggleLifecycleStage}
									/>
								</Box>
							)}
						</Stack>
					</Stack>

					<Divider />

					<Box
						style={{
							flex: "1 1 auto",
							minHeight: 0,
							overflowY: "auto",
						}}
					>
						<DragDropContext onDragEnd={onDragEnd}>
							<Droppable droppableId="status">
								{(provided) => (
									<div
										ref={provided.innerRef}
										{...provided.droppableProps}
									>
										{statuses?.map((status, index) => (
											<Draggable
												draggableId={status?.id?.toString()}
												index={index}
												key={status.id}
												isDragDisabled={
													!status?.isMovable
												}
											>
												{(provided) => (
													<Box
														ref={provided.innerRef}
														{...provided.draggableProps}
													>
														<LifecycleStageStatusDetail
															status={status}
															dragHandleProps={
																provided.dragHandleProps
															}
															isStatusEditable={
																isActive
															}
														/>
													</Box>
												)}
											</Draggable>
										))}

										{provided.placeholder}
									</div>
								)}
							</Droppable>
						</DragDropContext>
					</Box>

					<Divider hidden={!isActive} />

					<Box
						py={1.5}
						px={1.5}
						style={{
							cursor: "pointer",
						}}
						hidden={!isActive}
					>
						<Stack
							direction="row"
							alignItems="center"
							spacing={2.5}
							onClick={() => onClickAddStatus(true)}
						>
							{AddIcon(
								24,
								24,
								theme.palette.secondary.contrastText
							)}

							<Typography
								fontSize={14}
								fontWeight={500}
								color={theme.palette.secondary.contrastText}
							>
								Add Status
							</Typography>
						</Stack>
					</Box>
				</Box>
			</Box>
		</>
	);
}
