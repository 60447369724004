import { apiClient } from "..";
import { getAccountsUrl } from "../apiConfig";
import { assertError } from "../apiHelper";

export const forgotPassword = async (email) => {
	let request = getForgotPasswordRequest(email);
	return await postAccountManagement(request);
};

export const resetPassword = async (data) => {
	let request = getResetPasswordRequest(data);
	return await postAccountManagement(request);
};

const getForgotPasswordRequest = (email) => {
	return {
		type: "forgotPassword",
		data: {
			email: email,
		},
	};
};

const getResetPasswordRequest = (data) => {
	return {
		type: "resetPassword",
		data: data,
	};
};

const postAccountManagement = async (requestData) => {
	let forgotPasswordUrl = getAccountManagementUrl();
	let response = await apiClient.post(forgotPasswordUrl, requestData);
	assertError(response, forgotPasswordUrl);
	return response.data.data;
};

const getAccountManagementUrl = () => {
	/* eslint no-undef: 0 */
	if (process.env.NODE_ENV === "development") {
		return getAccountsUrl() + "/crm/mgmt?localRedirectionRequired=true";
	} else {
		return getAccountsUrl() + "/crm/mgmt";
	}
};
