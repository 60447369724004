import React from "react";
import AddForm from "../../Elements/AddForm";
import { removeFieldsWithEmptyValues } from "../../../utils/common";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import { modules } from "../../../utils/common/ModulesName";
import {
	useContactData,
	useEditContact,
} from "../../../hooks/services/contact";
import { useContactFieldPermissions } from "../../../hooks/modules/auth/contact";
import FieldPermissionContextProvider from "../../Elements/FieldPermissionContext/FieldPermissionContextProvider";
import { useModuleName } from "../../../hooks/modules";
import { CircularProgress, Stack } from "@mui/material";

export default function EditContact(props) {
	const { onClose, contactId, summaryFieldId } = props;

	const { getModuleName } = useModuleName();
	const moduleName = modules.CONTACT;
	const formHeaderLabel = `Update ${getModuleName(moduleName)}`;

	const contactFieldPermissions = useContactFieldPermissions();

	const { data: formData, isLoading: isFormDataLoading } =
		useContactData(contactId);
	const updateMutation = useEditContact(contactId);

	const updateSummary = (updatedFormData) => {
		removeFieldsWithEmptyValues(updatedFormData);
		updateMutation.mutate(updatedFormData, {
			onSuccess: () => {
				enqueueSnackbar({
					variant: notificationVariants.success,
					message: notificationMessage.contactUpdated,
				});
				onClose();
			},
			onError: (error) => {
				let errorMessage = error?.message;
				enqueueSnackbar({
					variant: notificationVariants.error,
					message: errorMessage
						? errorMessage
						: notificationMessage.errorMessage,
				});
			},
		});
	};

	return (
		<React.Fragment>
			{isFormDataLoading ? (
				<Stack
					alignItems="center"
					justifyContent="center"
					height="100vh"
				>
					<CircularProgress size="30px" />
				</Stack>
			) : (
				<FieldPermissionContextProvider value={contactFieldPermissions}>
					<AddForm
						onSave={updateSummary}
						moduleName={moduleName}
						formData={formData}
						summaryFieldId={summaryFieldId}
						onClose={onClose}
						isSaving={updateMutation.isLoading}
						hiddenSaveAndNewButton={true}
						saveButtonText="Update"
						formHeaderLabel={formHeaderLabel}
						isEditMode
					></AddForm>
				</FieldPermissionContextProvider>
			)}
		</React.Fragment>
	);
}
