import React, { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../utils/notification/notificationMessages";
import AssignOwner from "../../Elements/AssignOwner";
import { useAssignOwnerToProducts } from "../../../hooks/services/product";

export default function ProductAssignOwner(props) {
	const { open, onClose, productIds, onOwnerUpdated } = props;
	const [selectedUser, setSelectedUser] = useState(null);
	const assignOwnerMutation = useAssignOwnerToProducts();

	const handleSelectUser = (selectedUser) => {
		setSelectedUser(selectedUser);
	};

	const handleUpdateOwner = () => {
		assignOwnerMutation.mutate(
			{
				productIds: productIds,
				userId: selectedUser?.value,
			},
			{
				onSuccess: () => {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.productUpdated,
					});
					onClose();
					onOwnerUpdated();
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.genericErrorMessage,
					});
				},
			}
		);
	};

	const handleCloseAssignOwnerDialog = () => {
		setSelectedUser(null);
		onClose();
	};

	return (
		<React.Fragment>
			<AssignOwner
				open={open}
				onClose={handleCloseAssignOwnerDialog}
				value={selectedUser}
				handleSelectUser={handleSelectUser}
				onSave={handleUpdateOwner}
				disableCancelButton={assignOwnerMutation.isLoading}
				disableSaveButton={
					assignOwnerMutation.isLoading || !selectedUser
				}
			/>
		</React.Fragment>
	);
}
