import React, { useEffect, useState } from "react";
import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";
import { FormFieldName } from "../../../../../../styles/twozo";
import DeleteDialog from "../../../../../Elements/DeleteDialog";
import Autocomplete from "../../../../../Elements/Autocomplete";
import { removeFieldsWithEmptyValues } from "../../../../../../utils/common";
import { getDropdownDataByName } from "../../../../../../utils/dropdownData";
import { enqueueSnackbar } from "notistack";
import { notificationVariants } from "../../../../../../utils/notification/notificationConfig";
import { notificationMessage } from "../../../../../../utils/notification/notificationMessages";
import {
	useEmailSyncMutation,
	useEmailSyncStatus,
	useInvalidateEmailSyncSettingsData,
	useStopEmailSync,
} from "../../../../../../hooks/services/emailSync";
import { emailsSyncStatus } from "../../../../../../utils/emailsSyncUtils";

const providerActions = {
	archiveAction: "archive",
	deleteAction: "delete",
};

const syncSettings = {
	relatedContacts: "relatedContacts",
	allLabels: "allLabels",
	selectedLabels: "selectedLabels",
};

export default function EmailSyncSettings(props) {
	const { emailSyncSettingsData } = props;
	const theme = useTheme();
	const [showDisconnectDialog, setShowDisconnectDialog] = useState(false);
	const [connectedEmailAddress, setConnectedEmailAddress] = useState("");
	const emailFolderList = getDropdownDataByName("EMAIL_FOLDER_LIST");
	const [syncStatus, setSyncStatus] = useState(
		emailSyncSettingsData?.state || ""
	);

	const isSyncStateSyncing = syncStatus === emailsSyncStatus.SYNCING;
	const isSyncStateActive = syncStatus === emailsSyncStatus.ACTIVE;

	// query call:-
	const { data: emailSyncStatus, status: emailSyncStatusApiStatus } =
		useEmailSyncStatus(emailSyncSettingsData?.id, isSyncStateSyncing);

	// mutation call:-
	const stopSyncMutation = useStopEmailSync();
	const emailSyncMutation = useEmailSyncMutation();

	// invalidation call:-
	const invalidateEmailSyncSettingsData =
		useInvalidateEmailSyncSettingsData();

	//Sync Setting Emails
	const [selectedEmailSyncSettings, setSelectedEmailSyncSettings] = useState(
		syncSettings.relatedContacts
	);

	useEffect(() => {
		// This useEffect handles showing a notification when the sync state is active.
		// It checks if the emailSyncStatusApiStatus is "success" and then updates the sync state accordingly.
		// If the emailSyncStatus is ACTIVE, a success notification is displayed to the user.

		if (emailSyncStatusApiStatus === "success") {
			if (emailSyncStatus && syncStatus !== emailsSyncStatus.ACTIVE) {
				setSyncStatus(emailSyncStatus);
				if (emailSyncStatus === emailsSyncStatus.ACTIVE) {
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.emailSyncMessage,
					});
					invalidateEmailSyncSettingsData();
				}
			}
		}
	}, [emailSyncStatus, emailSyncStatusApiStatus, syncStatus]);

	const handleSelectSyncEmails = (event) => {
		setSelectedEmailSyncSettings(event.target.value);
	};

	//Archive or delete
	const [isArchive, setIsArchive] = useState(false);
	const [isDelete, setIsDelete] = useState(false);

	const handleArchiveSelect = () => {
		setIsArchive(!isArchive);
	};

	const handleDeleteSelect = () => {
		setIsDelete(!isDelete);
	};

	//Specific label dropdown
	const [selectedEmailFolders, setSelectedEmailFolders] = useState([
		emailFolderList[0],
		emailFolderList[1],
	]);
	const [selectedEmailFolderId, setSelectedEmailFolderId] = useState([]);

	const handleSelectEmailFolders = (_, selectedOption) => {
		// At least one label must be selected; deleting all labels should not be allowed.
		if (selectedOption?.length !== 0) {
			setSelectedEmailFolders(selectedOption);
		} else {
			enqueueSnackbar({
				variant: notificationVariants.error,
				message: notificationMessage.emailSyncLabelIssueMessage,
				preventDuplicate: true,
			});
		}
	};

	const isOptionEqualToValue = (option, value) => {
		return option.value === value.value;
	};

	//To store the whole details of selected specific label from its id get from API
	useEffect(() => {
		if (selectedEmailFolderId.length >= 1) {
			const selectedFolders = emailFolderList.filter((folder) =>
				selectedEmailFolderId.includes(folder.value)
			);
			setSelectedEmailFolders(selectedFolders);
		}
	}, [selectedEmailFolderId, emailFolderList]);

	//Select date
	const syncDateList = getDropdownDataByName("SYNC_DATE_LIST");
	const [selectedDateValue, setSelectedDateValue] = useState(
		syncDateList[0].value
	);
	const handleDateChange = (event) => {
		setSelectedDateValue(event.target.value);
	};

	//Email sync
	const getEmailSyncRequest = () => {
		let requestData = { syncConfig: {} };
		requestData.dateConfig = {};
		requestData.syncConfig.providerAction = [];
		requestData.id = emailSyncSettingsData.id;
		requestData.syncConfig.syncType = selectedEmailSyncSettings;
		requestData.syncConfig.labels =
			selectedEmailSyncSettings === syncSettings.selectedLabels
				? selectedEmailFolders.map((data) => data.value)
				: "";
		requestData.dateConfig.syncStartFrom = selectedDateValue;

		if (isArchive) {
			requestData.syncConfig.providerAction.push(
				providerActions.archiveAction
			);
		}

		if (isDelete) {
			requestData.syncConfig.providerAction.push(
				providerActions.deleteAction
			);
		}

		if (requestData.syncConfig.providerAction.length === 0) {
			delete requestData.syncConfig.providerAction;
		}

		removeFieldsWithEmptyValues(requestData.syncConfig);
		removeFieldsWithEmptyValues(requestData);
		return requestData;
	};

	const syncConnectedEmail = () => {
		const requestData = getEmailSyncRequest();
		emailSyncMutation.mutate(requestData, {
			onSuccess: () => {
				setSyncStatus(emailsSyncStatus.SYNCING);
			},
		});
	};

	//To store data get from API
	useEffect(() => {
		setSyncStatus(emailSyncSettingsData?.state);
		setConnectedEmailAddress(emailSyncSettingsData.emailAddress);
		setSelectedEmailSyncSettings(
			emailSyncSettingsData?.syncConfig?.syncType
		);
		setSelectedEmailFolderId(
			emailSyncSettingsData?.syncConfig?.syncType ===
				syncSettings.selectedLabels
				? emailSyncSettingsData?.syncConfig?.labels
				: ""
		);
		setIsArchive(
			emailSyncSettingsData?.syncConfig?.providerAction.includes(
				providerActions.archiveAction
			)
		);
		setIsDelete(
			emailSyncSettingsData?.syncConfig?.providerAction.includes(
				providerActions.deleteAction
			)
		);
		setSelectedDateValue(
			emailSyncSettingsData?.dateConfig?.syncStartFrom
				? emailSyncSettingsData?.dateConfig?.syncStartFrom
				: syncDateList[0].value
		);
	}, [emailSyncSettingsData, syncDateList]);

	const handleStopSync = (id) => {
		stopSyncMutation.mutate(
			{ id: id, isConnected: false },
			{
				onSuccess: () => {
					setShowDisconnectDialog(false);
					enqueueSnackbar({
						variant: notificationVariants.success,
						message: notificationMessage.emailDisconnectMessage,
					});
				},
				onError: () => {
					enqueueSnackbar({
						variant: notificationVariants.error,
						message: notificationMessage.errorMessage,
					});
				},
			}
		);
	};

	return (
		<React.Fragment>
			<DeleteDialog
				title={`Are you sure you want to stop syncing emails from “${connectedEmailAddress}”?`}
				subtitle="Stopping email sync for this account will stop the process of syncing new emails. Please note that stopping sync does not delete any already synced emails."
				confirmButtonText="Stop Sync"
				open={showDisconnectDialog}
				onCancel={() => setShowDisconnectDialog(false)}
				onDelete={() => handleStopSync(emailSyncSettingsData.id)}
			></DeleteDialog>

			<Box
				style={{
					border: "1px solid rgba(0, 0, 0, 0.1)",
					borderRadius: "8px",
				}}
				m={1}
			>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					p={2}
				>
					<Stack direction="row" alignItems="center" spacing={1.5}>
						<Typography fontWeight={600}>
							{connectedEmailAddress}
						</Typography>

						<Stack
							direction="row"
							style={{
								backgroundColor: theme.palette.secondary.main,
								borderRadius: "6px",
							}}
							py={0.5}
							px={1}
							spacing={1}
						>
							{isSyncStateSyncing && (
								<CircularProgress size="16px" thickness={6} />
							)}

							<Typography
								fontSize={13}
								fontWeight={500}
								color={theme.palette.primary.main}
							>
								{syncStatus}
							</Typography>
						</Stack>
					</Stack>

					<Box>
						{isSyncStateActive ? (
							<Button
								variant="contained"
								color="error"
								onClick={() => setShowDisconnectDialog(true)}
								disableElevation
							>
								Stop Sync
							</Button>
						) : null}
					</Box>
				</Stack>

				<Divider />

				<Box
					px={2}
					py={3}
					style={{
						opacity: isSyncStateSyncing && 0.6,
						pointerEvents: isSyncStateSyncing ? "none" : "auto",
					}}
				>
					<Box pb={1}>
						<Typography fontWeight={600}>Sync Settings</Typography>

						<Typography
							fontSize={13}
							fontWeight={500}
							pt={2}
							pl={1}
						>
							Emails
						</Typography>

						<FormControl>
							<RadioGroup
								value={selectedEmailSyncSettings}
								onChange={handleSelectSyncEmails}
							>
								<FormControlLabel
									value={syncSettings.relatedContacts}
									control={<Radio />}
									label={
										<Typography>
											Only sync emails from related
											contacts
										</Typography>
									}
								/>
								<FormControlLabel
									value={syncSettings.allLabels}
									control={<Radio />}
									label={
										<Typography>
											Sync emails with any label
										</Typography>
									}
								/>
								<FormControlLabel
									value={syncSettings.selectedLabels}
									control={<Radio />}
									label={
										<Typography>
											Sync emails with specific labels
										</Typography>
									}
								/>
							</RadioGroup>
						</FormControl>
						{selectedEmailSyncSettings ===
							syncSettings.selectedLabels && (
							<Box pl={3} maxWidth="530px">
								<Autocomplete
									multiple
									disableClearable
									options={emailFolderList}
									value={selectedEmailFolders}
									isOptionEqualToValue={isOptionEqualToValue}
									getOptionLabel={(option) => option.name}
									onChange={handleSelectEmailFolders}
								/>
							</Box>
						)}

						<Typography fontSize={13} fontWeight={500} p={1}>
							Archive or Deletion
						</Typography>

						<Stack spacing={2} pb={2}>
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<Checkbox
									checked={isArchive}
									onChange={() => handleArchiveSelect()}
								/>

								<Typography>
									Archive in outlook when archiving in Twozo
								</Typography>
							</Stack>

							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
							>
								<Checkbox
									checked={isDelete}
									onChange={() => handleDeleteSelect()}
								/>

								<Typography>
									Delete in outlook when deleting in Twozo
								</Typography>
							</Stack>
						</Stack>
					</Box>

					<Divider />

					<Stack spacing={2} pt={3}>
						<Typography fontWeight={600}>
							Import Past Emails
						</Typography>

						<Stack spacing={0.5}>
							<FormFieldName>Sync Starting from</FormFieldName>

							<Select
								value={selectedDateValue}
								style={{
									width: "40%",
								}}
								onChange={(event) => handleDateChange(event)}
							>
								{syncDateList.map((date) => (
									<MenuItem
										value={date.value}
										key={date.value}
									>
										<Typography fontSize={14}>
											{date.name}
										</Typography>
									</MenuItem>
								))}
							</Select>
						</Stack>

						<Box>
							<Button
								variant="contained"
								color="secondary"
								disableElevation
								onClick={syncConnectedEmail}
							>
								Sync
							</Button>
						</Box>
					</Stack>
				</Box>
			</Box>
		</React.Fragment>
	);
}
