import React from "react";
import { useForm } from "react-hook-form";
import { Box, Button, Divider, Stack } from "@mui/material";
import NameField from "../FieldType/Name";
import MetricsYAxis from "../FieldType/MetricsYAxis";
import Duration from "../FieldType/Duration";
import CompareTo from "../FieldType/CompareTo";

const getDefaultChartProperties = (chartProperty) => {
	return {
		viewName: chartProperty.viewName,
		viewProperties: {
			metrics: [
				{
					measureId: "",
					moduleData: "",
					moduleId: "",
					fieldId: "",
					label: "",
				},
			],
			duration: {
				fieldId: "",
				label: "",
				timeUnitId: "",
			},
			compareTo: {
				compareUnitId: "",
			},
		},
	};
};

export default function KpiCardProperties(props) {
	const { onSubmit, chartConfiguration } = props;
	const { control, handleSubmit, setValue, reset } = useForm({
		defaultValues: { ...getDefaultChartProperties(chartConfiguration) },
	});

	return (
		<React.Fragment>
			<form
				onSubmit={handleSubmit(onSubmit)}
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					flex={1}
					style={{
						overflowY: "auto",
					}}
				>
					<Box p={1}>
						<NameField control={control} />
					</Box>

					<Box pt={1}>
						<Divider />
					</Box>

					<Box p={1} pt={2}>
						<MetricsYAxis
							index={0}
							control={control}
							setValue={setValue}
						/>
					</Box>

					<Box>
						<Divider />
					</Box>

					<Box p={1} pt={2}>
						<Duration control={control} setValue={setValue} />
					</Box>

					<Box>
						<Divider />
					</Box>

					<Box p={1} pt={2}>
						<CompareTo control={control} />
					</Box>
				</Box>

				<Box>
					<Divider />
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						p={2}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => reset()}
							disableElevation
						>
							Clear All
						</Button>

						<Button
							type="submit"
							variant="contained"
							disableElevation
						>
							Apply
						</Button>
					</Stack>
				</Box>
			</form>
		</React.Fragment>
	);
}
