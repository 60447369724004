import { Typography, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
	useEffect,
	useState,
	forwardRef,
	useCallback,
	useImperativeHandle,
} from "react";
import { handleFieldValidation } from "../../../../utils/fieldValidation";
import ContactAutoComplete from "../../ContactAutoComplete";

const textFieldSx = {
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			border: "none",
		},
		"&:hover fieldset": {
			border: "none",
		},
		"&.Mui-focused fieldset": {
			border: "none",
		},
	},
};

const DateFieldEditForm = forwardRef(
	({ value, field, onError, onSave, onClose }, ref) => {
		const theme = useTheme();
		const fieldId = field?.fieldId;

		const {
			formState: { errors },
			control,
			handleSubmit,
			clearErrors,
			setValue,
		} = useForm({ values: { [fieldId]: value }, reValidateMode: false });

		const [selectedContact, setSelectedContact] = useState("");

		useEffect(() => {
			if (value?.fullName) {
				setSelectedContact({ name: value.fullName, value: value?.id });
				setValue(fieldId?.toString(), value?.id);
			}
		}, [fieldId, setValue, value]);

		const handleSelectContact = (selectedContact, onChange) => {
			setSelectedContact(selectedContact);
			onChange(selectedContact?.value);
			clearErrors(fieldId + "");
			onError(null);
		};

		const removeContactValue = (onChange) => {
			onChange("");
			setSelectedContact("");
		};

		const onClearErrors = () => {
			clearErrors(fieldId);
		};

		const submitForm = useCallback(() => {
			return handleSubmit(
				(data) => {
					onSave(data, onClose);
					onError(null);
					return data;
				},
				(errors) => {
					if (errors[fieldId]) {
						onError(errors[fieldId]);
					}
				}
			)();
		}, [handleSubmit, fieldId, onError, onSave, onClose]);

		useImperativeHandle(ref, () => ({
			submitForm,
		}));

		return (
			<>
				<Controller
					name={fieldId + ""}
					control={control}
					rules={handleFieldValidation(field)}
					render={({ field: { onChange } }) => (
						<ContactAutoComplete
							placeHolder={field?.placeHolder}
							value={selectedContact}
							onChange={(value) =>
								handleSelectContact(value, onChange)
							}
							onCancel={() => removeContactValue(onChange)}
							textFieldSx={textFieldSx}
							error={errors[fieldId]}
							clearErrors={onClearErrors}
						/>
					)}
					defaultValue=""
				></Controller>

				{errors[fieldId] ? (
					<Typography
						pl={1}
						pb={1.2}
						style={{
							fontSize: 13,
							marginLeft: "6px",
							color: theme.palette.error.main,
						}}
					>
						{errors[fieldId]?.message}
					</Typography>
				) : null}
			</>
		);
	}
);

DateFieldEditForm.displayName = "DateFieldEditForm";

export default DateFieldEditForm;
