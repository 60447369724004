import { Box, Divider, Stack, Typography, useTheme } from "@mui/material";
import { default as DragIcon } from "../../../../../../assets/icons/drag";
import { DEAL_STATUS } from "../../../LifecycleStageConfig";

export default function LifecycleStageStatusDetail(props) {
	const { status, isStatusEditable } = props;
	const theme = useTheme();

	const getStatusColor = (label) => {
		switch (label) {
			case DEAL_STATUS.WON:
				return theme.palette.primary.main;
			case DEAL_STATUS.LOST:
				return theme.palette.error.main;
			default:
				"#000";
		}
	};

	return (
		<>
			<Box px={2}>
				<Stack
					direction="row"
					spacing={2}
					py={2}
					borderBottom="1px solid #0000001A"
				>
					{isStatusEditable && (
						<Box display="flex" {...props.dragHandleProps}>
							{DragIcon(
								24,
								24,
								"grey",
								status?.isMovable ? 1 : 0.6
							)}
						</Box>
					)}

					<Typography
						fontSize={14}
						fontWeight={400}
						color={getStatusColor(status?.label)}
					>
						{status?.name}
					</Typography>

					<Divider />
				</Stack>
			</Box>
		</>
	);
}
