import { Box, Divider, IconButton, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import SummaryCard from "./SummaryCard";
import { default as DropDownIcon } from "../../../assets/icons/dropDownCentered";
import { default as EyeOffIcon } from "../../../assets/icons/eyeOff";
import { default as EyeIcon } from "../../../assets/icons/eye";
import { Tooltip } from "../../../styles/twozo";
import { useTheme } from "@emotion/react";

export default function SummaryCardView(props) {
	const {
		summaryFields,
		summarySubFields,
		hideEmptyFields,
		toggleHideEmptyFields,
	} = props;
	const theme = useTheme();
	const firstRow = 0;
	const secondRow = 1;
	const detailsCardContainerRef = useRef(null);
	const [
		hideDetailsCardContainerLeftScrollButton,
		setHideDetailsCardContainerLeftScrollButton,
	] = useState(true);
	const [
		hideDetailsCardContainerRightScrollButton,
		setHideDetailsCardContainerRightScrollButton,
	] = useState(false);

	const isDividerVisible = (index) => {
		return index < summaryFields[firstRow].length - 1;
	};

	const toggleFieldVisibility = () => {
		toggleHideEmptyFields();
		handleScrollUpdate();
	};

	const scroll = (scrollOffset) => {
		const scrollableContainer = detailsCardContainerRef.current;
		if (scrollableContainer) {
			const { scrollLeft, scrollWidth, clientWidth } =
				scrollableContainer;

			if (scrollLeft + clientWidth + scrollOffset >= scrollWidth) {
				setHideDetailsCardContainerRightScrollButton(true);
			} else if (scrollLeft + scrollOffset <= 0) {
				setHideDetailsCardContainerLeftScrollButton(true);
			} else {
				setHideDetailsCardContainerRightScrollButton(false);
				setHideDetailsCardContainerLeftScrollButton(false);
			}

			const start = scrollLeft;
			const end = start + scrollOffset;
			const duration = 300;
			let startTime;

			const animateScroll = (timestamp) => {
				if (!startTime) startTime = timestamp;
				const progress = (timestamp - startTime) / duration;
				const scrollPosition = start + scrollOffset * progress;

				scrollableContainer.scrollLeft = scrollPosition;

				if (progress < 1) {
					requestAnimationFrame(animateScroll);
				} else {
					scrollableContainer.scrollLeft = end;
				}
			};
			requestAnimationFrame(animateScroll);
		}
	};

	const getHoveredEyeIconContent = () => {
		return hideEmptyFields ? "Hide Empty Fields" : "Show Empty Fields";
	};

	const handleScrollUpdate = () => {
		if (detailsCardContainerRef.current) {
			const { scrollLeft, scrollWidth, clientWidth } =
				detailsCardContainerRef.current;

			if (scrollLeft <= 0) {
				setHideDetailsCardContainerLeftScrollButton(false);
			}
			if (clientWidth <= scrollWidth) {
				setHideDetailsCardContainerRightScrollButton(false);
			}
		}
	};

	return (
		<React.Fragment>
			<Box
				style={{
					display: "flex",
					flexDirection: "row",
				}}
			>
				{!hideDetailsCardContainerLeftScrollButton && (
					<Stack alignItems="center" justifyContent="center">
						<IconButton
							onClick={() => scroll(-280)}
							style={{
								padding: "16px 12px",
								borderRadius: 0,
							}}
						>
							<Box style={{ transform: "rotate(90deg)" }}>
								{DropDownIcon(16, 16, "#666")}
							</Box>
						</IconButton>
					</Stack>
				)}
				<Box
					style={{
						flex: "1 1 auto",
						minWidth: 0,
						overflowX: "hidden",
					}}
					ref={detailsCardContainerRef}
				>
					<Stack direction="row" spacing={2} p={2}>
						{summaryFields?.length > 0
							? summaryFields[firstRow].map(
									(summaryFirstField, index) => (
										<Box
											key={index}
											sx={{ display: "flex" }}
										>
											<Box minWidth="284px" height="48px">
												<table>
													<SummaryCard
														summaryFirstField={
															summaryFirstField
														}
														summarySecondField={
															summaryFields.length >
															1
																? summaryFields[
																		secondRow
																	][index]
																: []
														}
														summarySubFields={
															summarySubFields
														}
													/>
												</table>
											</Box>
											{isDividerVisible(index) ? (
												<Divider
													orientation="vertical"
													flexItem
													sx={{ marginLeft: "16px" }}
												/>
											) : null}
										</Box>
									)
								)
							: null}
					</Stack>
				</Box>

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
					spacing={1}
					px={1}
				>
					<Tooltip title={getHoveredEyeIconContent()} placement="top">
						<IconButton
							style={{ padding: "4px" }}
							onClick={toggleFieldVisibility}
						>
							{hideEmptyFields
								? EyeOffIcon(16, 16, "#666")
								: EyeIcon(16, 16, "#666")}
						</IconButton>
					</Tooltip>

					{!hideDetailsCardContainerRightScrollButton && (
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="center"
						>
							<Divider
								orientation="vertical"
								style={{ height: "28px" }}
							/>

							<IconButton
								onClick={() => scroll(280)}
								style={{
									marginRight: "-8px",
									padding: "16px 12px",
									borderRadius: 0,
									zIndex: theme.zIndex.drawer - 1,
								}}
							>
								<Box
									style={{
										transform: "rotate(-90deg)",
									}}
								>
									{DropDownIcon(16, 16, "#666")}
								</Box>
							</IconButton>
						</Stack>
					)}
				</Stack>
			</Box>
		</React.Fragment>
	);
}
